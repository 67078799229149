import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { getDataFromApi, putDataToApi } from '../../api/dataFromApi';
import {
  ISettings,
  SettingsUpdate,
  SET_SETTINGS,
  SET_SIDEBAR_OPEN,
  SET_SEARCH_OPEN,
  Tenants,
  SET_TENANTS,
  SET_ACTIVE_TENANT,
  SET_CHANGING_TENANT,
} from './types';
import { IStore } from '../store';
import i18n from '../../i18n';
import moment from 'moment';

export const setSettings = (settings: ISettings) => {
  return {
    type: SET_SETTINGS,
    payload: settings,
  };
};

export const setSidebarOpen = (sidebarOpen: boolean) => {
  return {
    type: SET_SIDEBAR_OPEN,
    payload: sidebarOpen,
  };
};

export const setSearchOpen = (searchOpen: boolean) => {
  return {
    type: SET_SEARCH_OPEN,
    payload: searchOpen,
  };
};

export const setTenants = (tenants: Tenants) => {
  return {
    type: SET_TENANTS,
    payload: tenants,
  };
};

export const setActiveTenant = (tenant: string, role: string) => {
  return {
    type: SET_ACTIVE_TENANT,
    payload: { tenant, role },
  };
};

export const setChangingTenant = (changing: boolean) => {
  return {
    type: SET_CHANGING_TENANT,
    payload: changing,
  };
};

export const getInitSettings: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      let apiSettings = await getDataFromApi('auth/user/settings/?fields=common&fields=learning_app&fields=calendar');
      Object.entries(apiSettings).forEach(([key, value]) => {
        if (key !== 'learning_app' && key !== 'common' && key !== 'calendar') return;
        dispatch(updateSettings(key, value as any, true));
      });
    } catch {}
  };
};

export const updateSettings: <T extends keyof ISettings, V extends SettingsUpdate<ISettings[T]>>(
  settingsType: T,
  value: V,
  init?: boolean
) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (settingsType, value, init) => {
  return async (dispatch, getState) => {
    const settings = getState().App.settings;
    const newSettings = {
      ...settings,
      [settingsType]: {
        ...settings[settingsType],
        ...value,
      },
    };

    if (i18n.language !== newSettings.common.language) {
      await i18n.changeLanguage(newSettings.common.language);
    }

    if (moment.locale() !== newSettings.common.language) {
      moment.locale(newSettings.common.language);
    }

    await dispatch(setSettings(newSettings));
    if (!init) {
      try {
        await putDataToApi('auth/user/settings/', newSettings);
      } catch {}
    }
  };
};

export const getUserTenants: () => ThunkAction<Promise<void>, IStore, any, Action<any>> = () => {
  return async (dispatch, getState) => {
    try {
      const tenants = (await getDataFromApi('auth/user/classter_users/')) as Tenants;
      dispatch(setTenants(tenants));
      const tenantValues = Object.values(tenants);
      const activeTenant = getState().App.active_tenant;
      if (!activeTenant && tenantValues.length === 1) {
        dispatch(setActiveTenant(tenantValues[0].key, tenantValues[0].role));
      }
      if (!activeTenant || !tenantValues.find(t => t.key === activeTenant )) {
        dispatch(setActiveTenant('', ''));
      } else {
        const tenant = tenantValues.find(t => t.key === activeTenant );
        dispatch(setActiveTenant(tenant?.key || '', tenant?.role || ''))
      }
    } catch {}
  };
};
