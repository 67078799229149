import * as React from 'react';

function Svg00CalendarEventClose(props) {
  return (
    <svg
      id="00_calendar_event_close_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 26 22"
      width="1em"
      height="1em"
      stroke="#b4b4b4"
      {...props}
    >
      <defs>
        <style>{'.00_calendar_event_close_svg__cls-1{fill:none;stroke-linecap:round;stroke-width:1.3px}'}</style>
      </defs>
      <g id="00_calendar_event_close_svg__Group_332" data-name="Group 332">
        <path
          id="00_calendar_event_close_svg__Line_10"
          data-name="Line 10"
          className="00_calendar_event_close_svg__cls-1"
          d="M18.47 4.87l-11 12"
        />
        <path
          id="00_calendar_event_close_svg__Line_11"
          data-name="Line 11"
          className="00_calendar_event_close_svg__cls-1"
          d="M18.47 16.87l-11-12"
        />
      </g>
    </svg>
  );
}

export default Svg00CalendarEventClose;
