import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogTitle from '../../shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

const BadgeContent = ({ info, handleClose }: { info: any; handleClose: any }) => {
  const { title, description, awarded, badge } = info;

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: theme.palette.text.primary,
        '& .MuiDialogContent-root': {
          padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          '& > h6': { fontWeight: 'bold' },
          '& > p': { marginTop: theme.spacing(1) },
          '& > span': {
            marginTop: theme.spacing(3),
            color: theme.palette.text.secondary,
          },
        },
      },
      imageWrapper: {
        maxWidth: theme.spacing(25),
        maxHeight: theme.spacing(25),
        '& img': {
          width: '100%',
          height: '100%',
        },
      },
    })
  )();

  return (
    <div className={classes.root}>
      <DialogTitle onClose={handleClose} />
      <DialogContent>
        <div className={classes.imageWrapper}>
          <img src={require(`../../../assets/badges/${badge}.png`).default} alt="badge icon" />
        </div>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <Typography variant="caption">{awarded}</Typography>
      </DialogContent>
    </div>
  );
};

export default BadgeContent;
