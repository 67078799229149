import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDataFromApi } from '../../../api/dataFromApi';
import { getActiveTenant } from '../../../redux/App/selectors';
import { getHigherRole } from '../../../redux/CurrentUser/selectors';
import { Group, PerformanceData, Subjects, UsageData } from './types';
import DashboardContext from './context';
import DashboardContent from './DashboardContent';
import StudentDashboard from './Student/StudentDashboard';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmployeeRole } from '../../../utils/userUtils';

const Dashboard: React.FC = () => {
  const [tab, setTab] = useState(0);
  const [group, setGroup] = useState<Group | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [usageData, setUsageData] = useState<UsageData | null>(null);
  const [performanceData, setPerformanceData] = useState<PerformanceData | null>(null);
  const [subjects, setSubjects] = useState<Subjects | null>(null);
  const [subjectsLoading, setSubjectsLoading] = useState(false);
  const [performanceGroups, setPerformanceGroups] = useState<Group[]>([]);
  const [performanceGroup, setPerformanceGroup] = useState<Group | null>(null);

  const location = useLocation();
  const history = useHistory();

  const activeTenant = useSelector(getActiveTenant);
  const higherRole = useSelector(getHigherRole);

  useEffect(() => {
    (async () => {
      try {
        const data: Group[] = await getDataFromApi(`analytics/groups/`, 'v0');
        setGroups(data);
        if (data.length) setGroup(data[0]);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [activeTenant]);

  const fetchUsageData = useCallback(async () => {
    if (!group || usageData?.group === group.id) return;
    try {
      const groupData = await Promise.all([
        await getDataFromApi(`analytics/communicator/${group.id}/`),
        await getDataFromApi(`analytics/learning_app/${group.id}/`),
        await getDataFromApi(`analytics/smartboard/${group.id}/`),
        await getDataFromApi(`analytics/ar/${group.id}/`),
        await getDataFromApi(`analytics/vr/${group.id}/`),
      ]);
      const usageData: UsageData = {
        communicator: groupData[0],
        learningApp: groupData[1],
        smartboard: groupData[2],
        ar: groupData[3],
        vr: groupData[4],
        group: group.id,
      };
      setUsageData(usageData);
    } catch {
      setUsageData(null);
    }
  }, [group, usageData]);

  const fetchPerformanceGroups = useCallback(async (subjectId: string) => {
    try {
      const groups = await getDataFromApi(`analytics/groups/${subjectId}/`);
      if (groups.length) {
        setPerformanceGroups(groups);
        setPerformanceGroup(groups[0]);
      }
    } catch {
      setPerformanceGroups([]);
    }
  }, []);

  const fetchPerformanceData = useCallback(
    async (group: Group | null, subjectId: string) => {
      if (!performanceGroup || !group) return;
      try {
        const data = await getDataFromApi(`analytics/marks/${group.id}/${subjectId}/`);
        if (data) {
          setPerformanceData(data);
        }
      } catch {
        setPerformanceData(null);
      }
    },
    [performanceGroup],
  );

  const fetchSubjects = useCallback(async () => {
    if (subjects) return;
    setSubjectsLoading(true);
    try {
      const data = await getDataFromApi(`auth/user/subjects/`);
      setSubjects(data);
      setSubjectsLoading(false);
    } catch {
      setSubjectsLoading(false);
    }
  }, [subjects]);

  const handleGroupChange = async (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fromPerformance: boolean,
  ) => {
    const { value } = event.target;
    if (fromPerformance) {
      setPerformanceGroup(performanceGroups.find((group) => group.title === value)!);
    } else {
      setGroup(groups.find((group) => group.title === value)!);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (location.pathname !== '/dashboard') history.push('/dashboard');
    setTab(newValue);
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      tabs: {
        padding: '0 10%',
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: { padding: `0 ${theme.spacing(2)}px` },
      },
      tab: { fontWeight: 'bold' },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    }),
  )();

  if (isLoading)
    return (
      <div className={classes.loading}>
        <CircularProgress size='5rem' />
      </div>
    );

  return (
    <DashboardContext.Provider
      value={{
        group,
        handleGroupChange,
        groups,
        subjects,
        subjectsLoading,
        fetchSubjects,
        usageData,
        fetchUsageData,
        performanceData,
        fetchPerformanceData,
        performanceGroups,
        fetchPerformanceGroups,
        performanceGroup,
      }}
    >
      {isEmployeeRole(higherRole) ? (
        <DashboardContent tab={tab} onTabChange={handleTabChange} />
      ) : (
        <StudentDashboard />
      )}
    </DashboardContext.Provider>
  );
};

export default Dashboard;
