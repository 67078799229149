import {
  SET_CURRENT_USER,
  SET_BADGES,
  SetCurrentUserTypes,
  ICurrentUserState,
  SET_BIO_INFO,
  SET_IMAGE_URL,
  ADD_OPEN_BADGE,
  SET_OPEN_BADGES,
  REMOVE_OPEN_BADGE,
  SET_TOS_ACCEPTED,
} from './types';

const initialState: ICurrentUserState = {
  loggedIn: false,
  user_id: '',
  first_name: '',
  last_name: '',
  role: '',
  fullName: '',
  badges: [],
  open_badges: [],
  bio_info: '',
  email: '',
  image_url: '',
  tnc_accepted: false,
  tnc_accepted_at: null,
};

export default function reducer(state = initialState, action: SetCurrentUserTypes): ICurrentUserState {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_BADGES:
      return {
        ...state,
        badges: {
          ...state.badges,
          ...action.payload,
        },
      };
    case SET_OPEN_BADGES:
      return {
        ...state,
        open_badges: [...action.payload],
      };
    case ADD_OPEN_BADGE:
      return {
        ...state,
        open_badges: [...state.open_badges, action.payload],
      };
    case SET_BIO_INFO:
      return {
        ...state,
        bio_info: action.payload,
      };
    case SET_IMAGE_URL:
      return {
        ...state,
        image_url: action.payload,
      };
    case REMOVE_OPEN_BADGE:
      return {
        ...state,
        open_badges: state.open_badges.filter((badge) => badge.id !== action.payload),
      };
    case SET_TOS_ACCEPTED:
      return {
        ...state,
        tnc_accepted: true,
      };
    default:
      return state;
  }
}
