import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDataFromApi } from '../../../../api/dataFromApi';
import { getCurrentUser } from '../../../../redux/CurrentUser/selectors';
import { StudentData } from '../types';
import StudentProfile from './StudentProfile';
import KnowledgeItems from './KnowledgeItems';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const StudentDashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<StudentData | null>(null);
  const currentUser = useSelector(getCurrentUser);
  const match = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  const userId = id || currentUser.user_id;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const userData = await getDataFromApi(`analytics/user/${userId}`);
        setData(userData);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
  }, [userId]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    })
  )();

  if (isLoading)
    return (
      <div className={classes.loading}>
        <CircularProgress size="5rem" />
      </div>
    );

  if (!data) return null;

  return (
    <Switch>
      <Route path={match.url} exact>
        <StudentProfile data={data} />
      </Route>
      <Route path={`${match.url}/knowledge-items/:mtype/:mname`} exact>
        <KnowledgeItems data={data} />
      </Route>
      <Redirect to={match.url} />
    </Switch>
  );
};

export default StudentDashboard;
