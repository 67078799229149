import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardContext from '../../context';
import NoData from '../../shared/NoData';
import GroupSelect from '../../shared/GroupSelect';
import AppAnalyticsCard from '../../AppCard/AppAnalyticsCard';
import AppUsageCard from '../../AppCard/AppUsageCard';
import ARVRAppsUsageCard from '../../AppCard/ARVRAppsUsageCard';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const Usage: React.FC = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { group, usageData, fetchUsageData } = useContext(DashboardContext);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setIsFetchingData(true);
      await fetchUsageData();
      setIsFetchingData(false);
    })();
  }, [fetchUsageData]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      usageTab: { paddingBottom: theme.spacing(5) },
      head: {
        padding: `${theme.spacing(2)}px 10%`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      title: { fontWeight: 'bold' },
      cards: {
        padding: '0 10%',
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: '1fr',
          padding: `0 ${theme.spacing(2)}px`,
        },
      },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
        height: '100%',
      },
    })
  )();

  if (!group) return null;

  return (
    <div className={classes.usageTab}>
      <div className={classes.head}>
        <Typography variant="subtitle1" className={classes.title}>
          {t('Dashboard.app_usage')}
        </Typography>
        <GroupSelect />
      </div>
      {!isFetchingData ? (
        usageData ? (
          <div className={classes.cards}>
            <AppUsageCard title="Communicator" data={usageData?.communicator} />
            <AppUsageCard title="Learning App" data={usageData?.learningApp} />
            <AppUsageCard title="Smartboard" data={usageData?.smartboard} />
            <AppAnalyticsCard title="Communicator" data={usageData?.communicator} />
            <ARVRAppsUsageCard title="AR" data={usageData?.ar} />
            <ARVRAppsUsageCard title="VR" data={usageData?.vr} />
          </div>
        ) : (
          <NoData className={classes.loading} />
        )
      ) : (
        <div className={classes.loading}>
          <CircularProgress size="3rem" />
        </div>
      )}
    </div>
  );
};

export default Usage;
