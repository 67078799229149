import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PdfIcon from '../../../../assets/icons/Pdf';
import WordIcon from '../../../../assets/icons/Word';
import PptIcon from '../../../../assets/icons/Ppt';
import ExcelIcon from '../../../../assets/icons/Excel';
import DefaultIcon from '../../../../assets/icons/Default';
import { getName } from '../../../../utils/userUtils';
import { downloadFile, findExtension, getFileType, getSizeText } from '../../../../utils/fileUtils';
import Dialog from '../../../shared/Dialog/Dialog';
import MessageDetails from '../MessageDetails/MessageDetails';
import { IFile } from '../types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

const File: React.FC<{ data: IFile }> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const { t } = useTranslation();
  const { file_data, user } = data;
  const { content_type, original_name, size, created_at } = file_data;

  const renderIcon = () => {
    switch (getFileType(content_type)) {
      case 'pdf':
        return <PdfIcon />;
      case 'word':
        return <WordIcon />;
      case 'powerpoint':
        return <PptIcon />;
      case 'excel':
        return <ExcelIcon />;
      default:
        const extension = findExtension(original_name);
        return <DefaultIcon extension={extension} />;
    }
  };

  const renderSubheader = () => (
    <span className={classes.subheader}>
      <Typography variant="subtitle2" component="span">
        {getName(user)}
      </Typography>
      <span className={`${classes.ml} ${classes.dot}`}>•</span>
      <Typography variant="subtitle2" component="span" className={classes.ml}>
        {moment(created_at).format('MMMM Do, YYYY hh:mm A')}
      </Typography>
      <span className={`${classes.ml} ${classes.dot}`}>•</span>
      <Typography variant="subtitle2" component="span" className={classes.ml}>
        {getSizeText(size)}
      </Typography>
    </span>
  );

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    downloadFile(file_data);
    handleClose();
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      file: {
        cursor: 'pointer',
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
      },
      header: {
        padding: 0,
        width: 'max-content',
        '& .MuiCardHeader-avatar': { marginRight: theme.spacing(2) },
      },
      subheader: {
        display: 'flex',
        alignItems: 'center',
      },
      avatar: { background: 'transparent' },
      ml: { marginLeft: theme.spacing(1) },
      [theme.breakpoints.down('xs')]: {
        subheader: {
          flexFlow: 'column',
          alignItems: 'flex-start !important',
        },
        ml: {
          marginLeft: theme.spacing(0),
        },
        dot: {
          display: 'none',
        },
      },
    })
  )();

  return (
    <>
      <Card className={classes.file} onClick={() => downloadFile(file_data)} onContextMenu={handleRightClick}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar variant="square" className={classes.avatar}>
              {renderIcon()}
            </Avatar>
          }
          title={<Typography variant="subtitle1">{original_name}</Typography>}
          subheader={renderSubheader()}
        />
      </Card>
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        autoFocus={false}
        onClose={handleClose}
      >
        <Dialog closePopup={handleClose} trigger={<MenuItem>{t('Home.LearningMaterials.details')}</MenuItem>}>
          {(close: () => void) => <MessageDetails handleClose={close} message={data} icon={renderIcon()} />}
        </Dialog>

        <MenuItem onClick={handleDownload}>
          <ListItemText primary={t('Image.download')} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default File;
