import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../../../redux/CurrentUser/selectors';
import { updateBioInfo } from '../../../redux/CurrentUser/actions';
import ProfilePictureUploader from './ProfilePictureUploader/ProfilePictureUploader';
import ProfileImage from '../../ProfileImage/ProfileImage';
import ProfilePictureRemove from './ProfilePictureRemove/ProfilePictureRemove';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface IProps {
  history: any;
}

const MAX_CHARS = 500;

const EditProfile: React.FC<IProps> = ({ history }) => {
  const user = useSelector(getCurrentUser);
  const [bioInfoState, setBioInfoState] = useState(user.bio_info);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bioInfo = () => {
    dispatch(updateBioInfo(bioInfoState));
  };

  const updateBioInfoState = (e: any) => {
    setBioInfoState(e.target.value.slice(0, MAX_CHARS));
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        gap: `${theme.spacing(3.5)}px`,
        color: theme.palette.text.primary,
        '& button': {
          textTransform: 'capitalize',
          fontWeight: 'bold',
        },
        '& main': { flex: 2.25 },
        '& aside': {
          flex: 1,
          [theme.breakpoints.down('md')]: { display: 'none' },
        },
      },
      profilePicture: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          backgroundColor: theme.palette.background.default,
        },
      },
      viewProfile: {
        marginLeft: 'auto',
        textDecoration: 'none',
        [theme.breakpoints.down('xs')]: { display: 'none' },
      },
      buttons: {
        marginLeft: theme.spacing(2),
        '& button': { marginLeft: theme.spacing(3) },
        [theme.breakpoints.down('sm')]: { marginLeft: theme.spacing(0) },
        [theme.breakpoints.down('xs')]: {
          width: '95%',
          margin: `${theme.spacing(1)}px auto 0`,
          display: 'flex',
          flexDirection: 'column',
          '& button': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
        },
      },
      content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'spacen-between',
        alignItems: 'center',
        '& .MuiTypography-body1': { color: theme.palette.text.secondary },
        '& .MuiButton-root': {
          marginLeft: 'auto',
          '&:hover': { backgroundColor: 'unset' },
        },
        '& .MuiInputBase-input': { padding: theme.spacing(1) },
      },
      fullName: {
        padding: theme.spacing(1),
        '& div': {
          display: 'flex',
          alignItems: 'center',
        },
        '& div:first-child': {
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          minHeight: theme.spacing(5.5),
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& .MuiTypography-body1': {
              marginTop: theme.spacing(2),
              marginLeft: theme.spacing(1),
            },
          },
          '&:hover': { backgroundColor: theme.palette.action.hover },
          '& h6': {
            minWidth: theme.spacing(25),
            [theme.breakpoints.down('xs')]: { minWidth: 'unset' },
          },
          '& p': { color: theme.palette.text.secondary },
        },
      },
      email: {
        padding: theme.spacing(1),
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          minHeight: theme.spacing(5.5),
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
          '&:hover': { backgroundColor: theme.palette.action.hover },
          '& h6': {
            minWidth: theme.spacing(25),
            [theme.breakpoints.down('xs')]: { minWidth: 0 },
          },
          '& p': {
            color: theme.palette.text.secondary,
            [theme.breakpoints.down('xs')]: {
              marginTop: theme.spacing(2),
              marginLeft: theme.spacing(1),
            },
          },
        },
      },
      about: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        '& > div': {
          marginTop: theme.spacing(0.5),
          display: 'flex',
          flexDirection: 'column',
          '& textarea': {
            width: theme.spacing(50),
            maxWidth: theme.spacing(70),
            minWidth: theme.spacing(37.5),
            maxHeight: theme.spacing(20),
            minHeight: theme.spacing(5),
            padding: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
            border: '1px solid #C5C9C9',
            borderRadius: 5,
            outline: 'none',
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            [theme.breakpoints.down('xs')]: {
              width: '100%',
              maxWidth: '100%',
              minWidth: '100%',
              maxHeight: theme.spacing(10),
            },
          },
          '& .MuiTypography-caption': { color: theme.palette.text.secondary },
        },
      },
      title: {
        '& .MuiTypography-subtitle1': {
          minWidth: theme.spacing(25),
          [theme.breakpoints.down('xs')]: { minWidth: 'unset' },
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      },
      subtitle: { [theme.breakpoints.down('xs')]: { fontWeight: 'bold' } },
      displayUpXs: { [theme.breakpoints.down('xs')]: { display: 'none' } },
      displayDownXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: { display: 'block' },
      },
      viewProfileXs: {
        display: 'none',
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '95%',
          margin: `${theme.spacing(1)}px auto`,
        },
      },
    })
  )();

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.profilePicture}>
          <ProfileImage large />
          <div className={classes.buttons}>
            <ProfilePictureUploader />
            <ProfilePictureRemove image={user.image_url} />
          </div>
          <Button
            className={classes.viewProfile}
            variant="outlined"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={() => history.push('/account-settings/profile')}
          >
            {t('Settings.Titles.view_profile')}
          </Button>
        </div>
        <Divider />
        <div className={classes.fullName}>
          <div>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {t('Settings.Edit_profile.full_name')}
            </Typography>
            <Typography variant="body1">{user.fullName}</Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.email}>
          <div>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {t('Settings.Edit_profile.email')}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.about}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('Settings.Edit_profile.about_me')}
          </Typography>
          <div>
            <TextareaAutosize
              aria-label="about"
              minRows={5}
              placeholder={t('Settings.Edit_profile.about')}
              value={bioInfoState}
              onChange={updateBioInfoState}
              onBlur={bioInfo}
            />
            <Typography variant="caption">
              {bioInfoState.length}/{MAX_CHARS}
            </Typography>
          </div>
        </div>
        <Button
          className={classes.viewProfileXs}
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<VisibilityIcon />}
          onClick={() => history.push('/account-settings/profile')}
        >
          {t('Settings.Titles.view_profile')}
        </Button>
      </main>
      <aside />
    </div>
  );
};

export default EditProfile;
