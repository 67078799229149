import React, { useState } from 'react';
import { ILink } from '../types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../shared/Dialog/Dialog';
import MessageDetails from '../MessageDetails/MessageDetails';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Link: React.FC<{ link: ILink }> = ({ link }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const { t } = useTranslation();

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      link: { marginBottom: theme.spacing(2) },
      title: { fontWeight: 'bold' },
    })
  )();

  return (
    <>
      <div className={classes.link} onContextMenu={handleRightClick}>
        <Typography variant="subtitle1" className={classes.title}>
          {link.link_title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {link.link_description}
        </Typography>
        <MuiLink href={link.body} target="_blank" rel="noopener">
          {link.body}
        </MuiLink>
      </div>
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        autoFocus={false}
        onClose={handleClose}
      >
        <Dialog closePopup={handleClose} trigger={<MenuItem>{t('Home.LearningMaterials.details')}</MenuItem>}>
          {(close: () => void) => <MessageDetails handleClose={close} message={link} />}
        </Dialog>
      </Menu>
    </>
  );
};

export default Link;
