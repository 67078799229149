import * as React from 'react';

function SvgCalendarEventDetailsGreen(props) {
  return (
    <svg
      id="calendar_event_details_green_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 22 24"
      width="1em"
      height="1em"
      stroke="#65a4ac"
      {...props}
    >
      <defs>
        <style>{'.calendar_event_details_green_svg__cls-1{fill:none;stroke-linecap:round;stroke-miterlimit:10}'}</style>
      </defs>
      <g id="calendar_event_details_green_svg__Group_338" data-name="Group 338">
        <rect
          id="calendar_event_details_green_svg__Rectangle_551-2"
          data-name="Rectangle 551-2"
          className="calendar_event_details_green_svg__cls-1"
          x={2.99}
          y={3.99}
          width={16}
          height={16}
          rx={2}
        />
        <path
          id="calendar_event_details_green_svg__Line_242-2"
          data-name="Line 242-2"
          className="calendar_event_details_green_svg__cls-1"
          d="M6.64 9.04h8.34"
        />
        <path
          id="calendar_event_details_green_svg__Line_243-2"
          data-name="Line 243-2"
          className="calendar_event_details_green_svg__cls-1"
          d="M6.64 12.17h8.34"
        />
        <path
          id="calendar_event_details_green_svg__Line_244-2"
          data-name="Line 244-2"
          className="calendar_event_details_green_svg__cls-1"
          d="M6.64 15.82h4.17"
        />
      </g>
    </svg>
  );
}

export default SvgCalendarEventDetailsGreen;
