import React from 'react';
import Usage from './Usage/Usage';
import AcademicPerformance from './AcademicPerformance/AcademicPerformance';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const TabContent: React.FC<{ tab: number }> = ({ tab }) => {
  return (
    <>
      <TabPanel value={tab} index={0}>
        <Usage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <AcademicPerformance />
      </TabPanel>
    </>
  );
};

export default TabContent;
