export interface User {
  bio_info: string;
  email: string;
  first_name: string;
  image_url: string;
  last_name: string;
  role: string;
  user_id: string;
}

export interface ISearchState {
  loading: boolean;
  results: User[];
  searchTerm: string;
  initSearch: string;
  initValueApplied: boolean;
  focusedResultIndex: number;
  nextPage: null | string;
}

export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_INIT_SEARCH = 'SET_INIT_SEARCH';
export const SET_INIT_VALUE_APPLIED = 'SET_INIT_VALUE_APPLIED';
export const INCREMENT_INDEX = 'INCREMENT_INDEX';
export const DECREMENT_INDEX = 'DECREMENT_INDEX';
export const SET_INDEX = 'SET_INDEX';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';
export const APPEND_SEARCH_RESULTS = 'APPEND_SEARCH_RESULTS';
export const UPDATE_USER_IMAGES = 'UPDATE_USER_IMAGES';

interface ISetSearchLoadinghAction {
  type: typeof SET_SEARCH_LOADING;
  payload: boolean;
}

interface ISetSearchResultshAction {
  type: typeof SET_SEARCH_RESULTS;
  payload: User[];
}

interface ISetSearchTerm {
  type: typeof SET_SEARCH_TERM;
  payload: string;
}

interface ISetInitSearchAction {
  type: typeof SET_INIT_SEARCH;
  payload: string;
}

interface ISetInitValueAppliedAction {
  type: typeof SET_INIT_VALUE_APPLIED;
  payload: boolean;
}

interface IIncrementIndexAction {
  type: typeof INCREMENT_INDEX;
}

interface IDecrementIndexAction {
  type: typeof DECREMENT_INDEX;
}

interface ISetIndex {
  type: typeof SET_INDEX;
  payload: number;
}

interface IResetSearch {
  type: typeof RESET_SEARCH;
}

interface ISetNextPage {
  type: typeof SET_NEXT_PAGE;
  payload: null | string;
}

interface IAppendSearchResults {
  type: typeof APPEND_SEARCH_RESULTS;
  payload: User[];
}

interface IUpdateUserImages {
  type: typeof UPDATE_USER_IMAGES;
  payload: { [id: string]: User };
}

export type SearchActionTypes =
  | ISetSearchLoadinghAction
  | ISetSearchResultshAction
  | ISetSearchTerm
  | ISetInitSearchAction
  | ISetInitValueAppliedAction
  | IIncrementIndexAction
  | IDecrementIndexAction
  | ISetIndex
  | IResetSearch
  | ISetNextPage
  | IAppendSearchResults
  | IUpdateUserImages;
