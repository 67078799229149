import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import SmartboardLogo from '../../../../assets/smartb_logo.svg';
import { useTranslation } from 'react-i18next';
import { IBoard, MaterialsRouteProps } from '../types';
import { DispatchContext, StateContext } from '../context/context';
import { getData, loadMore } from '../context/actions';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const Smartboard: React.FC<MaterialsRouteProps> = ({ sortOptions, activeFilters }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { t } = useTranslation();

  const data = state.data.smartboards;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getData('smartboards', { ...sortOptions, ...activeFilters }));
      setLoading(false);
    })();
  }, [dispatch, sortOptions, activeFilters]);

  const handleLoadMore = async () => {
    if (!data?.links?.next) return;
    setLoadingMore(true);
    dispatch(loadMore('smartboards', data.links.next));
    setLoadingMore(false);
  };

  const renderSkeleton = () => (
    <div className={classes.list}>
      {[...new Array(6)].map((item, index) => (
        <Skeleton key={index} component="div" className={classes.skeletonItem} />
      ))}
    </div>
  );

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gridAutoRows: '180px',
        gap: '16px',
      },
      media: { background: theme.palette.background.default },
      skeletonItem: { transform: 'unset' },
      cardContent: { paddingBottom: theme.spacing(1) },
      loadMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
  )();

  return (
    <div>
      {loading ? (
        renderSkeleton()
      ) : data ? (
        data.results.length ? (
          <div className={classes.list}>
            {data.results.map((board: IBoard) => (
              <a
                style={{ all: 'unset' }}
                href={board.link_url}
                target="_blank"
                rel="noopener noreferrer"
                key={board.message_id}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia className={classes.media} component="img" height="100" image={SmartboardLogo} />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="subtitle2">
                        {board.link_title}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {moment(board.created_at).format('DD/MM/YYYY hh:mm A')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </a>
            ))}
            {data.count > data.results.length && (
              <div className={classes.loadMore}>
                <Button onClick={handleLoadMore} disabled={loadingMore}>
                  {t('Home.LearningMaterials.load_more')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Home.LearningMaterials.no_boards')}
            </Typography>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Smartboard;
