import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './root-reducer';
import ReduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import { IAppState } from './App/types';
import { ICurrentUserState } from './CurrentUser/types';
import { ICalendarState } from './Calendar/types';
import { ISearchState } from './Search/types';

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(ReduxThunk),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
      : (f: any) => f
  )
);

export const persistor = persistStore(store);

export interface IStore {
  App: IAppState;
  CurrentUser: ICurrentUserState;
  Calendar: ICalendarState;
  Search: ISearchState;
}
