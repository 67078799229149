import * as React from 'react';

function SvgCalendar3Dots(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        data-name="Path 472"
        d="M12 8a2 2 0 10-2-2 2.006 2.006 0 002 2zm0 2a2 2 0 102 2 2.006 2.006 0 00-2-2zm0 6a2 2 0 102 2 2.006 2.006 0 00-2-2z"
        fill="#919191"
      />
    </svg>
  );
}

export default SvgCalendar3Dots;
