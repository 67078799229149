import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getDataFromApi } from '../../../../api/dataFromApi';
import { ARVRUserData } from '../types';
import DashboardContext from '../context';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';

interface RouteParams {
  id: string;
  mname: string;
  mtype: string;
}

type KnowledgeData = {
  knowledge_id: string;
  description: string;
  level: number;
  finished: boolean;
}[];

const SingleUserData: React.FC = () => {
  const [userData, setUserData] = useState<KnowledgeData | null>(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { usageData, fetchUsageData } = useContext(DashboardContext);
  const { id, mname, mtype } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setIsFetchingData(true);
        await fetchUsageData();
        const data = await getDataFromApi(`knowledge/${id}/${mname}/${mtype}/`, 'v1');
        setUserData(data);
        setIsFetchingData(false);
      } catch {
        setIsFetchingData(false);
      }
    })();
  }, [id, mname, mtype, fetchUsageData]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        padding: '0 10%',
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: { padding: 0 },
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(2.5)}px 0`,
        '& .MuiTypography-subtitle1': {
          fontWeight: 'bold',
          fontSize: '1.2em',
          marginLeft: theme.spacing(2),
        },
      },
      tableWrapper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2.5),
        '& .MuiTypography-h6': { fontWeight: 'bold' },
      },
      table: {
        marginTop: theme.spacing(1),
        '& thead': {
          backgroundColor: '#D0E9F0',
          '& th': {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.2px',
          },
        },
      },
      finished: {
        '& td': { color: theme.palette.text.disabled },
      },
      assessmentCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      textDisabled: { color: theme.palette.text.disabled },
      loading: {
        display: 'grid',
        placeItems: 'center',
      },
    })
  )();

  if (!userData) return null;

  const title = `${mname} ${t('Dashboard.items_list')}`;
  const { first_name, last_name, profile_image, usage } = (usageData as { [key: string]: any })[
    mtype.toLowerCase()
  ].users_data.find((u: ARVRUserData) => String(u.id) === id);
  const { assessments_total, knowledge_total, assessments_finished } = usage[mname];

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton size="small" onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="subtitle1">{title}</Typography>
      </div>
      {isFetchingData ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Card elevation={0}>
            <CardHeader
              avatar={<Avatar src={profile_image} />}
              title={`${first_name} ${last_name}`}
              subheader={`Enrolled ${mname} ${mtype}`}
            />
          </Card>
          {assessments_total ? (
            <div className={classes.tableWrapper}>
              <Typography variant="h6">{t('Dashboard.assessments')}</Typography>
              <TableContainer elevation={0} component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(Array(assessments_total)).map((a, i) => (
                      <TableRow key={i}>
                        <TableCell className={classes.assessmentCell}>
                          <span className={i >= assessments_finished ? classes.textDisabled : undefined}>
                            Item {i + 1}
                          </span>
                          {i < assessments_finished && <CheckIcon style={{ color: green[500] }} />}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : null}
          {knowledge_total ? (
            <div className={classes.tableWrapper}>
              <Typography variant="h6">{t('Dashboard.knowledge')}</Typography>
              <TableContainer elevation={0} component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell width="50%">{t('Dashboard.description')}</TableCell>
                      <TableCell width="50%">{t('Dashboard.level')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.map(({ knowledge_id, description, level, finished }) => (
                      <TableRow key={knowledge_id} className={!finished ? classes.finished : undefined}>
                        <TableCell>{description}</TableCell>
                        <TableCell>{level}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SingleUserData;
