import React from 'react';
import { NavLink, Switch, Route, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Appearance from '../Settings/Appearance/Appearance';
import Profile from '../Settings/Profile/Profile';
import EditProfile from '../Settings/EditProfile/Edit';
import Privacy from '../Settings/Privacy/Privacy';
import CodeGenerator from './CodeGenrator/CodeGenerator';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import PaletteIcon from '@material-ui/icons/Palette';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: { flexDirection: 'column', padding: theme.spacing(2) },
        [theme.breakpoints.down('xs')]: { padding: 0 },
      },
      nav: {
        flex: 1,
        [theme.breakpoints.down('xs')]: { display: 'none' },
        '& .MuiTypography-h6': {
          marginLeft: theme.spacing(2.5),
          marginBottom: theme.spacing(0.5),
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
        '& .MuiList-root': {
          '& a': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
          },
          '& .MuiListItemIcon-root': { minWidth: 40 },
        },
      },
      settings: {
        flex: 5,
        paddingLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: { paddingLeft: 0 },
      },
      selected: {
        '& .MuiListItem-root': { backgroundColor: theme.palette.action.selected },
      },
    })
  )();

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <Typography variant="h6">{t('Menus.settings')}</Typography>
        <List component="nav">
          <NavLink to="/account-settings" exact activeClassName={classes.selected}>
            <ListItem button>
              <ListItemIcon>
                <PersonIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('Settings.Titles.view_public_profile')} />
            </ListItem>
          </NavLink>
          <NavLink to="/account-settings/appearance" exact activeClassName={classes.selected}>
            <ListItem button>
              <ListItemIcon>
                <PaletteIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('Settings.Titles.appearance')} />
            </ListItem>
          </NavLink>
          <NavLink to="/account-settings/privacy" activeClassName={classes.selected}>
            <ListItem button>
              <ListItemIcon>
                <VisibilityIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('Settings.Titles.privacy')} />
            </ListItem>
          </NavLink>
          <NavLink to="/account-settings/code-generator" activeClassName={classes.selected}>
            <ListItem button>
              <ListItemIcon>
                <FingerprintIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('Settings.Titles.code_generator')} />
            </ListItem>
          </NavLink>
        </List>
      </div>
      <div className={classes.settings}>
        <Switch>
          <Route path="/account-settings" exact render={() => <EditProfile history={history} />} />
          <Route path="/account-settings/profile" exact render={() => <Profile isCurrentUser />} />
          <Route path="/account-settings/appearance" exact component={Appearance} />
          <Route path="/account-settings/privacy" exact component={Privacy} />
          <Route path="/account-settings/code-generator" exact component={CodeGenerator} />
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
