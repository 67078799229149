import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { getColors } from '../utils';
import DashboardContext from '../context';
import NoData from '../shared/NoData';
import GroupSelect from '../shared/GroupSelect';
import StudentsTable from './StudentsTable/StudentsTable';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const AcademicPerformanceDetails: React.FC = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { performanceData, fetchPerformanceData, fetchPerformanceGroups, group } = useContext(DashboardContext);
  const params = useParams<{ id: string, name: string }>();
  const theme = useTheme();
  const colors = getColors(theme);

  useEffect(() => {
    fetchPerformanceGroups(params.id);
  }, [fetchPerformanceGroups, params.id]);

  useEffect(() => {
    (async () => {
      setIsFetchingData(true);
      await fetchPerformanceData(group, params.id);
      setIsFetchingData(false);
    })();
  }, [fetchPerformanceData, group, params.id]);

  const labels = Object.keys(performanceData?.barchart_data || {});
  const percents = Object.values(performanceData?.barchart_data || {});

  const tableColumns = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'semester', label: 'Semester' },
    { id: 'mark', label: 'Mark' },
    { id: 'assessment_type', label: 'Assessment Type' },
    { id: 'assessment_title', label: 'Assessment Title' },
  ];
  const tableRows =
    performanceData?.users_data.map(({ profile_image, first_name, last_name, semester, mark, assessment_title, assessment_type }) => ({
      image: profile_image || '',
      name: `${first_name} ${last_name}`,
      semester,
      mark,
      assessment_type,
      assessment_title,
    })) || [];

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: `${theme.spacing(2)}px 10%`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          color: theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          '& svg': { fontSize: '1.2em' },
        },
        '& .MuiTypography-subtitle1': {
          fontWeight: 'bold',
          marginLeft: theme.spacing(2),
        },
      },
      chart: {
        padding: `${theme.spacing(2)}px 10%`,
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      chartContainer: {
        position: 'relative',
        width: 500,
        [theme.breakpoints.down('xs')]: {},
      },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    })
  )();

  return (
    <>
      <div className={classes.head}>
        <div className={classes.title}>
          <Link to="/dashboard">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography variant="subtitle1">{params.name}</Typography>
        </div>
        <GroupSelect fromPerformance />
      </div>

      {isFetchingData ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : performanceData?.users_data.length ? (
        <>
          <div className={classes.chart}>
            <div className={classes.chartContainer}>
              <Bar
                type="bar"
                data={{
                  labels,
                  datasets: [
                    {
                      data: percents,
                      backgroundColor: colors.main,
                      barPercentage: 0.5,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <StudentsTable rows={tableRows} columns={tableColumns} />
        </>
      ) : (
        <NoData className={classes.loading} />
      )}
    </>
  );
};

export default AcademicPerformanceDetails;
