import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { downloadFile } from '../../../../../utils/fileUtils';
import { getName } from '../../../../../utils/userUtils';
import MessageDetails from '../../MessageDetails/MessageDetails';
import { IMedia } from '../../types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AudiotrackRoundedIcon from '@material-ui/icons/AudiotrackRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import Dialog from '../../../../shared/Dialog/Dialog';

interface IProps {
  file: IMedia;
  onClick?: () => void;
}

const MediaThumb: React.FC<IProps> = ({ file, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const { t } = useTranslation();

  const { message_type, created_at, file_data, user } = file;
  const { media_file_url, original_name } = file_data;

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    downloadFile(file_data);
    handleClose();
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      imgTrigger: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
      },
      avTrigger: {
        position: 'relative',
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: theme.palette.background.default,
      },
      icon: {
        padding: '.75rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: theme.palette.primary.main,
        '& .MuiSvgIcon-root': { fill: theme.palette.getContrastText(theme.palette.primary.main) },
      },
      info: {
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        '& .MuiTypography-subtitle2': { fontWeight: 'bold' },
      },
    })
  )();

  return (
    <>
      {message_type === 'image' ? (
        <img
          src={media_file_url}
          alt={original_name}
          className={classes.imgTrigger}
          onClick={onClick}
          onContextMenu={handleRightClick}
        />
      ) : (
        <div className={classes.avTrigger} onClick={onClick} onContextMenu={handleRightClick}>
          <div className={classes.icon}>
            {message_type === 'audio' ? <AudiotrackRoundedIcon /> : <PlayArrowRoundedIcon />}
          </div>
          <div className={classes.info}>
            <Typography variant="subtitle2">{moment(created_at).format('MMM DD, YYYY')}</Typography>
            <Typography variant="body2">{user && getName(user)}</Typography>
          </div>
        </div>
      )}
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        autoFocus={false}
        onClose={handleClose}
      >
        <Dialog closePopup={handleClose} trigger={<MenuItem>{t('Home.LearningMaterials.details')}</MenuItem>}>
          {(close: () => void) => <MessageDetails handleClose={close} message={file} />}
        </Dialog>
        <MenuItem onClick={handleDownload}>
          <ListItemText primary={t('Image.download')} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default MediaThumb;
