import {
  ICalendarState,
  CalendarActionTypes,
  CREATE_EVENT,
  REMOVE_EVENT,
  UPDATE_EVENT,
  SET_EVENTS,
  SET_CALENDARS,
  UPDATE_LAST_CHECKED,
} from './types';

export default function reducer(
  state: ICalendarState = {
    events: [],
    calendars: [],
    last_checked: {
      from: '',
      timestamp: '',
      to: '',
    },
  },
  action: CalendarActionTypes
) {
  switch (action.type) {
    case SET_CALENDARS:
      return {
        ...state,
        calendars: [...action.payload],
      };
    case SET_EVENTS:
      const from = new Date(action.payload.from);
      const to = new Date(action.payload.to);
      const isBetween = (date: string) => {
        return (new Date(date) >= from && new Date(date) <= to)
      }

      const isAround = (start_date: string, end_date: string) => {
        return (new Date(start_date) < from && new Date(end_date) > to)
    }
      return {
        ...state,
        events: [
          ...state.events.filter(event => (!isBetween(event.start_time) && !isBetween(event.end_time)) && !isAround(event.start_time, event.end_time) ),
          ...action.payload.events,
        ],
      };
    case CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case UPDATE_EVENT:
      return {
        ...state,
        events: [...state.events.filter(event => event.id !== action.payload.id), action.payload],
      };
    case REMOVE_EVENT:
      return {
        ...state,
        events: [...state.events.filter(event => event.id !== action.payload)],
      };
    case UPDATE_LAST_CHECKED:
      return {
        ...state,
        last_checked: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
