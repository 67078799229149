import React from 'react';
import CSS from 'csstype';

const wrapperStyles: CSS.Properties = {
  position: 'fixed',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20%',
};

const titleStyles: CSS.Properties = {
  textAlign: 'center',
};

const linkStyles: CSS.Properties = {
  marginTop: '2rem',
  color: 'inherit',
  textDecoration: 'none',
  padding: '1rem',
  border: '1px solid black',
};

const TOSRefusedPage: React.FC = () => (
  <div style={wrapperStyles}>
    <h1 style={titleStyles}>You have to accept TOS in order to use this app</h1>
    <a href="/" style={linkStyles}>
      Return to TOS dialog
    </a>
  </div>
);

export default TOSRefusedPage;
