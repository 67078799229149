import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useInterval from 'use-interval';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import { View, EventApi } from '@fullcalendar/core';
import FullCalendarComponent from './FullCalendar/FullCalendar';
import './Calendar.css';
import { getAllEvents, refreshEvents, getAllCalendars } from '../../redux/Calendar/actions';
import { getCalendars } from '../../redux/Calendar/selectors';
import { IEvent } from '../../redux/Calendar/types';
import EventDetails from './EventDetails/EventDetails';
import CalendarFilter from './CalendarFilter/CalendarFilter';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const MyCalendar = () => {
  const { t } = useTranslation();
  const tMonths = t('Calendar.months', { returnObjects: true });
  const tLetterDays = t('Calendar.letter_days', { returnObjects: true });
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(moment());
  const fullCalendar = useRef<FullCalendar>(null);
  const dispatch = useDispatch();
  const [activeRange, setActiveRange] = useState<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [boundingElement, setBoundingElement] = useState<DOMRect | null>(null);
  const [eventToDisplay, setEventToDisplay] = useState<IEvent['azure_id'] | null>(null);
  const [triggerBySmallCalendar, setTriggerBySmallCalendar] = useState(false);

  const calendars = useSelector(getCalendars);

  useEffect(() => {
    dispatch(getAllCalendars());
  }, [dispatch]);

  useEffect(() => {
    if (!activeRange.start || !activeRange.end) return;
    dispatch(getAllEvents(activeRange.start.toISOString().slice(0, -5), activeRange.end.toISOString().slice(0, -5)));
  }, [dispatch, activeRange]);

  useInterval(
    () => {
      if (!activeRange.start || !activeRange.end) return;
      dispatch(refreshEvents(activeRange.start.toISOString().slice(0, -5), activeRange.end.toISOString().slice(0, -5)));
    },
    30000,
    false
  );

  useEffect(() => {
    fullCalendar.current?.getApi().gotoDate(selectedDate.toDate());
  }, [selectedDate]);

  const isToday = (date: Date) => {
    const todayString = today.toDateString();
    const dateString = date.toDateString();
    return todayString === dateString;
  };

  const setSmallCalendar = ({ type, activeStart, activeEnd, currentStart, context }: View) => {
    if (
      !activeRange.start ||
      !activeRange.end ||
      activeStart.toString() !== activeRange.start.toString() ||
      activeEnd.toString() !== activeRange.end.toString()
    ) {
      setActiveRange({
        start: activeStart,
        end: activeEnd,
      });
    }

    if (triggerBySmallCalendar) {
      return setTriggerBySmallCalendar(false);
    }

    const calcDays = selectedDate.day() === 0 ? 6 : selectedDate.day() - 1;
    const date =
      type === 'dayGridMonth'
        ? moment(currentStart)
        : type === 'timeGridWeek'
        ? moment(activeStart).add(calcDays, 'days')
        : moment(activeStart);
    const fullCalendarDate = moment(context.calendar.getDate());
    if (
      date.isSame(selectedDate) ||
      fullCalendarDate.isSame(selectedDate) ||
      (fullCalendarDate.month() === selectedDate.month() && type === 'dayGridMonth') ||
      (fullCalendarDate.week() === selectedDate.week() && type === 'timeGridWeek')
    )
      return;
    setSelectedDate(date);
  };

  const openPopup = ({
    event,
    el,
    jsEvent,
    view,
  }: {
    el: HTMLElement;
    event: EventApi;
    jsEvent: MouseEvent;
    view: View;
  }) => {
    jsEvent.preventDefault();
    jsEvent.stopImmediatePropagation();
    jsEvent.stopPropagation();
    jsEvent.cancelBubble = true;
    setBoundingElement(el.getBoundingClientRect());
    setEventToDisplay(event.id);
    setShowPopup(true);
    return false;
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexFlow: 'row',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        [theme.breakpoints.down('xs')]: {
          flexFlow: 'column',
          padding: theme.spacing(2),
        },
        '& .react-calendar__tile--active:enabled:focus, & .react-calendar__tile--active': {
          backgroundColor: theme.palette.action.selected,
        },
        '& .react-calendar__tile:enabled:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        '& .Today': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: theme.palette.primary.contrastText,
        },
        '& .react-calendar__tile--active.Today': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: theme.palette.primary.contrastText,
        },
      },
      items: {
        marginRight: theme.spacing(2.5),
        '& > div': {
          padding: `${theme.spacing(2.5)}px 0`,
          borderBottom: `1px solid ${theme.palette.background.paper}`,
          '&:last-child': { borderBottom: 'none' },
          [theme.breakpoints.down('xs')]: {
            borderBottom: 'none',
            marginBottom: theme.spacing(0.75),
            padding: 0,
          },
        },
        '& .react-calendar__navigation__arrow, & .react-calendar__navigation__label': {
          color: theme.palette.text.primary,
        },
        '& .react-calendar__navigation__arrow:hover, & .react-calendar__navigation__label:hover': {
          backgroundColor: theme.palette.background.paper,
        },
      },
      newEvent: {
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(0.75) },
      },
      filters: {
        listStyle: 'none',
        paddingLeft: 0,
      },
    })
  )();

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <div>
          <Calendar
            value={selectedDate.toDate()}
            showNavigation={true}
            navigationLabel={({ date, label, view }) => {
              if (view !== 'month') return label;
              const month = date.getMonth();
              const year = date.getFullYear();
              return `${tMonths[month]} - ${year}`;
            }}
            formatMonth={(_, day) => tMonths[day.getMonth()]}
            formatShortWeekday={(_, day) => tLetterDays[day.getDay()]}
            tileClassName={({ date }) => (isToday(date) ? 'Today' : '')}
            onChange={(date) => {
              setSelectedDate(moment(date as Date));
              setTriggerBySmallCalendar(true);
            }}
          />
        </div>
        <div>
          <h3>{t('Calendar.activities')}</h3>
          <ul className={classes.filters}>
            {calendars &&
              calendars.map((cal, i) => <CalendarFilter key={cal.id} id={cal.id} index={i} active title={cal.name} />)}
          </ul>
        </div>
      </div>
      <FullCalendarComponent
        ref={fullCalendar}
        isToday={isToday}
        setSmallCalendar={setSmallCalendar}
        openPopup={openPopup}
      />
      <EventDetails el={boundingElement} setShowPopup={setShowPopup} showPopup={showPopup} eventId={eventToDisplay} />
    </div>
  );
};

export default MyCalendar;
