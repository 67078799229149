import React, { useState } from 'react';
import MuiDialog from '@material-ui/core/Dialog';

interface IProps {
  children?: any;
  trigger?: any;
  onClose?: () => void;
  closePopup?: () => void;
  forwardedRef?: any;
}

const Dialog: React.FC<IProps> = ({ children, trigger, onClose, closePopup, forwardedRef }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (closePopup) closePopup();
  };
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleOpen, ref: forwardedRef })}
      <MuiDialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        {children instanceof Function ? children(handleClose) : children}
      </MuiDialog>
    </>
  );
};

export default React.forwardRef((props: IProps, ref: any) => <Dialog {...props} forwardedRef={ref} />);
