import React from 'react';
import { useHistory } from 'react-router-dom';
import { getDataFromClusterName } from '../../../../utils/clusterUtils';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

interface IProps {
  title: string;
  id: string;
  fromDashboard?: boolean;
  moodle_id?: string | null;
}

const UnitCard: React.FC<IProps> = ({ title, id, fromDashboard, moodle_id }) => {
  const [code, name] = getDataFromClusterName(title);
  const history = useHistory();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      unit: {
        aspectRatio: '1 / 1',
        textDecoration: 'none',
        cursor: moodle_id ? 'pointer' : 'auto',
      },
      paper: {
        height: '100%',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .MuiTypography-h6': { fontSize: '1.2rem' },
        '& .MuiTypography-body1': { fontSize: '0.9rem' },
        '& .MuiTypography-body2': { fontSize: '0.8rem' },
      },
      unitTitle: {
        flex: 2,
        '& .MuiTypography-h6': {
          fontWeight: 'bold',
          lineClamp: 3,
          boxOrient: 'vertical',
          display: '-webkit-box',
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          [theme.breakpoints.down(500)]: { wordBreak: 'break-all' },
        },
      },
      unitInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      },
      textSuccess: { color: theme.palette.success.main },
    })
  )();

  const handleClick = async () => {
    if (fromDashboard) {
      history.push(`/dashboard/academic-performance/${title}/${id}`);
    } else {
      await goToMoodle();
    }
  };

  const goToMoodle = async () => {
    if (moodle_id) {
      window.open(`${process.env.REACT_APP_MOODLE_URL}?id=${moodle_id}`, '_blank');
    }
  };

  return (
    <div onClick={handleClick} className={classes.unit}>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.unitTitle}>
          <Typography variant="h6">{name}</Typography>
        </div>
        <div className={classes.unitInfo}>
          <Typography variant="body2" color={moodle_id ? 'primary' : 'textSecondary'}>
            {code}
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default UnitCard;
