import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { downloadFile } from '../../../../utils/fileUtils';
import { getName } from '../../../../utils/userUtils';
import InfoSubheader from '../../../shared/InfoSubheader/InfoSubheader';
import DialogTitle from '../../../shared/Dialog/DialogTitle/DialogTitle';
import { IMedia, IFile, IUser, ILink } from '../types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import GroupIcon from '@material-ui/icons/Group';

interface IProps {
  message: IMedia | IFile | ILink;
  sender?: IUser | null;
  icon?: JSX.Element;
  handleClose: () => void;
}

const MessageDetails: React.FC<IProps> = ({ message, icon, handleClose }) => {
  const { t } = useTranslation();

  const { file_data, user, chat } = { file_data: null, ...message };
  const { media_file_url, original_name, content_type, created_at } = file_data || {};

  const otherUser = chat.private && chat.users.find((u: IUser) => u.user_id !== message.user_id);

  const renderMessage = () => {
    if (message.message_type === 'image')
      return (
        <div>
          <img src={media_file_url} alt={original_name} className={classes.image} />
          <InfoSubheader message={message} />
        </div>
      );
    if (message.message_type === 'audio')
      return (
        <div>
          <audio controls src={media_file_url} style={{ maxWidth: '100%' }}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <InfoSubheader message={message} />
        </div>
      );
    if (message.message_type === 'video')
      return (
        <div>
          <video controls preload="metadata" width="100%" style={{ maxWidth: 400 }}>
            <source src={media_file_url} type={content_type}></source>
            Sorry, your browser doesn't support embedded videos.
          </video>
          <InfoSubheader message={message} />
        </div>
      );
    if (message.message_type === 'file')
      return (
        <div className={classes.file}>
          {icon}
          <InfoSubheader message={message} />
        </div>
      );
    if (message.message_type === 'link')
      return (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {message.link_title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {message.link_description}
          </Typography>
          <MuiLink href={message.body} target="_blank" rel="noopener">
            {message.body}
          </MuiLink>
        </div>
      );
    return message.body;
  };

  const renderAvatar = () => {
    if (chat.private && otherUser) return <Avatar src={otherUser.image_url} />;
    if (chat.signed_url) return <Avatar src={chat.signed_url} />;
    return (
      <Avatar>
        <GroupIcon />
      </Avatar>
    );
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      content: { padding: 0 },
      message: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        minHeight: 160,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      section: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        '& .MuiTypography-subtitle1': {
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        },
      },
      info: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body1': {
          marginLeft: theme.spacing(2),
          wordWrap: 'break-word',
          maxWidth: 300,
        },
      },
      download: {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        margin: theme.spacing(2),
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.text.primary,
        '& .MuiTypography-body2': { marginLeft: theme.spacing(1) },
      },
      image: {
        maxWidth: 280,
        maxHeight: 500,
      },
      timestamp: { marginTop: theme.spacing(2) },
      file: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& svg': { width: '6em', height: '6em' },
        '& > div': {
          alignSelf: 'flex-start',
          marginTop: theme.spacing(1),
          '& > span': {
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
          },
        },
      },
    })
  )();

  return (
    <>
      <DialogTitle onClose={handleClose}>{t('MessageDetails.title')}</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.message}>{renderMessage()}</div>
        {user && (
          <div className={classes.section}>
            <Typography variant="subtitle1">{t('MessageDetails.sender')}</Typography>
            <div className={classes.info}>
              <Avatar src={user.image_url} />
              <Typography variant="body1">{getName(user)}</Typography>
            </div>
          </div>
        )}
        {chat && (
          <div className={classes.section}>
            <Typography variant="subtitle1">{t('MessageDetails.sent_to')}</Typography>
            <div className={classes.info}>
              {renderAvatar()}
              <Typography variant="body1">{chat.private ? getName(otherUser) : chat.title}</Typography>
            </div>
            <Typography variant="body2" color="textSecondary" className={classes.timestamp}>
              {moment(created_at).format('DD/MM/YYYY hh:mm A')}
            </Typography>
          </div>
        )}
        {['audio', 'video', 'image', 'file'].includes(message.message_type) && (
          <button className={classes.download} onClick={() => downloadFile(file_data)}>
            <GetAppIcon />
            <Typography variant="body2">{t('MessageDetails.download')}</Typography>
          </button>
        )}
      </DialogContent>
    </>
  );
};

export default MessageDetails;
