import React from 'react';
import { useTranslation } from 'react-i18next';
import IconILP from '../../../assets/IconILP';
import IconSmartboard from '../../../assets/IconSmartboad';
import IconProsys from '../../../assets/IconProsys';
import CommunicatorIcon from '../../../assets/CommunicatorIcon';
import Menu from '../../shared/Menu/Menu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';

const AppSwitcher = () => {
  const { t } = useTranslation();
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      link: {
        display: 'flex',
        alignItems: 'center',
        '& > span': { marginLeft: theme.spacing(2) },
        '& > svg': { fontSize: '1.2em' },
      },
      support: {
        display: 'flex',
        marginTop: theme.spacing(1),
        all: 'unset',
        fontSize: '0.8rem',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        '& > span': { marginLeft: theme.spacing(2) },
      },
    })
  )();

  return (
    <Menu
      opener={
        <IconButton>
          <AppsIcon />
        </IconButton>
      }
    >
      {(close: () => void) => (
        <div>
          <MenuItem
            button
            onClick={close}
            component={Link}
            target="_blank"
            href={process.env.REACT_APP_COMMUNICATOR_APP_URL}
            underline="none"
            color="textPrimary"
            className={classes.link}
          >
            <CommunicatorIcon />
            <span>Communicator</span>
          </MenuItem>
          <MenuItem
            button
            onClick={close}
            component={Link}
            target="_blank"
            href={process.env.REACT_APP_ILP_APP_URL}
            underline="none"
            color="textPrimary"
            className={classes.link}
          >
            <IconILP />
            <span>ILP</span>
          </MenuItem>
          <MenuItem
            button
            onClick={close}
            component={Link}
            target="_blank"
            href={process.env.REACT_APP_SMARTBOARD_APP_URL}
            underline="none"
            color="textPrimary"
            className={classes.link}
          >
            <IconSmartboard />
            <span>Smartboard</span>
          </MenuItem>
          <MenuItem
            button
            onClick={close}
            component={Link}
            target="_blank"
            href={process.env.REACT_APP_PROSYS_URL}
            underline="none"
            color="textPrimary"
            className={classes.link}
          >
            <IconProsys />
            <span>MyTrailMap</span>
          </MenuItem>
          <MenuItem
            onClick={close}
            className={classes.support}
            component={Link}
            href="mailto:David.D.Debono@mcast.edu.mt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('Menus.support')}</span>
          </MenuItem>
        </div>
      )}
    </Menu>
  );
};

export default AppSwitcher;
