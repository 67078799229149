import * as React from 'react';

function SvgPdf(props) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 100 120" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M59.098 0L100 40.115V110c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0h49.098z"
          fill="#F44336"
        />
        <path d="M100 40l-35.314-.86A10 10 0 0159 30.114V0h1l40 39v1z" fill="#FECDD2" fillRule="nonzero" />
        <path
          d="M24.857 77v-7.553h3.814c2.614 0 4.688-.664 6.222-1.99 1.533-1.328 2.3-3.076 2.3-5.246 0-2.16-.767-3.907-2.3-5.244-1.534-1.338-3.608-2.006-6.222-2.006h-8.945V77h5.13zm3.814-11.489h-3.814v-6.615h3.814c1.11 0 1.956.313 2.536.939.58.626.87 1.428.87 2.407 0 .958-.29 1.743-.87 2.353-.58.611-1.426.916-2.536.916zM48.206 77c2.593 0 4.763-.843 6.508-2.528 1.746-1.685 2.62-3.855 2.62-6.509v-3.965c0-2.644-.874-4.811-2.62-6.502-1.745-1.69-3.915-2.535-6.508-2.535h-7.962V77h7.962zm-.349-3.936h-2.482V58.896h2.482c1.363 0 2.427.465 3.194 1.393.767.928 1.15 2.155 1.15 3.678v3.996c0 1.534-.383 2.768-1.15 3.701-.767.934-1.831 1.4-3.194 1.4zM65.802 77v-8.87h8.825v-3.936h-8.825v-5.298h10.369v-3.935h-15.5V77h5.131z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgPdf;
