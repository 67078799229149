import React, { useState } from 'react';
import MuiMenu from '@material-ui/core/Menu';

interface IProps {
  opener: any;
  children: any;
}

const Menu: React.FC<IProps> = ({ opener, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {React.cloneElement(opener, { onClick: handleClick })}
      <MuiMenu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children instanceof Function ? children(handleClose) : children}
      </MuiMenu>
    </>
  );
};

export default Menu;
