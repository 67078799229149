import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

interface Column {
  [key: string]: string;
}

interface Row {
  [key: string]: string | number;
}

type Order = 'asc' | 'desc';

interface IProps {
  rows: Row[];
  columns: Column[];
  title?: string;
  tableContainerProps?: any;
  urlGenerator?: (id: string) => string;
}

const StudentsTable: React.FC<IProps> = ({ rows, columns, title, tableContainerProps, urlGenerator }) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: Order, orderBy: string) {
    return order === 'desc'
      ? (a: Row, b: Row) => descendingComparator(a, b, orderBy)
      : (a: Row, b: Row) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        padding: `0 10%`,
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: {
          padding: `0 ${theme.spacing(2)}px`,
          paddingBottom: theme.spacing(5),
        },
      },
      tableHead: {
        '& th': {
          fontWeight: 'bold',
          paddingTop: theme.spacing(1.25),
          paddingBottom: theme.spacing(1.25),
        },
      },
      header: {
        padding: `${theme.spacing(1)}px 0`,
        '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body2': { textTransform: 'uppercase' },
      },
      imageCol: {
        width: theme.spacing(9),
        padding: theme.spacing(2),
        fontWeight: 'bold',
      },
      imageCell: { padding: '6px 16px' },
      col: {
        padding: theme.spacing(2),
        fontWeight: 'bold',
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    })
  )();

  return (
    <Paper elevation={0} className={classes.table}>
      <div className={classes.header}>
        <Typography variant="subtitle1">{title || t('Dashboard.students')}</Typography>
      </div>
      <Divider light />
      <TableContainer {...tableContainerProps}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  className={column.id === 'image' ? classes.imageCol : classes.col}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'image')
                          return (
                            <TableCell key={column.id} className={classes.imageCell}>
                              <Avatar src={String(value)} />
                            </TableCell>
                          );
                        if (column.id === 'name' && urlGenerator)
                          return (
                            <TableCell key={column.id}>
                              <Link to={urlGenerator(String(row.id))} className={classes.link}>
                                {value}
                              </Link>
                            </TableCell>
                          );
                        return <TableCell key={column.id}>{value}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('Dashboard.rows_per_page')}
      />
    </Paper>
  );
};

export default StudentsTable;
