import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    width: '1.75rem',
    height: '1.75rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginRight: '.75rem',
  },
  icon: {},
  checkedIcon: {},
  active: { border: '2px solid #6A6A6A' },
});

interface IProps {
  color: string;
  toggleAccentColor: (e: any) => void;
}

const AccentColors: React.FC<IProps> = ({ color, toggleAccentColor }) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      accentColors: {
        marginLeft: theme.spacing(1.25),
        marginTop: theme.spacing(1.25),
      },
    })
  )();

  function StyledRadio(props: RadioProps) {
    const classes = useStyles();
    return (
      <Radio
        className={`${classes.root} ${color === props.value ? classes.active : ''}`}
        disableRipple
        color="default"
        checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <FormControl component="fieldset" className={classes.accentColors}>
      <RadioGroup row value={color} aria-label="accent-color" name="accent-color" onChange={toggleAccentColor}>
        <FormControlLabel
          value="WaikawaGray"
          control={<StyledRadio style={{ backgroundColor: '#6C7AAC' }} />}
          label=""
        />
        <FormControlLabel
          value="OutrageousOrange"
          control={
            <StyledRadio
              style={{
                backgroundColor: '#FD612C',
              }}
            />
          }
          label=""
        />
        <FormControlLabel
          value="FountainBlue"
          control={<StyledRadio style={{ backgroundColor: '#63ACBE' }} />}
          label=""
        />
        <FormControlLabel value="Seance" control={<StyledRadio style={{ backgroundColor: '#7F1D86' }} />} label="" />
      </RadioGroup>
    </FormControl>
  );
};

export default AccentColors;
