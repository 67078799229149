const theme = {
  globals: {
    fontSize: 14,
  },
  variants: {
    WaikawaGray: {
      light: {
        palette: {
          type: 'light',
          primary: { main: '#5D6DA4' },
          secondary: { main: '#FBC02D' },
          error: { main: '#B00020' },
        },
      },
      dark: {
        palette: {
          type: 'dark',
          primary: { main: '#8D9BD6' },
          secondary: { main: '#FBC02D' },
          error: { main: '#CF6679' },
        },
      },
    },
    OutrageousOrange: {
      light: {
        palette: {
          type: 'light',
          primary: { main: '#FD612C' },
          secondary: { main: '#63ACBE' },
          error: { main: '#B00020' },
        },
      },
      dark: {
        palette: {
          type: 'dark',
          primary: { main: '#FD8F6B' },
          secondary: { main: '#95DEF1' },
          error: { main: '#CF6679' },
        },
      },
    },
    FountainBlue: {
      light: {
        palette: {
          type: 'light',
          primary: { main: '#63ACBE' },
          secondary: { main: '#7F1D86' },
          error: { main: '#B00020' },
        },
      },
      dark: {
        palette: {
          type: 'dark',
          primary: { main: '#95DEF1' },
          secondary: { main: '#C3B1CA' },
          error: { main: '#CF6679' },
        },
      },
    },
    Seance: {
      light: {
        palette: {
          type: 'light',
          primary: { main: '#7F1D86' },
          secondary: { main: '#63ACBE' },
          error: { main: '#B00020' },
        },
      },
      dark: {
        palette: {
          type: 'dark',
          primary: { main: '#C3B1CA' },
          secondary: { main: '#95DEF1' },
          error: { main: '#CF6679' },
        },
      },
    },
  },
};

export default theme;

const colorsMap = {
  green: {
    default: '#C8CDA0',
    paper: '#CEDEA2',
  },
  pink: {
    default: '#E7C3AB',
    paper: '#EEDBCE',
  },
  blue: {
    default: '#D3D1DC',
    paper: '#DBD7E4',
  },
};
export type ColorsMapType = typeof colorsMap;
export const getThemeColors = (color: keyof ColorsMapType) => colorsMap[color];
