import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../../redux/CurrentUser/actions';
import { setChangingTenant } from '../../../redux/App/actions';
import ProfileImage from '../../ProfileImage/ProfileImage';
import Menu from '../../shared/Menu/Menu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const ProfileActions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = (closePopup: () => void) => {
    dispatch(logoutUser());
    closePopup();
  };

  const handleChangeInstitute = (closePopup: () => void) => {
    dispatch(setChangingTenant(true));
    closePopup();
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      pointer: { cursor: 'pointer' },
      link: {
        textDecoration: 'none',
        color: 'inherit',
      },
      icon: {
        fontSize: '.9rem',
        color: theme.palette.text.secondary,
        marginRight: '.5rem',
      },
    })
  )();

  return (
    <Menu
      opener={
        <div className={classes.pointer}>
          <ProfileImage />
        </div>
      }
    >
      {(close: () => void) => (
        <div>
          <MenuItem button onClick={() => handleChangeInstitute(close)}>
            <PersonIcon className={classes.icon} />
            <Typography variant="body2" color="textSecondary">
              {t('Menus.change_institute')}
            </Typography>
          </MenuItem>
          <Link to="/account-settings" className={classes.link}>
            <MenuItem button onClick={close}>
              <SettingsIcon className={classes.icon} />
              <Typography variant="body2" color="textSecondary">
                  {t('Menus.settings')}
              </Typography>
            </MenuItem>
          </Link>
          <MenuItem onClick={() => handleLogout(close)}>
            <ExitToAppIcon className={classes.icon} />
            <Typography variant="body2" color="textSecondary">
              {t('Menus.logout')}
            </Typography>
          </MenuItem>
        </div>
      )}
    </Menu>
  );
};

export default ProfileActions;
