interface Style {
  [prop: string]: string;
}
interface Styles {
  ulStyles?: Style;
  liStyles?: Style;
  boxStyles?: Style;
  textStyles?: Style;
}

const getOrCreateLegendList = (chart: any, id: string, ulStyles?: Style) => {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) return;
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.flexWrap = 'wrap';
    listContainer.style.gap = '8px 20px';
    listContainer.style.padding = '0px';

    if (ulStyles) {
      for (const prop in ulStyles) {
        (listContainer.style as { [key: string]: any })[prop] = ulStyles[prop];
      }
    }

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const generateLegendPlugin = (styles?: Styles) => ({
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(chart, options.containerID, styles?.ulStyles);
    if (!ul) return;

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item: any) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '0px';

      if (styles?.liStyles) {
        for (const prop in styles?.liStyles) {
          (li.style as { [key: string]: any })[prop] = styles?.liStyles[prop];
        }
      }

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '12px';
      boxSpan.style.width = '12px';
      boxSpan.style.marginRight = '6px';
      boxSpan.style.borderRadius = '50%';

      if (styles?.boxStyles) {
        for (const prop in styles?.boxStyles) {
          (boxSpan.style as { [key: string]: any })[prop] = styles?.boxStyles[prop];
        }
      }

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.fontSize = '0.8rem';
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0px';
      textContainer.style.padding = '0px';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
      textContainer.style.textTransform = 'uppercase';

      if (styles?.textStyles) {
        for (const prop in styles?.textStyles) {
          (textContainer.style as { [key: string]: any })[prop] = styles?.textStyles[prop];
        }
      }

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
});

export default generateLegendPlugin;
