import { Selector } from 'react-redux';
import { IBadge, ICurrentUserState, IOpenBadge } from './types';
import { IStore } from '../store';

export const getCurrentUser: Selector<IStore, ICurrentUserState> = (state) => state.CurrentUser;
export const getOTISBadges: Selector<IStore, IBadge[]> = (state) => state.CurrentUser.badges;
export const getOpenBadges: Selector<IStore, IOpenBadge[]> = (state) => state.CurrentUser.open_badges;
export const getLastOpenBadges: Selector<IStore, IOpenBadge[]> = (state) =>
  Object.values(
    state.CurrentUser.open_badges.reduce((prev, next) => {
      if (!prev[next.name]) {
        prev[next.name] = [next];
      } else {
        prev[next.name].push(next);
      }
      return prev;
    }, {} as { [key: string]: IOpenBadge[] }),
  )
    .flatMap(
      (a) =>
        a.sort((a, b) => {
          let order = new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime();
          if (a.name === b.name) {
            if (a.is_valid !== b.is_valid) {
              if (a.is_valid) return -1;
              if (b.is_valid) return 1;
              return order;
            } else {
              return order;
            }
          } else {
            return order;
          }
        })[0],
    )
    .sort((a, b) => new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime())
    .slice(0, 3);
export const getTOSAccepted: Selector<IStore, boolean> = (state) => state.CurrentUser.tnc_accepted;
export const getHigherRole: Selector<IStore, string> = (state) => {
  const user_role = state.CurrentUser?.role;
  const tenant_role = state.App.active_role;
  if (['Administrator', 'Counsellor'].includes(user_role)) return user_role;
  return tenant_role;
};