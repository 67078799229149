import React from 'react';
import { useTranslation } from 'react-i18next';
import CommunicatorIcon from '../../../../assets/CommunicatorIcon';
import LearningAppIcon from '../../../../assets/IconLearningApp';
import SmartboardIcon from '../../../../assets/IconSmartboad';
import { StudentData } from '../types';
import ARVRAppsUsageCard from '../AppCard/ARVRAppsUsageCard';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';

const StudentProfile: React.FC<{ data: StudentData }> = ({ data }) => {
  const { t } = useTranslation();

  const getTextColor = (text: string) => {
    if (['Regular', 'Engaged'].includes(text)) {
      return green[500];
    }
    return red[500];
  };

  const getTranslation = (text: string) => {
    if (text === 'Not used in 2 weeks') return t('Dashboard.not_used');
    if (text === 'Not engaged') return t('Dashboard.not_engaged');
    return t(`Dashboard.${text.toLowerCase()}`);
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      studentProfileContainer: {
        maxWidth: '1000px',
        width: '90%',
        margin: `${theme.spacing(5)}px auto`,
      },
      otisAppsUsage: {
        padding: theme.spacing(2),
        '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
      },
      arvrAppsUsage: {
        marginTop: theme.spacing(2.5),
        display: 'grid',
        gap: '20px',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: '1fr',
        },
      },
      wrapper: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      apps: {
        padding: theme.spacing(2),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
      app: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      },
      logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'max-content',
        borderRadius: '50%',
        padding: '0.5rem',
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
      },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    })
  )();

  const communicatorUsage = data.communicator.users_data[0].usage;
  const communicatorEngagement = data.communicator.users_data[0].engaged === 'Yes' ? 'Engaged' : 'Not engaged';
  const smartboardUsage = data.smartboard.users_data[0].usage;
  const learningAppUsage = data.learning_app.users_data[0].usage;

  return (
    <div className={classes.studentProfileContainer}>
      <Paper elevation={2} className={classes.otisAppsUsage}>
        <Typography variant="subtitle1">OTIS APPS USAGE</Typography>
        <div className={classes.wrapper}>
          <div className={classes.apps}>
            <div className={classes.app}>
              <Paper className={classes.logo} variant="outlined">
                <CommunicatorIcon />
              </Paper>
              <div>
                <Typography variant="body2">COMMUNICATOR USAGE</Typography>
                <Typography variant="body2" style={{ color: getTextColor(communicatorUsage) }}>
                  {getTranslation(communicatorUsage)}
                </Typography>
              </div>
            </div>
            <div className={classes.app}>
              <Paper className={classes.logo} variant="outlined">
                <SmartboardIcon />
              </Paper>
              <div>
                <Typography variant="body2">SMARTBOARD</Typography>
                <Typography variant="body2" style={{ color: getTextColor(smartboardUsage) }}>
                  {getTranslation(smartboardUsage)}
                </Typography>
              </div>
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.apps}>
            <div className={classes.app}>
              <Paper className={classes.logo} variant="outlined">
                <CommunicatorIcon />
              </Paper>
              <div>
                <Typography variant="body2">COMMUNICATOR ENGAGEMENT</Typography>
                <Typography variant="body2" style={{ color: getTextColor(communicatorEngagement) }}>
                  {getTranslation(communicatorEngagement)}
                </Typography>
              </div>
            </div>
            <div className={classes.app}>
              <Paper className={classes.logo} variant="outlined">
                <LearningAppIcon />
              </Paper>
              <div>
                <Typography variant="body2">LEARNING APP</Typography>
                <Typography variant="body2" style={{ color: getTextColor(learningAppUsage) }}>
                  {getTranslation(learningAppUsage)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <div className={classes.arvrAppsUsage}>
        <ARVRAppsUsageCard title="AR" data={data.ar} studentDash />
        <ARVRAppsUsageCard title="VR" data={data.vr} studentDash />
      </div>
    </div>
  );
};

export default StudentProfile;
