import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

interface IProps {
  text?: string;
  [key: string]: any;
}

const NoData: React.FC<IProps> = ({ text, ...props }) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <Typography variant="body2" color="textSecondary">
        {text || t('Dashboard.no_data')}
      </Typography>
    </div>
  );
};

export default NoData;
