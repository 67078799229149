export interface IData<T> {
  count: number;
  links?: {
    previous?: string;
    next?: string;
  };
  results: T[];
}

interface IMessageCore {
  body: string;
  chat_id: number;
  created_at: string;
  message_id: number;
  user_id: number | string;
}

export interface IMessage extends IMessageCore {
  message_type: string;
}

export interface IFile extends IMessageCore {
  message_type: 'file';
  file_data: IFileData;
  user: IUser;
  chat: IChat;
}

export interface IMedia extends IMessageCore {
  message_type: 'image' | 'audio' | 'video';
  file_data: IFileData;
  user: IUser;
  chat: IChat;
}

export interface ILink extends IMessageCore {
  message_type: 'link';
  user: IUser;
  chat: IChat;
  link_title?: string;
  link_description?: string;
}

export interface IBoard extends IMessage {
  link_description?: string;
  link_title?: string;
  link_url?: string;
}

export interface IFileData {
  name: string;
  original_name: string;
  size: number;
  created_at: string;
  expires_at: string | undefined;
  content_type: string;
  message_type_suggestion: string;
  media_file_url: string | undefined;
  media_file_thumb_url: string | undefined;
}

export interface IUser {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  bio_info: string;
  image_url: string;
}

export interface IChat {
  chat_id: number;
  creator_id: string;
  draft: IMessage | null; // TODO: create type
  has_lecturer: boolean;
  image: string;
  signed_url: string | undefined;
  latest_message: IMessage | null; // TODO: create type
  private: boolean;
  title: string;
  user_left: boolean;
  users: IUser[];
}

export type State = {
  data: {
    files: IData<IFile>;
    media: IData<IMedia>;
    links: IData<ILink>;
    smartboards: IData<IBoard>;
  };
  fileData: {
    [key: number]: IFileData;
  };
  users: {
    [key: string]: IUser;
  };
  chats: {
    [key: number]: IChat;
  };
};

export enum ActionType {
  SET_DATA = 'SET_DATA',
  APPEND_DATA = 'APPEND_DATA',
  SET_FILE_DATA = 'SET_FILE_DATA',
  SET_USERS = 'SET_USERS',
  SET_CHATS = 'SET_CHATS',
}

type DataType = IFile | IMedia | ILink | IBoard;

export interface ISetData {
  type: typeof ActionType.SET_DATA;
  payload: {
    key: Key;
    response: IData<DataType>;
  };
}

export interface IAppendData {
  type: typeof ActionType.APPEND_DATA;
  payload: {
    key: Key;
    response: IData<DataType>;
  };
}

export interface ISetFileData {
  type: typeof ActionType.SET_FILE_DATA;
  payload: { [message_id: number]: IFileData }[];
}

export interface ISetUsers {
  type: typeof ActionType.SET_USERS;
  payload: { [user_id: string]: IUser }[];
}

export interface ISetChats {
  type: typeof ActionType.SET_CHATS;
  payload: { [chat_id: string]: IChat }[];
}

export type Key = keyof State['data'];
export type Action = ISetData | IAppendData | ISetFileData | ISetUsers | ISetChats;
export type ActionCreator = Action | ((dispatch: (action: Action) => void, getState: () => State) => void);

export type SortOptionsType = { sort_order: 'ASC' | 'DESC'; sort_by: 'date' };
export type ActiveFiltersType = { chat_ids: number[]; lecturer_ids: number[] };
export type GetDataPayload = SortOptionsType | ActiveFiltersType;

export type MaterialsRouteProps = {
  sortOptions: SortOptionsType;
  activeFilters: ActiveFiltersType;
};

export type Filters = {
  chats: { id: number; title: string }[];
  lecturer_ids: number[];
  lecturers: { user_id: number; first_name: string; last_name: string }[];
};
