export interface ICalendarState {
  events: IEvent[];
  calendars: ICalendar[];
  last_checked: ICalendarLastChecked;
}

export interface ICalendarLastChecked {
  timestamp: string;
  from: string;
  to: string;
}

export interface ICalendar {
  id: string;
  azure_id: string;
  is_primary: boolean;
  user_id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface IEvent {
  id: number;
  azure_id: string;
  user_id: number;
  calendar: ICalendar;
  subject: string;
  description: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  azure_created_at: string;
  azure_updated_at: string;
  location: string;
  state?: 'CREATED' | 'UPDATED' | 'REMOVED';
}

export const SET_CALENDARS = 'SET_CALENDARS';
export const SET_EVENTS = 'SET_EVENTS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const UPDATE_LAST_CHECKED = 'UPDATE_LAST_CHECKED';

export interface ISetEventsAction {
  type: typeof SET_EVENTS;
  payload: {
    events: IEvent[];
    from: string;
    to: string;
  };
}

export interface ISetCalendarsAction {
  type: typeof SET_CALENDARS;
  payload: ICalendar[];
}

export interface ICreateEventAction {
  type: typeof CREATE_EVENT;
  payload: IEvent;
}

export interface IUpdateEventAction {
  type: typeof UPDATE_EVENT;
  payload: IEvent;
}

export interface IRemoveEventAction {
  type: typeof REMOVE_EVENT;
  payload: IEvent['id'];
}

export interface IUpdateLastCheckedAction {
  type: typeof UPDATE_LAST_CHECKED;
  payload: ICalendarLastChecked;
}

export type CalendarActionTypes =
  | ISetCalendarsAction
  | ISetEventsAction
  | ICreateEventAction
  | IUpdateEventAction
  | IRemoveEventAction
  | IUpdateLastCheckedAction;
