import {
  ISearchState,
  SearchActionTypes,
  SET_INIT_SEARCH,
  SET_INIT_VALUE_APPLIED,
  SET_SEARCH_LOADING,
  SET_SEARCH_RESULTS,
  INCREMENT_INDEX,
  DECREMENT_INDEX,
  SET_INDEX,
  RESET_SEARCH,
  SET_SEARCH_TERM,
  SET_NEXT_PAGE,
  APPEND_SEARCH_RESULTS,
  UPDATE_USER_IMAGES,
} from './types';

export default function reducer(
  state: ISearchState = {
    loading: false,
    results: [],
    searchTerm: '',
    initSearch: '',
    initValueApplied: false,
    focusedResultIndex: -1,
    nextPage: null,
  },
  action: SearchActionTypes
) {
  switch (action.type) {
    case SET_SEARCH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case SET_INIT_SEARCH:
      return {
        ...state,
        initSearch: action.payload,
      };
    case SET_INIT_VALUE_APPLIED:
      return {
        ...state,
        initValueApplied: action.payload,
      };
    case INCREMENT_INDEX:
      if (state.results.length === 0) return state;
      return {
        ...state,
        focusedResultIndex: (state.focusedResultIndex + 1) % state.results.length,
      };
    case DECREMENT_INDEX:
      if (state.results.length === 0) return state;
      const newIndex = state.focusedResultIndex <= 0 ? state.results.length - 1 : state.focusedResultIndex - 1;
      return {
        ...state,
        focusedResultIndex: newIndex,
      };
    case SET_INDEX:
      return {
        ...state,
        focusedResultIndex: action.payload,
      };
    case RESET_SEARCH:
      return {
        ...state,
        results: [],
        focusedResultIndex: -1,
      };
    case SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: action.payload,
      };
    case APPEND_SEARCH_RESULTS:
      return {
        ...state,
        results: [...state.results, ...action.payload],
      };
    case UPDATE_USER_IMAGES:
      return {
        ...state,
        results: state.results.map((r) =>
          r.image_url ? r : { ...r, image_url: 'data:image/png;base64,' + action.payload[r.user_id] }
        ),
      };
    default:
      return state;
  }
}
