import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { UsageCardData } from '../types';
import { getChartAnalyticsData, getColors } from '../utils';
import generateLegendPlugin from '../legendPlugin';
import CardContainer from './CardContainer';
import CommunicatorIcon from '../../../../assets/CommunicatorIcon';
import NoData from '../shared/NoData';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';

const CommunicatorUsageCard: React.FC<UsageCardData> = ({ title, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = getColors(theme);
  const legendPlugin = useMemo(
    () =>
      generateLegendPlugin({
        ulStyles: {
          marginTop: '8px',
          justifyContent: 'flex-start',
        },
        textStyles: { color: theme.palette.text.secondary },
      }),
    [theme.palette.text.secondary]
  );

  const { labels, percents, backgroundColor } = getChartAnalyticsData({ title, data }, colors, t);
  const handle = title.toLowerCase().split(' ').join('-');

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      appInfo: {
        padding: theme.spacing(3),
        '& .MuiTypography-subtitle2': {
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
      },
      chartContainer: {
        position: 'relative',
        height: 225,
        width: 225,
        margin: '0 auto',
        marginTop: theme.spacing(5),
      },
      percentage: {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        fontSize: '2rem',
        color: theme.palette.primary.main,
      },
      link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'uppercase',
        letterSpacing: '0.1px',
        fontSize: '.9em',
        fontWeight: 'bold',
      },
      noData: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 200,
      },
    })
  )();

  if (!data) return null;

  const containerTitle = `${title} user engagement`.toUpperCase();
  const legendId = `${title}-analytics-legend`;
  const detailsUrl = `/dashboard/analytics/${handle}`;
  const engagedPercent = data?.engagement?.engaged_percent.toFixed(0);

  return (
    <CardContainer title={containerTitle} Icon={CommunicatorIcon} legendId={legendId} detailsUrl={detailsUrl}>
      {data.users_data.length ? (
        <>
          <div className={classes.chartContainer}>
            <span className={classes.percentage}>{engagedPercent}%</span>
            <Doughnut
              type="doughnut"
              data={{
                labels,
                datasets: [
                  {
                    data: percents,
                    backgroundColor,
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  htmlLegend: {
                    containerID: legendId,
                  },
                },
                cutout: '90%',
              }}
              plugins={[legendPlugin]}
            />
          </div>
        </>
      ) : (
        <NoData className={classes.noData} />
      )}
    </CardContainer>
  );
};

export default CommunicatorUsageCard;
