import * as React from 'react';

function SvgCalendarEventTimeGreen(props) {
  return (
    <svg
      id="calendar_event_time_green_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 26 21"
      width="1em"
      height="1em"
      stroke="#65a4ac"
      {...props}
    >
      <defs>
        <style>{'.calendar_event_time_green_svg__cls-1{fill:none;stroke-linecap:round;stroke-miterlimit:10}'}</style>
      </defs>
      <g id="calendar_event_time_green_svg__Group_337" data-name="Group 337">
        <circle
          id="calendar_event_time_green_svg__Ellipse_42"
          data-name="Ellipse 42"
          className="calendar_event_time_green_svg__cls-1"
          cx={12.68}
          cy={10.26}
          r={8}
        />
        <path
          id="calendar_event_time_green_svg__Path_146"
          data-name="Path 146"
          className="calendar_event_time_green_svg__cls-1"
          d="M12.5 5.5V11l5.13 2.81"
        />
      </g>
    </svg>
  );
}

export default SvgCalendarEventTimeGreen;
