import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IFile, MaterialsRouteProps } from '../types';
import { DispatchContext, StateContext } from '../context/context';
import { getData, loadMore } from '../context/actions';
import File from './File';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const Files: React.FC<MaterialsRouteProps> = ({ sortOptions, activeFilters }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { t } = useTranslation();

  const data = state.data.files;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getData('files', { ...sortOptions, ...activeFilters }));
      setLoading(false);
    })();
  }, [dispatch, sortOptions, activeFilters]);

  const handleLoadMore = async () => {
    if (!data?.links?.next) return;
    setLoadingMore(true);
    await dispatch(loadMore('files', data.links.next));
    setLoadingMore(false);
  };

  const renderSkeleton = () => (
    <div>
      {[...new Array(10)].map((item, index) => (
        <div className={classes.skeletonItem} key={index}>
          <Skeleton width={30} height={55} />
          <div className={classes.text}>
            <Skeleton width={175} height={20} />
            <Skeleton width={200} height={15} className={classes.info} />
          </div>
        </div>
      ))}
    </div>
  );

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      skeletonItem: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
      },
      text: { marginLeft: theme.spacing(2) },
      info: { marginTop: theme.spacing(0.5) },
      loadMore: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
  )();

  return (
    <div>
      {loading ? (
        renderSkeleton()
      ) : data ? (
        data.results.length ? (
          <div>
            {data.results.map((file: IFile) => (
              <File key={file.message_id} data={file} />
            ))}
            {data.count > data.results.length && (
              <div className={classes.loadMore}>
                <Button onClick={handleLoadMore} disabled={loadingMore}>
                  {t('Home.LearningMaterials.load_more')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Home.LearningMaterials.no_files')}
            </Typography>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Files;
