import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './redux/store';
import { setSidebarOpen, setSearchOpen } from './redux/App/actions';
import {
  getSearchOpen,
  getSettings,
  getSidebarOpen,
  getActiveTenant,
  getChangingTenant,
  getIsTenantHeaderSet,
} from './redux/App/selectors';
import { getCurrentUser } from './redux/CurrentUser/actions';
import { getTOSAccepted } from './redux/CurrentUser/selectors';
import { setInitSearch } from './redux/Search/actions';
import { Auth } from './api/azureAuth';
import { getDataFromApi, putDataToApi } from './api/dataFromApi';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import theme, { ColorsMapType, getThemeColors } from './utils/theme';
import Settings from './components/Settings/Settings';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Calendar from './components/Calendar/Calendar';
import Sidebar from './components/Sidebar/Sidebar';
import SearchResults from './components/SearchResults/SearchResults';
import Profile from './components/Settings/Profile/Profile';
import TenantsModal from './components/TenantsModal';
import TOSModal from './components/TOSModal';
import TOSRefusedPage from './components/TOSRefusedPage';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const sidebarOpen = useSelector(getSidebarOpen);
  const searchOpen = useSelector(getSearchOpen);
  const settings = useSelector(getSettings);
  const activeTenant = useSelector(getActiveTenant);
  const changingTenant = useSelector(getChangingTenant);
  const tenantHeaderSet = useSelector(getIsTenantHeaderSet);
  const tenants = useSelector((state: IStore) => state.App.tenants);
  const tenantsFetched = useSelector((state: IStore) => state.App.tenantsFetched);
  const tosAcceptedSelector = useSelector(getTOSAccepted);
  const [tosRefused, setTosRefused] = useState(false);
  const [tosResolved, setTosResolved] = useState(false);

  // eslint-disable-next-line
  const muiTheme = useMemo(() => createMuiTheme(theme), [settings]);

  useEffect(() => {
    (async () => {
      try {
        await Auth.getToken();
        if (!tosResolved) {
          setTosResolved(true);
          await dispatch(getCurrentUser());
          setIsLoading(false);
        } else if (tosAcceptedSelector) {
          try {
            if (!tenantsFetched) await dispatch(getCurrentUser());
            await putDataToApi('analytics/refresh/', {});
            await getDataFromApi('auth/register/azure-ad/');
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, tosResolved, tosAcceptedSelector, tenantsFetched]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTerm = urlParams.get('search_term');
    if (searchTerm) {
      try {
        const toAscii = atob(searchTerm);
        if (sidebarOpen) dispatch(setSidebarOpen(false));
        dispatch(setSearchOpen(true));
        dispatch(setInitSearch(toAscii));
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createMuiTheme(theme: any) {
    const accent = settings.common.color_accent;
    const mode = settings.common.color_theme === 'Classic' ? 'light' : 'dark';
    const fontSize = settings.learning_app.font_size * theme.globals.fontSize;
    const fontFamily = `${settings.learning_app.font_family}, '${
      settings.learning_app.font_family === 'Times New Roman' ? 'serif' : 'sans-serif'
    }'`;
    const themeVariant = theme.variants[accent][mode];
    themeVariant.typography = { fontSize, fontFamily };
    if (settings.common.background_color === 'default') {
      themeVariant.palette.background = {};
    } else {
      themeVariant.palette.background = getThemeColors(settings.common.background_color as keyof ColorsMapType);
    }
    return createTheme(themeVariant);
  }

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      '@global': {
        html: { fontSize: 16 },
        [theme.breakpoints.down('sm')]: {
          html: { fontSize: 14 },
        },
      },
      app: {
        display: 'flex',
        flexDirection: 'column',
        contentVisibility: 'auto',
      },
      content: {
        position: 'relative',
        minHeight: sidebarOpen ? 'unset' : 'calc(100vh - 64px - 48px)',
        height: sidebarOpen ? '0px' : '100%',
        overflow: 'hidden',
        transition: '.2s',
        [theme.breakpoints.down('xs')]: {
          minHeight: sidebarOpen || searchOpen ? 'unset' : 'calc(100vh - 56px - 48px)',
          height: searchOpen ? 'calc(100vh - 56px - 48px)' : '100%',
        },
      },
      footer: {
        width: '100%',
        height: sidebarOpen ? '0px' : '48px',
        overflow: 'hidden',
        display: 'flex',
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
          height: sidebarOpen || searchOpen ? '0px' : '48px',
        },
      },
      tosTitle: { textAlign: 'center' },
      tosContent: {
        '& > h3': { marginBottom: '1rem' },
        '& > p': { marginBottom: '1rem' },
      },
      tosActions: {
        justifyContent: 'flex-start',
        paddingBottom: '1.5rem',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    })
  )();

  if (!tosResolved) return null;

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {!tosAcceptedSelector && !isLoading ? (
        tosRefused ? (
          <TOSRefusedPage />
        ) : (
          <TOSModal refuse={() => setTosRefused(true)} />
        )
      ) : (
        <BrowserRouter>
          {!isLoading && (
            <div className={classes.app} style={{ backgroundColor: muiTheme.palette.background.paper }}>
              <Header />
              <Sidebar />
              <div className={classes.content}>
                <SearchResults />
                {!changingTenant && activeTenant && tenantHeaderSet ? (
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/unit/:name" exact component={Home} />
                    <Route path="/materials" component={Home} />
                    <Route path="/dashboard" component={Home} />
                    <Route path="/calendar" exact component={Calendar} />
                    <Route path="/account-settings" component={Settings} />
                    <Route path="/profile/:id" exact component={Profile} />
                    <Redirect to="/" />
                  </Switch>
                ) : (
                  <TenantsModal tenants={tenants} />
                )}
              </div>
              <Footer />
            </div>
          )}
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
};

export default App;
