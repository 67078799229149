import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { StudentData } from '../types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';
import { green } from '@material-ui/core/colors';

interface RouteParams {
  mname: string;
  mtype: string;
}

const KnowledgeItems: React.FC<{ data: StudentData }> = ({ data }) => {
  const { mname, mtype } = useParams<RouteParams>();

  const title = `${mtype} ${mname} Knowledge Items`;
  const { profile_image, first_name, last_name } = (data as { [key: string]: any })[mtype.toLowerCase()].users_data[0];
  const { knowledge_total, knowledge_finished } = (data as { [key: string]: any })[mtype.toLowerCase()].users_data[0]
    .usage[mname];

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      knowledgeItemsContainer: {
        padding: '0 10%',
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: { padding: `0 ${theme.spacing(2)}px` },
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: `${theme.spacing(2)}px 0`,
        '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
      },
      knowledgeItems: {
        padding: `${theme.spacing(2)}px 0`,
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateRows: `repeat(${knowledge_total / 2}, auto)`,
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(1),
      },
      knowledgeItem: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '6px',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      check: { fill: green[500] },
      lock: { fill: theme.palette.action.active },
      textDisabled: { color: theme.palette.text.disabled },
    })
  )();

  return (
    <div className={classes.knowledgeItemsContainer}>
      <div className={classes.header}>
        <Link to="/dashboard">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="subtitle1">{title}</Typography>
      </div>
      <Card elevation={0}>
        <CardHeader
          avatar={<Avatar src={profile_image} />}
          title={`${first_name} ${last_name}`}
          subheader={`Enrolled ${mname} ${mtype}`}
        />
      </Card>
      <div className={classes.knowledgeItems}>
        {Array.from(Array(knowledge_total)).map((k, i) => (
          <div className={classes.knowledgeItem} key={i}>
            <Typography variant="body2" className={knowledge_finished <= i ? classes.textDisabled : ''}>
              Knowledge Item {i + 1}
            </Typography>
            {knowledge_finished > i ? <CheckIcon className={classes.check} /> : <LockIcon className={classes.lock} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgeItems;
