import React, { createContext } from 'react';
import { Group, PerformanceData, Subjects, UsageData } from './types';

const DashboardContext = createContext<{
  group: Group | null;
  handleGroupChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fromPerformance: boolean
  ) => void;
  groups: Group[];
  subjects: Subjects | null;
  subjectsLoading: boolean;
  fetchSubjects: () => void;
  usageData: UsageData | null;
  performanceData: PerformanceData | null;
  performanceGroups: Group[];
  performanceGroup: Group | null;
  fetchUsageData: () => void;
  fetchPerformanceData: (group: Group | null, subjectId: string) => void;
  fetchPerformanceGroups: (subjectId: string) => void;
}>({
  group: null,
  handleGroupChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fromPerformance: boolean
  ) => {},
  groups: [],
  subjects: [],
  subjectsLoading: false,
  fetchSubjects: () => {},
  usageData: null,
  performanceData: null,
  performanceGroups: [],
  performanceGroup: null,
  fetchUsageData: () => {},
  fetchPerformanceData: (group: Group | null, subjectId: string) => {},
  fetchPerformanceGroups: (subjectId: string) => {},
});

export default DashboardContext;
