import moment from 'moment';

export const downloadFile = async (file_data: any) => {
  const { media_file_url, original_name } = file_data;
  const a = document.createElement('a');
  a.href = media_file_url;
  a.download = original_name;
  a.target = '_blank';
  a.click();
};

export const getFileInfo = (file_data: any) => {
  const { size, expires_at } = file_data;

  const expiration = moment(expires_at).fromNow();
  const sizeInMB = size / 1024 / 1024;
  const sizeText = sizeInMB < 1 ? `${Math.trunc(sizeInMB * 1024)}KB` : `${sizeInMB.toFixed(2)}MB`;

  return { expiration, sizeText };
};

export const getFileType = (contentType: string) => {
  switch (contentType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'word';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'powerpoint';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'excel';
    default:
      return '';
  }
};

export const getSizeText = (size: number) => {
  const sizeInMB = size / 1024 / 1024;
  return sizeInMB < 1 ? `${Math.trunc(sizeInMB * 1024)}KB` : `${sizeInMB.toFixed(2)}MB`;
};

export const findExtension = (name: string) => {
  if (!name) return '';
  const splitName = name.split('.');
  const extension = splitName[splitName.length - 1];
  return extension.length < 4 ? extension.toUpperCase() : '';
};
