import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../../../redux/App/selectors';
import { updateSettings } from '../../../redux/App/actions';

const Privacy: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const displayBadges = settings.common.badges_visible;

  const updateBadgeVisibility = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSettings('common', {
        badges_visible: e.target.value,
      })
    );
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        gap: `${theme.spacing(3.5)}px`,
        color: theme.palette.text.primary,
        '& main': { flex: 2.25 },
        '& aside': {
          flex: 1,
          [theme.breakpoints.down('md')]: { display: 'none' },
        },
      },
      section: {
        padding: `${theme.spacing(2)}px 0`,
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
        '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
        '& .MuiTypography-caption': { color: theme.palette.text.secondary },
      },
      radioGroup: {
        padding: theme.spacing(1),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
      },
    })
  )();

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.section}>
          <Typography variant="subtitle1">{t('Settings.Privacy.display_badges')}</Typography>
          <div className={classes.radioGroup}>
            <FormControl component="fieldset">
              <RadioGroup value="publicly" onChange={updateBadgeVisibility}>
                <FormControlLabel
                  value="true"
                  label={t('Settings.Privacy.everybody')}
                  control={<Radio color="primary" />}
                  checked={displayBadges === 'true'}
                />
                <FormControlLabel
                  value="false"
                  label={t('Settings.Privacy.only_me')}
                  control={<Radio color="primary" />}
                  checked={displayBadges !== 'true'}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </main>
      <aside />
    </div>
  );
};

export default Privacy;
