import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { uploadBadge } from '../../../redux/CurrentUser/actions';
import { IOpenBadge } from '../../../redux/CurrentUser/types';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '../../shared/Dialog/Dialog';
import DialogTitle from '../../shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Portal from '@material-ui/core/Portal';

const OpenBadgeUploader: React.FC<{ fromSidebar?: boolean }> = ({ fromSidebar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<{ type: 'error' | 'warning' | 'success'; message: string }>({
    type: 'error',
    message: t('Home.OpenBadgeUploader.alert'),
  });

  const uploadOpenBadge = async (file: FileObject, close: () => void) => {
    setIsUploading(true);
    const response: unknown = await dispatch(uploadBadge(file.file));
    setIsUploading(false);
    setOpen(true);
    if ((response as IOpenBadge).is_valid) {
      setAlert({
        type: 'success',
        message: t('Home.OpenBadgeUploader.success'),
      });

      close();
    } else if ((response as IOpenBadge).hasOwnProperty('is_valid') && !(response as IOpenBadge).is_valid) {
      setAlert({
        type: 'warning',
        message: t('Home.OpenBadgeUploader.warning'),
      });
    } else if ((response as AxiosResponse).status === 400 && (response as AxiosResponse).data.is_valid) {
      setAlert({
        type: 'error',
        message: t('Home.OpenBadgeUploader.not_a_open_badge'),
      });
    } else if ((response as AxiosResponse).status === 400 && (response as AxiosResponse).data.error) {
      let message = (response as AxiosResponse).data.error;
      if (message === 'That badge already exists for this user') message = t('Home.OpenBadgeUploader.badge_exists');
      setAlert({
        type: 'error',
        message,
      });
    } else {
      setAlert({
        type: 'error',
        message: t('Home.OpenBadgeUploader.alert'),
      });
    }
  };

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        display: 'none',
      },
      root: {
        marginTop: theme.spacing(2),
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(4)}px`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        outline: 'none',
      },
      textContainer: {
        minHeight: fromSidebar ? theme.spacing(10) : theme.spacing(31.25),
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: 3,
        display: 'grid',
        placeItems: 'center',
      },
      text: {
        whiteSpace: 'pre-line',
        fontSize: '1.2em !important',
        lineHeight: '1.8em !important',
        padding: `0 ${theme.spacing(6)}`,
        color: theme.palette.text.secondary,
      },
      customHoverFocus: {
        width: fromSidebar ? theme.spacing(2.5) : theme.spacing(12.5),
        height: fromSidebar ? theme.spacing(2.5) : theme.spacing(12.5),
        [theme.breakpoints.down('xs')]: {
          width: fromSidebar ? theme.spacing(2.5) : theme.spacing(10),
          height: fromSidebar ? theme.spacing(2.5) : theme.spacing(10),
        },
        justifySelf: 'center',
        '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.action.hover },
      },
    })
  )();

  return (
    <>
      <Dialog
        trigger={
          <IconButton className={classes.customHoverFocus}>
            <Tooltip title={t('Home.OpenBadgeUploader.add') as string}>
              <AddIcon color="primary" />
            </Tooltip>
          </IconButton>
        }
      >
        {(close: () => void) => (
          <>
            <DialogTitle onClose={close} />
            <DialogContent>
              <form>
                <DropzoneAreaBase
                  acceptedFiles={['image/*']}
                  filesLimit={1}
                  fileObjects={[]}
                  // dropzoneText={t('Home.OpenBadgeUploader.file')}
                  dropzoneText={
                    !isUploading
                      ? `${t('Home.OpenBadgeUploader.uploadArea.drag')}
                          ${t('Home.OpenBadgeUploader.uploadArea.or')}
                          ${t('Home.OpenBadgeUploader.uploadArea.browse')}`
                      : 'Uploading file...'
                  }
                  classes={{
                    icon: classes.icon,
                    root: classes.root,
                    textContainer: classes.textContainer,
                    text: classes.text,
                  }}
                  showAlerts={['error', 'info']}
                  onAdd={(files) => uploadOpenBadge(files[0], close)}
                  getDropRejectMessage={() => `${t('Home.OpenBadgeUploader.max_file_size')}`}
                  // dropzoneClass={dropzoneAnimation}
                />
              </form>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Portal>
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
        >
          <Alert onClose={handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default OpenBadgeUploader;
