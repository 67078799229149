import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../api/dataFromApi';
import { ITimetable } from '../../../utils/types';
import { getDataFromClusterName } from '../../../utils/clusterUtils';
import { getActiveTenant } from '../../../redux/App/selectors';
import { IData } from '../../Home/LearningMaterials/types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Skeleton from '@material-ui/lab/Skeleton';

interface Entity {
  id: number;
  from: string;
  to: string;
  subject: string;
  teacher: string;
  classroom?: string;
}

interface TimetableData {
  entities: Entity[];
  day: string;
}

const Timetable: React.FC = () => {
  const [data, setData] = useState<TimetableData[] | null>(null);
  const [resultIndex, setResultIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const activeTenant = useSelector(getActiveTenant);

  const _mounted = useRef(false);

  useEffect(() => {
    _mounted.current = true;
    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getDataFromApi(`timetables/`, 'classter');
        const data: { [key: string]: TimetableData } = {};
        (response as IData<ITimetable>).results.forEach((timetable) => {
          const entities = [
            ...(data[timetable.day]?.entities || []),
            {
              id: timetable.id,
              from: timetable.periods[0].from,
              to: timetable.periods[timetable.periods.length - 1].to,
              subject: timetable.entities[0].subject.description,
              teacher: `${timetable.entities[0].teacher.givenName} ${timetable.entities[0].teacher.surname}`,
              classroom: timetable.classroom?.description,
            },
          ];
          data[timetable.day] = {
            day: timetable.day,
            entities: entities.sort(function (a, b) {
              return a.from.localeCompare(b.from);
            }),
          };
          _mounted.current && setData(Object.values(data));
        });
      } catch (e) {
        _mounted.current && setData(null);
        console.error(e);
      }
      _mounted.current && setLoading(false);
    })();
  }, [activeTenant]);

  const previous = () => {
    if (resultIndex === 0) return;
    setResultIndex((result) => result - 1);
  };

  const next = () => {
    if (!data || resultIndex >= data.length - 1) return;
    setResultIndex((result) => result + 1);
  };

  const noData = () => {
    return (
      <div className={classes.noData}>
        <Typography variant="body2" color="textSecondary">
          {t('Home.no_data_from_cmis')}
        </Typography>
      </div>
    );
  };

  const renderTable = () => {
    if (!data) return noData();
    if (data.length) {
      const table = data[resultIndex];
      return (
        <div className={classes.table}>
          <div className={classes.head}>
            <Typography variant="subtitle1">{table.day[0].toUpperCase() + table.day.substring(1)}</Typography>
            <div>
              <IconButton size="small" disabled={resultIndex === 0} onClick={previous}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton size="small" disabled={resultIndex >= data.length - 1} onClick={next}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.periods}>
            {table.entities.map((period) => (
              <div key={period.id} className={classes.period}>
                <div className={classes.coltop}>
                  <Typography variant="subtitle2">{getDataFromClusterName(period.subject)[1]}</Typography>
                  <Typography variant="caption" color="textSecondary" className={classes.desc}>
                    {period.from} {period.to}
                  </Typography>
                </div>
                <div className={classes.colbot}>
                  <Typography variant="body2" color="textSecondary">
                    {period.teacher}
                  </Typography>
                  {period.classroom && (
                    <Typography variant="body2" color="textSecondary">
                      {period.classroom}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return noData();
  };

  const renderSkeleton = () => (
    <div className={classes.table}>
      <div className={classes.head}>
        <Skeleton variant="text" width="100px" />
        <div>
          <IconButton size="small" disabled>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton size="small" disabled>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.periods}>
        {[...Array(5)].map((_, i) => (
          <div key={i} className={classes.period}>
            <div className={classes.coltop}>
              <Skeleton variant="text" width="220px" />
              <Skeleton variant="text" width="80px" />
            </div>
            <div className={classes.colbot}>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="40px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      timetable: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2),
          height: '100%',
          backgroundColor: theme.palette.background.default,
        },
        '& > .MuiTypography-h6': { fontWeight: 'bold' },
      },
      table: {
        '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
        '& .MuiTypography-subtitle2': { fontWeight: 'bold' },
      },
      head: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px 0`,
      },
      periods: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      },
      period: {
        background: theme.palette.background.default,
        padding: theme.spacing(1),
      },
      coltop: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      colbot: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      desc: { minWidth: 'max-content' },
      noData: {
        minHeight: '200px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
  )();

  return (
    <div className={classes.timetable}>
      <Typography variant="h6" gutterBottom>
        {t('Menus.timetable')}
      </Typography>
      {loading ? renderSkeleton() : renderTable()}
    </div>
  );
};

export default Timetable;
