import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../shared/Dialog/Dialog';
import BadgeContent from './BadgeContent/BadgeContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  badge: any;
}

const OtisBadge: React.FC<IProps> = ({ badge }) => {
  const { t } = useTranslation();

  const badgeDescription = [
    {
      achievement_id: 'learning_app_login',
      description: t('Settings.Appearance.Badges.learning_app_login'),
    },
    {
      achievement_id: 'communicator_app_login',
      description: t('Settings.Appearance.Badges.communicator_app_login'),
    },
    {
      achievement_id: 'ar_app_login',
      description: t('Settings.Appearance.Badges.ar_app_login'),
    },
    {
      achievement_id: 'vr_app_login',
      description: t('Settings.Appearance.Badges.vr_app_login'),
    },
    {
      achievement_id: 'login_hero',
      description: t('Settings.Appearance.Badges.login_hero'),
    },
    {
      achievement_id: 'novice_chatter',
      description: t('Settings.Appearance.Badges.novice_chatter'),
    },
    {
      achievement_id: 'chat_trainee',
      description: t('Settings.Appearance.Badges.chat_trainee'),
    },
    {
      achievement_id: 'chat_wizard',
      description: t('Settings.Appearance.Badges.chat_wizard'),
    },
    {
      achievement_id: 'mad_chatter',
      description: t('Settings.Appearance.Badges.mad_chatter'),
    },
    {
      achievement_id: 'profile_master',
      description: t('Settings.Appearance.Badges.profile_master'),
    },
    {
      achievement_id: 'group_leader',
      description: t('Settings.Appearance.Badges.group_leader'),
    },
    {
      achievement_id: 'networker',
      description: t('Settings.Appearance.Badges.networker'),
    },
  ];

  const date = new Date(`${badge.awarded_at}`);
  const string = badge.achievement_id;
  const desc = badgeDescription.find((o) => o.achievement_id === badge.achievement_id);
  const info = {
    title: string.replace(/(^|_)./g, (s: any) => s.toUpperCase().replace('_', ' ')),
    description: desc?.description,
    awarded: `${t('Settings.Appearance.Badges.received_on')} ${date.toString().slice(4, 15)}`,
    badge: badge.achievement_id,
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      trigger: {
        width: theme.spacing(12.5),
        height: theme.spacing(12.5),
        justifySelf: 'center',
        position: 'relative',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
          width: theme.spacing(10),
          height: theme.spacing(10),
        },
      },
      grayed: {
        filter: 'grayscale(100%)',
        opacity: 0.5,
        pointerEvents: 'none',
      },
    })
  )();

  return (
    <Dialog
      trigger={
        <div className={`${classes.trigger} ${!badge.is_achieved ? classes.grayed : ''}`}>
          <img
            width="100%"
            height="100%"
            src={require(`../../assets/badges/${badge.achievement_id}.png`).default}
            alt="OTIS Badge"
          />
        </div>
      }
    >
      {(close: () => void) => <BadgeContent info={info} handleClose={close} />}
    </Dialog>
  );
};

export default OtisBadge;
