import axios from 'axios';
import { Auth } from './azureAuth';

axios.defaults.timeout = 300000;

let token = '';
export const defaultVersion = process.env.REACT_APP_DEV_API_VERSION;
export const URL = process.env.REACT_APP_DEV_API_URL;

const buildURL = (version?: string) => {
  return `${URL}${version || defaultVersion}/`;
};

export const getDataFromApi = async (
  query: string,
  version?: string,
  forCalendar?: boolean,
  additionalHeaders?: { [key: string]: unknown }
) => {
  token = (await Auth.getToken()) as string;
  const headers: { [key: string]: string } = { Authorization: 'Bearer ' + token, ...additionalHeaders };
  if (forCalendar) {
    headers['Outlook-Authorization'] = (await Auth.getToken('https://graph.microsoft.com/Calendars.Read')) as string;
  }
  try {
    const response = await axios.get(`${buildURL(version)}${query}`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async (query: string, data: unknown, version?: string) => {
  try {
    const response = await axios.post(`${buildURL(version)}${query}`, data);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDataToApi = async (
  query: string,
  data: unknown,
  version?: string,
  additionalHeaders?: { [key: string]: unknown }
) => {
  token = (await Auth.getToken()) as string;
  const headers = { Authorization: 'Bearer ' + token, ...additionalHeaders };
  try {
    const response = await axios.post(`${buildURL(version)}${query}`, data, { headers });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const patchDataToApi = async (
  query: string,
  data: unknown,
  version?: string,
  additionalHeaders?: { [key: string]: unknown }
) => {
  token = (await Auth.getToken()) as string;
  const headers = { Authorization: 'Bearer ' + token, ...additionalHeaders };
  try {
    const response = await axios.patch(`${buildURL(version)}${query}`, data, { headers });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const putDataToApi = async (
  query: string,
  data: unknown,
  version?: string,
  additionalHeaders?: { [key: string]: unknown }
) => {
  token = (await Auth.getToken()) as string;
  const headers = { Authorization: 'Bearer ' + token, ...additionalHeaders };
  try {
    const response = await axios.put(`${buildURL(version)}${query}`, data, { headers });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDataFromApi = async (
  query: string,
  version?: string,
  additionalHeaders?: { [key: string]: unknown }
) => {
  token = (await Auth.getToken()) as string;
  const headers = { Authorization: 'Bearer ' + token, ...additionalHeaders };
  try {
    const response = await axios.delete(`${buildURL(version)}${query}`, { headers });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
