import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../api/dataFromApi';
import moment from 'moment';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const CodeGenerator: React.FC = () => {
  const [code, setCode] = useState('');
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  let timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!duration) return;
    timeout.current = setTimeout(() => {
      setDuration((duration) => duration - 1);
    }, 1000);
    return () => clearTimeout(timeout.current!);
  }, [duration]);

  const handleGenerateCode = async () => {
    setCode('');
    setLoading(true);
    try {
      const { code, generated_at } = await getDataFromApi('auth/user/generate_code');
      const end = moment.utc(generated_at).add(5, 'minutes');
      const remainingTime = moment.duration(end.diff(moment())).asSeconds();
      setCode(code);
      setDuration(Math.round(remainingTime));
    } catch (e) {
      console.error('Error occurred');
    } finally {
      setLoading(false);
    }
  };

  const renderAlerts = () => {
    if (!code || loading) return null;
    if (duration) {
      return (
        <Alert severity="warning" className={classes.alert}>
          {t('Settings.CodeGenerator.warning', { duration })}
        </Alert>
      );
    } else {
      return (
        <Alert severity="error" className={classes.alert}>
          {t('Settings.CodeGenerator.error')}
        </Alert>
      );
    }
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      codeGenerator: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
      code: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(2)}px 0`,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 4,
        width: 260,
        height: 60,
        display: 'flex',
        justifyContent: 'space-around',
      },
      digit: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        color: theme.palette.text.primary,
      },
      generateBtn: {
        textTransform: 'capitalize',
        letterSpacing: '.5px',
        marginTop: theme.spacing(1),
      },
      alert: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2),
        width: 'max-content',
      },
    })
  )();

  return (
    <div className={classes.codeGenerator}>
      <Typography variant="h6" gutterBottom color="primary">
        {t('Settings.CodeGenerator.title')}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t('Settings.CodeGenerator.subtitle')}
      </Typography>
      <div className={classes.code}>
        {code.split('').map((digit, i) => (
          <span key={i} className={classes.digit}>
            {digit}
          </span>
        ))}
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.generateBtn}
        onClick={handleGenerateCode}
        disabled={loading}
      >
        {loading ? t('Settings.CodeGenerator.loading') : t('Settings.CodeGenerator.generate')}
      </Button>
      {renderAlerts()}
    </div>
  );
};

export default CodeGenerator;
