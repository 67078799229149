import { Selector } from 'react-redux';
import { IStore } from '../store';
import { User } from './types';

export const isSearchLoading: Selector<IStore, boolean> = (state) => state.Search.loading;
export const searchResults: Selector<IStore, User[]> = (state) => state.Search.results;
export const getInitSearch: Selector<IStore, string> = (state) => state.Search.initSearch;
export const getInitValueApplied: Selector<IStore, boolean> = (state) => state.Search.initValueApplied;
export const getFocusedResultIndex: Selector<IStore, number> = (state) => state.Search.focusedResultIndex;
export const getNextPage: Selector<IStore, string | null> = (state) => state.Search.nextPage;
export const getSearchTerm: Selector<IStore, string> = (state) => state.Search.searchTerm;
