import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setSearchOpen } from '../../redux/App/actions';
import { getSearchOpen } from '../../redux/App/selectors';
import { incrementIndex, decrementIndex, setIndex, loadMore } from '../../redux/Search/actions';
import {
  getFocusedResultIndex,
  getNextPage,
  isSearchLoading,
  searchResults,
  getSearchTerm,
} from '../../redux/Search/selectors';
import { getName } from '../../utils/userUtils';
import SearchBar from '../Header/SearchBar/SearchBar';
import ProfileImage from '../ProfileImage/ProfileImage';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const SearchResults: React.FC = () => {
  const open = useSelector(getSearchOpen);
  const loading = useSelector(isSearchLoading);
  const results = useSelector(searchResults);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const nextPage = useSelector(getNextPage);
  const value = useSelector(getSearchTerm);
  const resultsEl = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (resultsEl.current) {
      const clientHeight = resultsEl.current.clientHeight;
      const scrollTop = resultsEl.current.scrollTop;
      const element = resultsEl.current.querySelector(`div:nth-of-type(${focusedResultIndex + 1})`) as HTMLElement;
      if (!element) return;
      if (clientHeight + scrollTop < element.offsetTop + element.clientHeight) {
        resultsEl.current.scrollTop = element.offsetTop - clientHeight + element.clientHeight;
      } else if (scrollTop > element.offsetTop) {
        if (focusedResultIndex === 0) {
          resultsEl.current.scrollTop = 0;
        } else {
          resultsEl.current.scrollTop = element.offsetTop;
        }
      }
    }
  }, [focusedResultIndex]);

  const handleClickAway = (e: any) => {
    if (Array.from(document.querySelectorAll('*[data-info=search-bar]')).some((el) => el.contains(e.target))) return;
    dispatch(setSearchOpen(false));
  };

  const handleClick = (id: string) => {
    dispatch(setIndex(-1));
    dispatch(setSearchOpen(false));
    document.getElementById('#root')?.focus();
    history.push(`/profile/${id}`);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      dispatch(decrementIndex());
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      dispatch(incrementIndex());
    } else if (e.key === 'Escape') {
      dispatch(setSearchOpen(false));
    } else if (e.key === 'Enter' && focusedResultIndex >= 0) {
      dispatch(setIndex(-1));
      dispatch(setSearchOpen(false));
      document.getElementById('#root')?.focus();
      history.push(`/profile/${results[focusedResultIndex].user_id}`);
    }
  };

  const searchIn = (app: 'communicator' | 'smartboard') => {
    let url = '';
    if (app === 'communicator') {
      url = process.env.REACT_APP_COMMUNICATOR_APP_URL as string;
    } else {
      url = (process.env.REACT_APP_SMARTBOARD_APP_URL as string) + '/dashboard';
    }

    window.open(`${url}?search_term=${btoa(value)}`, '_blank');
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: 'absolute',
        right: 0,
        minWidth: 500,
        minHeight: theme.spacing(20),
        maxHeight: '100%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        zIndex: 10,
        display: 'flex',
        flexFlow: 'column',
        '&:focus': { outline: 'none' },
        [theme.breakpoints.down('md')]: { minWidth: 450 },
        [theme.breakpoints.down('sm')]: {
          minWidth: 400,
          minHeight: '50vh',
        },
        [theme.breakpoints.down('xs')]: {
          minWidth: 'unset',
          width: '100vw',
          maxHeight: 'calc(100vh - 56px)',
        },
      },
      searchBar: {
        [theme.breakpoints.up('lg')]: { display: 'none' },
        height: theme.spacing(7.5),
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: 8,
        display: 'grid',
        placeItems: 'center',
        '& .MuiFormControl-root': {
          width: '90%',
          backgroundColor: theme.palette.background.default,
          '& .MuiInputBase-root': { width: '100%' },
        },
      },
      loading: {
        marginTop: theme.spacing(2),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      },
      subtitle: { marginLeft: theme.spacing(2) },
      noItems: {
        marginTop: theme.spacing(2),
        padding: `0 ${theme.spacing(2)}px`,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      results: {
        position: 'relative',
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      },
      result: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.action.hover },
        [`&:nth-of-type(${focusedResultIndex + 1})`]: { backgroundColor: theme.palette.action.focus },
      },
      actions: {
        padding: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        '& .MuiButton-root': {
          minWidth: 200,
          borderRadius: 16,
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
      seeMore: {
        padding: `${theme.spacing(1)}px 0`,
        display: 'grid',
        placeItems: 'center',
        '& .MuiButton-root': {
          textTransform: 'none',
          color: theme.palette.primary.main,
        },
      },
    })
  )();

  return open ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper elevation={1} className={classes.root} onKeyDown={handleKeyDown} tabIndex={0}>
        <div className={classes.searchBar}>
          <SearchBar keepMounted />
        </div>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : results.length ? (
          <div className={classes.results} ref={resultsEl}>
            <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
              {t('SearchResults.contacts')}
            </Typography>
            {results.map((result) => (
              <div className={classes.result} key={result.user_id} onClick={() => handleClick(result.user_id)}>
                <ProfileImage user={result} />
                <Typography variant="body1">{getName(result)}</Typography>
              </div>
            ))}
            {nextPage && (
              <div className={classes.seeMore}>
                <Button onClick={() => dispatch(loadMore())}>{t('SearchResults.see_more')}</Button>
              </div>
            )}
          </div>
        ) : (
          <p className={classes.noItems}>{t('SearchResults.no_items')}</p>
        )}
        <div className={classes.actions}>
          <Button variant="contained" color="primary" disableElevation onClick={() => searchIn('smartboard')}>
            {t('SearchResults.search_in_smartboard')}
          </Button>
          <Button variant="contained" color="primary" disableElevation onClick={() => searchIn('communicator')}>
            {t('SearchResults.search_in_communicator')}
          </Button>
        </div>
      </Paper>
    </ClickAwayListener>
  ) : null;
};

export default SearchResults;
