export interface ISettings {
  learning_app: ILearningAppSettings;
  common: ICommonSettings;
  calendar: ICalendarSettings;
}

export interface ICalendarSettings {
  [key: string]: {
    foreground: string;
    background: string;
  };
}

export interface ILearningAppSettings {
  font_size: number;
  font_family: string;
}

export interface ICommonSettings {
  color_theme: string;
  color_accent: string;
  background_color: string;
  language: string;
  badges_visible: string;
}

export type Partial<T> = {
  [P in keyof T]?: T[P];
};

export type SettingsUpdate<T> = Partial<T>;

export interface Tenants {
  [key: string]: {
    key: string;
    name: string;
    role: string;
  };
}

export interface IAppState {
  settings: ISettings;
  sidebarOpen: boolean;
  searchOpen: boolean;
  tenants: Tenants;
  tenantsFetched: boolean;
  active_tenant: string;
  active_role: string;
  changing_tenant: boolean;
  tenantHeaderSet: boolean;
}

export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_SEARCH_OPEN = 'SET_SEARCH_OPEN';
export const SET_ACTIVE_TENANT = 'SET_ACTIVE_TENANT';
export const SET_CHANGING_TENANT = 'SET_CHANGING_TENANT';
export const SET_TENANTS = 'SET_TENANTS';

interface ISetSettingsAction {
  type: typeof SET_SETTINGS;
  payload: ISettings;
}
interface ISetSidebarOpen {
  type: typeof SET_SIDEBAR_OPEN;
  payload: boolean;
}

interface ISetSearchOpen {
  type: typeof SET_SEARCH_OPEN;
  payload: boolean;
}

interface ISetActiveTenant {
  type: typeof SET_ACTIVE_TENANT;
  payload: {
    tenant: string;
    role: string;
  };
}

interface ISetChangingTenant {
  type: typeof SET_CHANGING_TENANT;
  payload: boolean;
}

interface ISetTenants {
  type: typeof SET_TENANTS;
  payload: Tenants;
}

export type SettingsActionTypes =
  | ISetSettingsAction
  | ISetSidebarOpen
  | ISetSearchOpen
  | ISetActiveTenant
  | ISetChangingTenant
  | ISetTenants;
