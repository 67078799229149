import React, { useState, useEffect, useRef } from 'react';
import { getDataFromApi } from '../../../../api/dataFromApi';
import UnitCard from './UnitCard';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Subject } from '../../Dashboard/types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getActiveTenant } from '../../../../redux/App/selectors';

const Units: React.FC = () => {
  const [data, setData] = useState<Subject[] | null>(null);
  const [loading, setLoading] = useState(false);
  const activeTenant = useSelector(getActiveTenant);
  const { t } = useTranslation();

  const _mounted = useRef(false);

  useEffect(() => {
    _mounted.current = true;
    return () => {
      _mounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getDataFromApi(`auth/user/subjects/`);
        _mounted.current && setData(response);
      } catch (e) {
        _mounted.current && setData(null);
        console.error(e);
      }
      _mounted.current && setLoading(false);
    })();
  }, [activeTenant]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      units: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(12.5rem, 1fr))`,
        gridAutoRows: '1fr',
        gap: '16px',
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: `repeat(auto-fill, minmax(10.7rem, 1fr))`,
          gap: '4px',
        },
      },
      noData: {
        minHeight: '200px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
  )();

  const noData = () => {
    return (
      <div className={classes.noData}>
        <Typography variant="body2" color="textSecondary">
          {t('Home.no_data_from_cmis')}
        </Typography>
      </div>
    );
  };

  return data ? (
    <div className={classes.units}>
      {data.map(({ title, id, moodle_id }, i) => (
        <UnitCard title={title} id={id} key={i} moodle_id={moodle_id} />
      ))}
    </div>
  ) : !loading ? (
    noData()
  ) : null;
};

export default Units;
