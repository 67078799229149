import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { CardContainerProps } from '../types';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const CardContainer: React.FC<CardContainerProps> = ({ title, Icon, legendId, detailsUrl, children }) => {
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      card: { padding: theme.spacing(2) },
      cardHeader: { display: 'flex' },
      cardInfo: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      },
      link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'uppercase',
        letterSpacing: '0.1px',
        fontSize: '.9em',
        fontWeight: 'bold',
      },
      title: { fontWeight: 'bold' },
      logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'max-content',
        borderRadius: '50%',
        padding: '0.5rem',
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
      },
      legend: {
        display: 'flex',
        gap: theme.spacing(2),
      },
      legendItem: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body2': {
          marginLeft: theme.spacing(1),
          fontSize: '.8em',
        },
        '&:first-child $circle': {
          background: '#1177D3',
        },
        '&:last-child $circle': {
          background: '#42C7DB',
        },
      },
      circle: {
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '50%',
      },
    })
  )();

  return (
    <Paper elevation={2} className={classes.card}>
      <div className={classes.cardHeader}>
        <Paper className={classes.logo} variant="outlined">
          <Icon />
        </Paper>
        <div className={classes.cardInfo}>
          {detailsUrl ? (
            <Link to={detailsUrl} className={classes.link}>
              {title}
            </Link>
          ) : (
            <Typography variant="subtitle2" className={classes.title}>
              {title}
            </Typography>
          )}
          {legendId ? (
            <div id={legendId} />
          ) : (
            <div className={classes.legend}>
              <div className={classes.legendItem}>
                <div className={classes.circle} />
                <Typography variant="body2" color="textSecondary">
                  {t('Dashboard.knowledge')}
                </Typography>
              </div>
              <div className={classes.legendItem}>
                <div className={classes.circle} />
                <Typography variant="body2" color="textSecondary">
                  {t('Dashboard.assessments')}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </Paper>
  );
};

export default CardContainer;
