import * as React from 'react';

function IconProsys(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17px"
      height="17px"
      viewBox="0 0 19.442 19.491"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>{'.prefix__fil0{fill:#47b245}'}</style>
      </defs>
      <g id="prefix__Layer_x0020_1">
        <g id="prefix___2025338493872">
          <path
            className="prefix__fil0"
            d="M0 2.047v15.441c0 .659.495 1.305.883 1.583.671.48.997.417 2.094.417h4.976c.048-.57.187-.248.187-1.674 0-.329-.093-.642-.19-.88-.122-.297-.339-.32-.741-.329l-2.093-.093c-.597-.004-1.116-.447-1.326-.86-.158-.31-.163-.459-.162-.908v-1.116c-.323-.155-.847-.106-1.126-.316-.836-.631.8-1.149.8-2.614 0-.364-.006-.508-.111-.866-.041-.141-.077-.199-.115-.303-.272-.756-.332-1.396-.332-2.227 0-2.363 1.902-3.971 3.787-4.724.294-.118.789-.299 1.104-.339 2.549-.317 5.509-.043 7.513 1.589 1.323 1.077 2.015 2.869 2.015 4.684 0 2.5-1.542 4.678-3.136 5.934-.463.365-.516.339-.993.821-.941.949-1.155 2.544-.841 3.936.032.142.016.285.179.285h5.07c.968 0 2-1.032 2-2V2.047c0-.639-.444-1.219-.804-1.522C18.184.142 17.611 0 16.791 0H2.744C1.877 0 1.369.099.856.484.473.771 0 1.403 0 2.047z"
          />
          <path
            className="prefix__fil0"
            d="M4.884 5.767v6.838c0 .693.305 1.349.976 1.349h2.512c.467 0 .93-.464.93-.931V5.349c0-.75-.753-.977-1.442-.977-1.699 0-2.976-.277-2.976 1.395zM9.86 5.349v4.186c0 .466.464.93.931.93h2.511c.467 0 .931-.464.931-.93V5.349c0-.332-.222-.606-.417-.746-.479-.343-1.774-.231-2.514-.231-.399 0-.747.04-.998.212-.216.147-.444.425-.444.765z"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconProsys;
