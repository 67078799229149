import React from 'react';
import { ARVRData } from '../types';
import IconIlp from '../../../../assets/IconILP';
import CardContainer from './CardContainer';
import ARVRAppsData from './ARVRAppsData';

interface Props {
  title: string;
  data?: ARVRData;
  studentDash?: boolean;
}

const ARVRAppsUsageCard: React.FC<Props> = ({ title, data, studentDash }) => {
  if (!data) return null;

  const containerTitle = `${title.toUpperCase()} APP USAGE`;

  return (
    <CardContainer title={containerTitle} Icon={IconIlp}>
      <ARVRAppsData data={data} studentDash={studentDash} />
    </CardContainer>
  );
};

export default ARVRAppsUsageCard;
