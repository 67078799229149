import React, { Ref } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { View, EventApi } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './FullCalendar.css';
import { getEvents, getAllCalendarColor } from '../../../redux/Calendar/selectors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const FullCalendarComponent = React.memo(
  React.forwardRef(
    (
      {
        isToday,
        setSmallCalendar,
        openPopup,
      }: {
        isToday: (day: Date) => boolean;
        setSmallCalendar: (data: View) => void;
        openPopup: ({
          event,
          el,
          jsEvent,
          view,
        }: {
          el: HTMLElement;
          event: EventApi;
          jsEvent: MouseEvent;
          view: View;
        }) => void;
      },
      ref: Ref<FullCalendar>
    ) => {
      const { t } = useTranslation();
      const tShortDays = t('Calendar.short_days', { returnObjects: true });
      const tMonths = t('Calendar.months', { returnObjects: true });
      const tShortMonths = t('Calendar.short_months', { returnObjects: true });

      const events = useSelector(getEvents);

      const rightHeader = 'dayGridMonth timeGridWeek timeGridDay';
      const calendarSettings = useSelector(getAllCalendarColor);

      const classes = makeStyles((theme: Theme) =>
        createStyles({
          fullCalendar: {
            '& td.fc-today': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .fc-today .fc-day-number': {
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          },
        })
      )();

      return (
        <div className={classes.fullCalendar}>
          <FullCalendar
            fixedWeekCount={false}
            slotEventOverlap={false}
            ref={ref}
            datesRender={({ view }) => setSmallCalendar(view)}
            firstDay={1}
            allDayText={t('Calendar.all_day')}
            header={{
              left: 'prev,next today',
              center: 'title',
              right: rightHeader,
            }}
            defaultView="dayGridMonth"
            navLinks={true}
            nowIndicator={true}
            buttonText={{
              today: t('Calendar.today'),
              month: t('Calendar.month'),
              week: t('Calendar.week'),
              day: t('Calendar.day'),
              list: t('Calendar.list'),
            }}
            views={{
              month: {
                titleFormat: ({ date }) => `${tMonths[date.month]} - ${date.year}`,
                columnHeaderText: (day) => tShortDays[new Date(Array.isArray(day) ? new Date() : day).getDay()],
                displayEventTime: true,
                eventTimeFormat: {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                },
                eventLimit: 5,
              },
              week: {
                titleFormat: ({ start, end }) => {
                  const startYear = start.year !== end?.year ? ` ${start.year}` : '';
                  const endMonth = start.month !== end?.month ? `${tShortMonths[end?.month || 0]} ` : '';
                  return `${tShortMonths[start.month]} ${start.day}${startYear} - ${endMonth}${end?.day}, ${end?.year}`;
                },
                columnHeaderHtml: (day) => {
                  const date = new Date(Array.isArray(day) ? new Date() : day);
                  return `
                  <div class="Flex">
                    <div>
                      ${tShortDays[date.getDay()]}
                    </div>
                    <div class="Day ${isToday(date) ? 'Today' : ''}">
                      ${date.getDate()}
                    </div>
                  </div>
                `;
                },
              },
              day: {
                titleFormat: ({ date }) => `${tMonths[date.month]} ${date.day} - ${date.year}`,
                columnHeaderText: (day) =>
                  t('Calendar.days', { returnObjects: true })[new Date(Array.isArray(day) ? new Date() : day).getDay()],
              },
            }}
            displayEventTime={true}
            plugins={[dayGridPlugin, timeGridPlugin]}
            events={events.map((event) => {
              const calendarSetting = calendarSettings?.[event.calendar.id];
              const color = calendarSetting ? calendarSetting.background : 'var(--calendar-1)';
              const textColor = calendarSetting ? calendarSetting.foreground : 'var(--background)';
              return {
                id: event.id,
                title: event.subject,
                start: event.start_time,
                end: event.end_time,
                color,
                textColor,
              };
            })}
            eventClick={openPopup}
          />
        </div>
      );
    }
  )
);

export default FullCalendarComponent;
