import * as React from 'react';

function SvgDefault(props) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 100 120" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M59.098 0L100 40.115V110c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0h49.098z"
          fill="#9E9E9E"
        />
        <path d="M100 40l-35.314-.86A10 10 0 0159 30.114V0h1l40 39v1z" fill="#F5F5F5" />
        <text fontFamily="Roboto-Bold, Roboto" fontSize="32" fontWeight="bold" letterSpacing="0.4410082" fill="#F2F2F2">
          <tspan x="16.6744252" y="73">
            {props.extension || ''}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgDefault;
