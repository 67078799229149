import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Auth } from '../../api/azureAuth';
import App from '../App/reducers';
import Search from '../Search/reducers';
import Calendar from '../Calendar/reducers';
import CurrentUser from '../CurrentUser/reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['App', 'CurrentUser', 'Search'],
};

const appConfig = {
  key: 'App',
  storage,
  blacklist: ['sidebarOpen', 'searchOpen', 'tenantHeaderSet'], // TODO: SEPARATE TO NEW MODULE WHAT WE DON'T SAVE IN LOCAL STORAGE
};

const allReducers = combineReducers({
  App: persistReducer(appConfig, App),
  CurrentUser,
  Calendar,
  Search,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

const rootReducer = (state: any, payload: { type: 'LOGOUT_USER' }) => {
  if (payload.type === 'LOGOUT_USER') {
    Auth.logout();
  }
  return persistedReducer(state, payload);
};

export default rootReducer;
