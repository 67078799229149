import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLastOpenBadges, getOpenBadges, getOTISBadges } from '../../../redux/CurrentUser/selectors';
import { IBadge } from '../../../redux/CurrentUser/types';
import RightArrowIcon from '../../../assets/OutlineArrowRight24Px';
import OpenBadge from '../../OpenBadge/OpenBadge';
import OpenBadgeUploader from '../../Home/OpenBadgeUploader/OpenBadgeUploader';
import OtisBadge from '../../OtisBadge/OtisBadge';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const Badges: React.FC = () => {
  const badges = useSelector(getOTISBadges);
  const openBadges = useSelector(getLastOpenBadges);
  const allOpenBadges = useSelector(getOpenBadges);
  const latestBadges: IBadge[] = makeLatestBadges();
  const [showAllBadges, setShowAllBadges] = useState(false);
  const [showAllOpenBadges, setShowAllOpenBadges] = useState(false);
  const { t } = useTranslation();

  function makeLatestBadges() {
    const categories = new Set(['login', 'message', 'social', 'communication', 'profile']);
    const sortFn = (a: any, b: any) => new Date(b.awarded_at).getTime() - new Date(a.awarded_at).getTime();
    const filterFn = (x: any) => x.is_achieved && categories.delete(x.category);
    return Object.values(badges).sort(sortFn).filter(filterFn);
  }

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: `${theme.spacing(2)}px 0`,
        [theme.breakpoints.down('xs')]: {
          padding: 0,
          height: '100%',
          backgroundColor: theme.palette.background.default,
        },
      },
      badges: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('sm')]: {
          backgroundColor: 'unset',
          '&:not(:first-child)': { marginTop: theme.spacing(2) },
        },
      },
      badgesGrid: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(12.5)}px, 1fr))`,
        gap: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1),
          gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(10)}px, 1fr))`,
          gap: `${theme.spacing(1)}px`,
        },
      },
      title: {
        marginBottom: theme.spacing(2),
        '& .MuiTypography-root': {
          fontWeight: 'bold',
        },
        [theme.breakpoints.down('xs')]: {
          fontWeight: 'normal',
          marginBottom: 0,
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.default,
        },
      },
      h6: { [theme.breakpoints.down('xs')]: { display: 'none' } },
      subtitle: { [theme.breakpoints.up('sm')]: { display: 'none' } },
      badgeUploaderUpSm: { [theme.breakpoints.down('xs')]: { display: 'none' } },
      badgeUploaderDownXs: { [theme.breakpoints.up('sm')]: { display: 'none' } },
      foot: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          padding: 0,
          marginTop: theme.spacing(1),
        },
      },
      seeAll: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          fontSize: '0.8em',
          marginLeft: theme.spacing(1),
        },
      },
      seeAllBtn: {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        maxWidth: 'max-content',
        maxHeight: 'min-content',
        cursor: 'pointer',
      },
      link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '& > svg': {
          fontSize: '.8em',
          marginLeft: theme.spacing(1),
        },
      },
    })
  )();

  return (
    <div className={classes.root}>
      <div className={classes.badges}>
        <div className={classes.title}>
          <Typography variant="h6" className={classes.h6}>
            {t('Settings.Appearance.Badges.otis_badges')}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('Settings.Appearance.Badges.otis_badges')}
          </Typography>
        </div>
        <div className={classes.badgesGrid}>
          {!showAllBadges
            ? latestBadges.map((badge: any) => <OtisBadge key={badge.achievement_id} badge={badge} />)
            : Object.values(badges).map((badge: any) => <OtisBadge key={badge.achievement_id} badge={badge} />)}
        </div>
        <div className={classes.foot}>
          <div className={classes.seeAll}>
            <button className={classes.seeAllBtn} onClick={() => setShowAllBadges((v) => !v)}>
              <Typography variant="body2" color="textPrimary">
                {t(`Home.${!showAllBadges ? 'see_all' : 'see_latest'}`)}
              </Typography>
            </button>
            <RightArrowIcon />
          </div>
        </div>
      </div>
      <div className={classes.badges}>
        <div className={classes.title}>
          <Typography variant="h6" className={classes.h6}>
            {t('Settings.Appearance.Badges.open_badges')}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('Settings.Appearance.Badges.open_badges')}
          </Typography>
        </div>
        <div className={classes.badgesGrid}>
          {!showAllOpenBadges
            ? openBadges.map((badge) => <OpenBadge key={badge.id} badge={badge} />)
            : allOpenBadges.map((badge) => <OpenBadge key={badge.id} badge={badge} />)}
          {}
          <div className={classes.badgeUploaderUpSm}>
            <OpenBadgeUploader />
          </div>
        </div>
        <div className={classes.foot}>
          <div className={classes.seeAll}>
            <button className={classes.seeAllBtn} onClick={() => setShowAllOpenBadges((v) => !v)}>
              <Typography variant="body2" color="textPrimary">
                {t(`Home.${!showAllOpenBadges ? 'see_all' : 'see_latest'}`)}
              </Typography>
            </button>
            <RightArrowIcon />
          </div>
          <div className={classes.badgeUploaderDownXs}>
            <OpenBadgeUploader fromSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badges;
