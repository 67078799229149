import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const Unit = () => {
  const { name } = useParams<{ name: string }>();
  const [showMoreFirst, setShowMoreFirst] = useState(false);
  const [showMoreSecond, setShowMoreSecond] = useState(false);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      heading: {
        padding: `${theme.spacing(15)}px ${theme.spacing(20)}px ${theme.spacing(20)}px`,
        backgroundColor: theme.palette.type === 'light' ? '#F1F4FF' : theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(15)}px ${theme.spacing(10)}px ${theme.spacing(20)}px`,
        },
        [theme.breakpoints.down('xs')]: {
          padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
        },
        '& > h4': {
          marginTop: theme.spacing(1),
          textTransform: 'capitalize',
        },
      },
      timeline: {
        marginTop: theme.spacing(7.5),
        height: '3px',
        width: '100%',
        backgroundColor: '#979797',
        position: 'relative',
        '& > div': {
          width: '2em',
          height: '2em',
          borderRadius: '50%',
          backgroundColor: '#D8D8D8',
          position: 'absolute',
        },
        '& div:first-child': {
          left: 0,
          transform: 'translateY(-50%)',
        },
        '& div:nth-child(2)': {
          left: '25%',
          transform: 'translateY(-50%) translateX(-25%)',
        },
        '& div:nth-child(3)': {
          left: '50%',
          transform: 'translateY(-50%) translateX(-50%)',
        },
        '& div:nth-child(4)': {
          left: '75%',
          transform: 'translateY(-50%) translateX(-75%)',
        },
        '& div:last-child': {
          left: '100%',
          transform: 'translateY(-50%) translateX(-100%)',
        },
      },
      info: {
        padding: `${theme.spacing(5)}px ${theme.spacing(20)}px`,
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(5)}px ${theme.spacing(10)}px` },
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(5) },
        '& > div': { margin: `${theme.spacing(5)}px 0` },
      },
      caption: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          fontSize: '1.1em',
          fill: theme.palette.text.hint,
        },
        '& > p': {
          marginLeft: theme.spacing(0.5),
          marginTop: theme.spacing(0.125),
          color: theme.palette.text.hint,
        },
      },
      content: {
        marginTop: theme.spacing(1),
        '& .MuiTypography-body1': { marginTop: theme.spacing(1) },
      },
      fail: {
        border: `4px solid red`,
        display: 'grid',
        placeItems: 'center',
        '& svg': { fill: 'red' },
      },
      pass: {
        border: `4px solid ${theme.palette.success.main}`,
        display: 'grid',
        placeItems: 'center',
        '& svg': { fill: theme.palette.success.main },
      },
      showMoreBtn: {
        padding: 0,
        margin: `${theme.spacing(1.5)}px 0 ${theme.spacing(1)}px`,
        '&:hover': { backgroundColor: 'unset' },
      },
    })
  )();

  return (
    <div>
      <div className={classes.heading}>
        <Typography variant="subtitle1">Introductory Certificate in ICT</Typography>
        <Typography variant="h4">{name}</Typography>
        <div className={classes.timeline}>
          <div />
          <div className={classes.fail}>
            <CloseIcon />
          </div>
          <div className={classes.pass}>
            <CheckCircleRoundedIcon />
          </div>
          <div />
          <div />
        </div>
      </div>
      <div className={classes.info}>
        <div>
          <Typography variant="h5">About this unit</Typography>
          <div className={classes.caption}>
            <AccessTimeTwoToneIcon />
            <Typography variant="body2">Total 150 Hours (105h with guidence + 45h non-guided)</Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body1">
              This unit enables Learners to understand the fundamental concepts and terminology of software development.
              It is mainly based on the design based learning approach that emphasises different practices that focus on
              the creative aspect of programming. The unit develops the learners with the skills required to design
              simple programming algorithms as well as in writing simple computer programs. Learners will be able to
              accomplish tasks associated with structured algorithms and procedural techniques such as variables, data
              types, selection, iteration, conditional and loop constructs using a visual drag and drop programming
              language.
            </Typography>
            <Typography variant="body1" hidden={!showMoreFirst}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, explicabo libero suscipit totam
              deleniti eos tempora voluptatum doloribus rerum praesentium perferendis vero ea harum consequuntur
              temporibus. Blanditiis iste cum hic eius explicabo molestiae at nam dolorem dolores nostrum. Pariatur
              earum quasi inventore eius hic ab, aperiam qui deleniti optio temporibus voluptate, ipsam error aliquid,
              nobis voluptatum odio recusandae quae tenetur iure vel provident distinctio explicabo? Veniam amet a quis
              labore eos rem error debitis, obcaecati recusandae nobis praesentium ipsum voluptatem vel perferendis,
              minima commodi quibusdam soluta? Nesciunt porro perferendis veniam assumenda tempore in officia
              consequuntur debitis ut quas, explicabo fugiat culpa nemo temporibus odit aliquid at ipsam asperiores
              suscipit sed excepturi voluptatem? Officia, repellat beatae. Accusantium odit architecto recusandae dicta!
            </Typography>
            <Button
              color="primary"
              disableRipple
              onClick={() => setShowMoreFirst((state) => !state)}
              className={classes.showMoreBtn}
            >
              {showMoreFirst ? 'Show less' : 'Show more'}
            </Button>
          </div>
        </div>
        <div>
          <Typography variant="h5">Syllabus - What will you learn</Typography>
          <div className={classes.content}>
            <Typography variant="body1">LO1. Design a solution for simple algorithms</Typography>
            <Typography variant="body1">LO2. Identify the basic procedural techniques used in programming</Typography>
            <Typography variant="body1">
              Use a graphical programming language for the implementation of an algorithm
            </Typography>
            <Typography variant="body1" hidden={!showMoreSecond}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate, explicabo libero suscipit totam
              deleniti eos tempora voluptatum doloribus rerum praesentium perferendis vero ea harum consequuntur
              temporibus. Blanditiis iste cum hic eius explicabo molestiae at nam dolorem dolores nostrum. Pariatur
              earum quasi inventore eius hic ab, aperiam qui deleniti optio temporibus voluptate, ipsam error aliquid,
              nobis voluptatum odio recusandae quae tenetur iure vel provident distinctio explicabo? Veniam amet a quis
              labore eos rem error debitis, obcaecati recusandae nobis praesentium ipsum voluptatem vel perferendis,
              minima commodi quibusdam soluta? Nesciunt porro perferendis veniam assumenda tempore in officia
              consequuntur debitis ut quas, explicabo fugiat culpa nemo temporibus odit aliquid at ipsam asperiores
              suscipit sed excepturi voluptatem? Officia, repellat beatae. Accusantium odit architecto recusandae dicta!
            </Typography>
            <Button
              color="primary"
              disableRipple
              onClick={() => setShowMoreSecond((state) => !state)}
              className={classes.showMoreBtn}
            >
              {showMoreSecond ? 'Show less' : 'Show more'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unit;
