import * as React from 'react';

function SvgCommunicatorIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 26.717" {...props}>
      <path
        data-name="Path 111"
        d="M24.701 0H5.268A5.4 5.4 0 00.009 5.533v12.779a3.3 3.3 0 003.053 3.522h2.172a.569.569 0 01.423.192.611.611 0 01.159.447L5.624 26.5a.2.2 0 00.342.159l3.623-3.748.582-.606.294-.3a.556.556 0 01.289-.159.337.337 0 01.12 0h13.833A5.409 5.409 0 0030 16.325V5.533A5.408 5.408 0 0024.718 0zM17.07 14.478H6.798a1.116 1.116 0 11-.146-2.228 1.06 1.06 0 01.146 0H17.07a1.116 1.116 0 01.146 2.228 1.06 1.06 0 01-.146 0zm5.423-5.509H6.798a1.116 1.116 0 11-.146-2.228 1.06 1.06 0 01.146 0h15.695a1.116 1.116 0 01.146 2.228 1.061 1.061 0 01-.146 0z"
        fill="#2091c8"
      />
    </svg>
  );
}

export default SvgCommunicatorIcon;
