import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Link from './Link';
import { ILink, MaterialsRouteProps } from '../types';
import { DispatchContext, StateContext } from '../context/context';
import { getData, loadMore } from '../context/actions';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const Links: React.FC<MaterialsRouteProps> = ({ sortOptions, activeFilters }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { t } = useTranslation();

  const data = state.data.links;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getData('links', { ...sortOptions, ...activeFilters }));
      setLoading(false);
    })();
  }, [dispatch, sortOptions, activeFilters]);

  const handleLoadMore = async () => {
    if (!data?.links?.next) return;
    setLoadingMore(true);
    await dispatch(loadMore('links', data.links.next));
    setLoadingMore(false);
  };

  const renderSkeleton = () => (
    <div>
      {[...new Array(5)].map((item, index) => (
        <div key={index} className={classes.link}>
          <Skeleton width="150px" height="30px" />
          <Skeleton width="225px" height="20px" />
          <Skeleton width="250px" height="25px" />
        </div>
      ))}
    </div>
  );

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      loadMore: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      link: { marginBottom: theme.spacing(2) },
    })
  )();

  return (
    <div>
      {loading ? (
        renderSkeleton()
      ) : data ? (
        data.results.length ? (
          <div>
            {data.results.map((link: ILink) => (
              <Link key={link.message_id} link={link} />
            ))}
            {data.count > data.results.length && (
              <div className={classes.loadMore}>
                <Button onClick={handleLoadMore} disabled={loadingMore}>
                  {t('Home.LearningMaterials.load_more')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Home.LearningMaterials.no_links')}
            </Typography>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Links;
