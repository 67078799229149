import React from 'react';
import { useDispatch } from 'react-redux';
import { setTOSAccepted } from '../redux/CurrentUser/actions';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type TOSModalProps = {
  refuse: () => void;
};

const TOSModal: React.FC<TOSModalProps> = ({ refuse }) => {
  const dispatch = useDispatch();

  const handleAcceptTOS = () => {
    dispatch(setTOSAccepted());
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      tosTitle: { textAlign: 'center' },
      tosContent: {
        '& > h6': { fontWeight: 'bold' },
        '& > p': { marginBottom: '1rem' },
      },
      tosActions: {
        justifyContent: 'flex-start',
        paddingBottom: '1.5rem',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      ulDefault: {
        listStyle: 'initial',
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
        '& > li': { marginBottom: '.25rem' },
      },
    })
  )();

  return (
    <MuiDialog open maxWidth="xs">
      <MuiDialogTitle className={classes.tosTitle} disableTypography>
        <Typography variant="h6">Terms of Service</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={refuse}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.tosContent}>
        <Typography variant="h6" gutterBottom>
          1. General Information
        </Typography>
        <p>
          This website is part of the OTIS and MyTrailMap systems provided to enable MCAST to provide personalised support
          and mentoring to students of the Foundation College and facilitate and support the target group in a didactic
          and methodical way
        </p>
        <p>
          The systems are provided by the Malta College of Arts, Science and Technology and are operated and maintained
          by the OTIS Learning Technology Alliance and its corporate members (SGM, brainshuttle).
        </p>
        <Typography variant="subtitle1" gutterBottom>
          Purposes for collecting data
        </Typography>
        <p>
          MCAST collects and processes information to carry out its functions. All data collected and processed in
          accordance with GDPR, The Education Act (Cap 327, Part VIII), and any other subsidiary legislation.
        </p>
        <Typography variant="subtitle1" gutterBottom>
          Recipients and processing of data
        </Typography>
        <p>
          Personal information is accessed by MCAST staff who are responsible to carry out specific tasks as part of
          MCAST's functions, which is to 'provide universally accessible vocational and professional education and
          training with an international dimension, responsive to the needs of the individual and the economy.
        </p>
        <p>
          Within the services offered by the OTIS and MyTrailMap systems, this information is shared with the
          above-mentioned third-parties to provide the intended functions of the services, or collected within the
          system (MyTrailMap). This includes:
        </p>
        <ul className={classes.ulDefault}>
          <li>Names,</li>
          <li>profile image/avatar</li>
          <li>enrolments (incl. courses, units),</li>
          <li>calendar events, data regarding assessments and assignments (pass/fail, submission, times),</li>
          <li>marks/grades/points,</li>
          <li>badges,</li>
          <li>education details and student records (OTIS, MyTrailMap),</li>
          <li>employment and financial details (MyTrailMap),</li>
          <li>attendance records and details of disciplinary proceedings (OTIS, MyTrailMap).</li>
        </ul>
        <p>
          The College and the commissioned third-parties commit themselves to handle personal and sensitive data in
          compliance with the General Data Protection Regulations (GDPR) that regulates the processing of personal
          information, whether held manually or electronically and also in compliance with the relevant requirements of
          the Education Act and subsidiary legislation.
        </p>
        <Typography variant="subtitle1" gutterBottom>
          Student and Staff rights
        </Typography>
        <p>
          The rights and details of what type of information MCAST processes in the case of students and staff are
          described in the 'MCAST Privacy Policy Staff' and 'MCAST Privacy Policy Students' as provided in the data
          protection documents
        </p>
        <Typography variant="subtitle1" gutterBottom>
          Retention policy
        </Typography>
        <p>
          MCAST will retain the information only as long as it is necessary, or as required by law. For more detailed
          information about retention periods for the various types of personal and sensitive data, refer to 'MCAST
          Retention Policy'.
        </p>
        <Typography variant="subtitle1" gutterBottom>
          The Data Protection Officer
        </Typography>
        <p>
          The Data Protection Officer may be contacted at:
          <br />
          Mr Mario Pace
          <br />
          Malta College of Arts, Science and Technology
          <br />
          Data Protection Office
          <br />
          Administration Building
          <br />
          Corradino Hill
          <br />
          Paola. PLA9032
          <br />
          Malta
          <br />
          Telephone: 23987141
          <br />
          Email: dpo@mcast.edu.mt
        </p>
        <Typography variant="h6" gutterBottom>
          2. Consent
        </Typography>
        <p>
          By clicking accept, you give consent to MCAST and its authorised entities to use your information to fulfil
          their tasks to:
        </p>
        <ul className={classes.ulDefault}>
          <li>
            provide universally accessible vocational and professional education and training with an international
            dimension
          </li>
          <li>provide personalised support and mentoring through the OTIS and MyTrailMap system?</li>
        </ul>
        <Typography variant="h6" gutterBottom>
          Declarations
        </Typography>
        <p>
          I declare that I am aware of my rights and conditions thereof, regarding the processing of my personal data as
          laid out in MCAST's Students' Privacy Policy{' '}
          <a href="https://www.mcast.edu.mt/data-protection-documents/">(Data Protection Documents - MCAST)</a>
        </p>
        <p>I am aware and accept the general conditions regarding the above mentioned clauses.</p>
      </MuiDialogContent>
      <MuiDialogActions className={classes.tosActions}>
        <Button onClick={handleAcceptTOS} color="primary" variant="contained">
          Accept
        </Button>
        <Button onClick={refuse} color="primary">
          Decline
        </Button>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default TOSModal;
