import { useRef, useReducer, useCallback } from 'react';
import { State, Key, ActionType, Action, ActionCreator } from '../types';

export const initialState: State = {
  data: {
    files: {
      count: 0,
      results: [],
    },
    media: {
      count: 0,
      results: [],
    },
    links: {
      count: 0,
      results: [],
    },
    smartboards: {
      count: 0,
      results: [],
    },
  },
  fileData: {},
  users: {},
  chats: {},
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DATA: {
      const { key, response } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: response,
        },
      };
    }
    case ActionType.APPEND_DATA: {
      const { key, response } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: {
            ...response,
            results: [...state.data[key as Key].results, ...response.results],
          },
        },
      };
    }
    case ActionType.SET_FILE_DATA: {
      return {
        ...state,
        fileData: Object.assign({}, state.fileData, ...action.payload),
      };
    }
    case ActionType.SET_USERS: {
      return {
        ...state,
        users: Object.assign({}, state.users, ...action.payload),
      };
    }
    case ActionType.SET_CHATS: {
      return {
        ...state,
        chats: Object.assign({}, state.chats, ...action.payload),
      };
    }
    default:
      return state;
  }
};

export const useReducerWithThunk = (
  reducer: (state: State, action: Action) => State,
  initialState: State
): [State, React.Dispatch<Action>] => {
  const lastState = useRef(initialState);
  const [state, dispatch] = useReducer(
    (state: State, action: Action) => (lastState.current = reducer(state, action)),
    initialState
  );
  const getState = useCallback(() => lastState.current, []);

  const customDispatch: (action: ActionCreator) => void = useCallback(
    (action) => {
      if (typeof action === 'function') {
        return action(customDispatch, getState);
      } else {
        return dispatch(action);
      }
    },
    [getState]
  );

  return [state, customDispatch];
};
