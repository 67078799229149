import * as React from 'react';

function SvgCalendarEventLocationGreen(props) {
  return (
    <svg
      id="calendar_event_location_green_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 25 25"
      width="1em"
      height="1em"
      stroke="#65a4ac"
      {...props}
    >
      <defs>
        <style>{'.calendar_event_location_green_svg__cls-1{fill:none;stroke-miterlimit:10}'}</style>
      </defs>
      <g id="calendar_event_location_green_svg__Group_341" data-name="Group 341">
        <path
          id="calendar_event_location_green_svg__Path_268-2"
          data-name="Path 268-2"
          className="calendar_event_location_green_svg__cls-1"
          d="M12.12 4a6.14 6.14 0 016.14 6.14c0 5.46-4.25 10.31-5.67 11.79a.66.66 0 01-.91 0C10.2 20.41 5.79 15.36 6 9.73A6.07 6.07 0 0112.12 4z"
        />
        <circle
          id="calendar_event_location_green_svg__Ellipse_133-2"
          data-name="Ellipse 133-2"
          className="calendar_event_location_green_svg__cls-1"
          cx={12.12}
          cy={9.61}
          r={3.51}
        />
      </g>
    </svg>
  );
}

export default SvgCalendarEventLocationGreen;
