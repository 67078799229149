import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { getCurrentUser, getOTISBadges, getOpenBadges, getHigherRole } from '../../../redux/CurrentUser/selectors';
import { IBadge, IOpenBadge } from '../../../redux/CurrentUser/types';
import { getSettings } from '../../../redux/App/selectors';
import { User } from '../../../redux/Search/types';
import { getDataFromApi } from '../../../api/dataFromApi';
import { getDataFromClusterName } from '../../../utils/clusterUtils';
import { getName, isRecordEmployeeRole } from '../../../utils/userUtils';
import { Subject } from '../../Home/Dashboard/types';
import ProfileImage from '../../ProfileImage/ProfileImage';
import OtisBadge from '../../OtisBadge/OtisBadge';
import OpenBadge from '../../OpenBadge/OpenBadge';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

const Profile: React.FC<{ isCurrentUser: boolean }> = ({ isCurrentUser }) => {
  const { id } = useParams<{ id: string }>();
  const badges = useSelector(getOTISBadges);
  const openBadges = useSelector(getOpenBadges);
  const higherRole = useSelector(getHigherRole);
  const user = useSelector(getCurrentUser);
  const [userData, setUserData] = useState<{
    user: User;
    badges: IBadge[];
    openBadges: IOpenBadge[];
    otisBadgesCount: number;
    openBadgesCount: number;
  } | null>(null);
  const [subjects, setSubjects] = useState<{
    [key: string]: Subject[];
  }>({});
  const settings = useSelector(getSettings);
  const displayBadges = settings.common.badges_visible;

  const [isLoading, setIsLoading] = useState(false);

  const otisBadgesCount = Object.values(badges).filter((badge) => badge.is_achieved).length;
  const openBadgesCount = openBadges.length;

  const [showBadges, setShowBadges] = useState(true);

  function makeLatestBadges(badges: IBadge[]) {
    const categories = new Set(['login', 'message', 'social', 'communication', 'profile']);
    const sortFn = (a: any, b: any) => new Date(b.awarded_at).getTime() - new Date(a.awarded_at).getTime();
    const filterFn = (x: any) => x.is_achieved && categories.delete(x.category);
    return badges.sort(sortFn).filter(filterFn);
  }

  useEffect(() => {
    if (id === user.user_id || isCurrentUser) {
      setCurrentUserData();
    } else {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await getDataFromApi(`auth/user/${id}/`);
      await getSubjects(id);
      let achievmentData, badgesData;
      try {
        achievmentData = await getDataFromApi(`achievements/list/other/${id}/`, 'v1');
        badgesData = await getDataFromApi(`badges/list/other/${id}/`, 'v1');
      } catch (e) {
        if ((e as AxiosError)?.response?.status === 403) {
          setShowBadges(false);
        }
      }

      setUserData({
        badges: makeLatestBadges(achievmentData || []),
        user: data,
        openBadges: badgesData || [],
        otisBadgesCount: (achievmentData || []).filter((badge: { is_achieved: boolean }) => badge.is_achieved).length,
        openBadgesCount: (badgesData || []).length,
      });
    } catch {
      console.warn('no data');
    }
    setIsLoading(false);
  };

  const setCurrentUserData = async () => {
    setIsLoading(true);
    await getSubjects(user.user_id);
    setShowBadges(displayBadges === 'true');
    setUserData({
      user,
      badges: makeLatestBadges(Object.values(badges)),
      openBadges,
      otisBadgesCount,
      openBadgesCount,
    });
    setIsLoading(false);
  };

  const getSubjects = async (id: string) => {
    const subjects = await getDataFromApi(`auth/user/${id}/subjects/`);
    setSubjects(subjects);
  };

  const goToMoodle = (moodleId: string | null) => {
    if (moodleId) window.open(`${process.env.REACT_APP_MOODLE_URL}?id=${moodleId}`, '_blank');
  };

  const canSeeProsysLink = (role: string) => {
    return isRecordEmployeeRole(role) && userData?.user.role === 'Student';
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minWidth: 500,
        width: '50%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: { width: '70%' },
        [theme.breakpoints.down('xs')]: { width: '90%', minWidth: 'unset' },
      },
      profileContent: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        padding: theme.spacing(4),
      },
      userInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      userName: { marginTop: theme.spacing(2) },
      userBio: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      bioTitle: { fontWeight: 'bold' },
      bioText: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        lineHeight: 1.5,
      },
      divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
      },
      badges: { width: '100%' },
      badgesList: {
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
        padding: '.5rem',
      },
      units: {
        width: '100%',
        marginTop: '1rem',
      },
      subject: {
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.action.hover },
      },
      mt1: { marginTop: '1rem' },
      bold: { fontWeight: 'bold' },
      prosysBtn: {
        color: '#34B53A',
        borderColor: '#34B53A',
        textTransform: 'unset',
        margin: '1rem',
      },
    })
  )();

  return (
    <div className={classes.root}>
      {userData && !isLoading && (
        <>
          <Paper component="main" className={classes.profileContent}>
            <div className={classes.userInfo}>
              <ProfileImage large user={userData.user} />
              <Typography variant="h6" className={classes.userName}>
                {getName(userData.user)}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {userData.user.role}
              </Typography>
              {canSeeProsysLink(higherRole) && (
                <Button
                  variant="outlined"
                  className={classes.prosysBtn}
                  target="_blank"
                  href={`${process.env.REACT_APP_PROSYS_URL}/creator/students/${id}`}
                >
                  MyTrailMap
                </Button>
              )}
              {userData.user.bio_info && (
                <div className={classes.userBio}>
                  <Typography variant="subtitle1" gutterBottom className={classes.bioTitle}>
                    Bio
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.bioText}>
                    {userData.user.bio_info}
                  </Typography>
                </div>
              )}
              <Divider className={classes.divider} />
              {showBadges && (
                <div className={classes.badges}>
                  <Typography variant="h6">OpenBadges</Typography>
                  <div className={classes.badgesList}>
                    {userData.openBadges.map((badge) => (
                      <OpenBadge badge={badge} key={badge.id} />
                    ))}
                  </div>
                  <Typography variant="h6" className={classes.mt1}>
                    OTIS Badges
                  </Typography>
                  <div className={classes.badgesList}>
                    {userData.badges.map((badge) => (
                      <OtisBadge badge={badge} key={badge.achievement_id} />
                    ))}
                  </div>
                </div>
              )}
              <div className={classes.units}>
                <Typography variant="h6">Units</Typography>
                {Object.keys(subjects).map((key) => {
                  return (
                    <div key={key}>
                      <Typography variant="subtitle1" className={classes.bold}>
                        {key}
                      </Typography>
                      <List component="ul" dense>
                        {subjects[key].map(({ id, title, moodle_id }) => {
                          const [code, name] = getDataFromClusterName(title);
                          return (
                            <ListItem key={id} onClick={() => goToMoodle(moodle_id)} className={classes.subject}>
                              <ListItemText primary={name} secondary={code} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  );
                })}
              </div>
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default Profile;
