import React, { useContext } from 'react';
import DashboardContext from '../context';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const GroupSelect: React.FC<{ fromPerformance?: boolean }> = ({ fromPerformance }) => {
  const { groups, group, performanceGroup, performanceGroups, handleGroupChange } = useContext(DashboardContext);

  const currentGroup = fromPerformance ? performanceGroup : group;
  const currentGroups = fromPerformance ? performanceGroups : groups;

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        minWidth: 150,
        padding: `0 ${theme.spacing(2)}px`,
        background: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: { minWidth: 120 },
        '& .MuiSelect-root': {
          paddingLeft: theme.spacing(0.5),
          marginTop: theme.spacing(0.2),
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.9rem',
          letterSpacing: '.5px',
        },
        '& svg': { color: 'white' },
      },
      menuItem: { fontSize: '.9rem' },
    })
  )();

  if (!currentGroup || !currentGroups || !currentGroups.find((group) => group.title === currentGroup.title))
    return null;

  return (
    <FormControl className={classes.formControl}>
      <Select
        disableUnderline
        labelId="groups-label"
        id="groups"
        value={currentGroup?.title}
        onChange={(e) => handleGroupChange(e, Boolean(fromPerformance))}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        IconComponent={ExpandMoreIcon}
      >
        {currentGroups.map(({ title }) => (
          <MenuItem key={title} value={title}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelect;
