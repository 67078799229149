import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      footer: {
        height: theme.spacing(6),
        width: '100%',
        padding: `0 ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
          alignItems: 'flex-end',
          '& .MuiTypography-caption': { fontSize: '0.625em' },
        },
        '& .MuiTypography-caption': { flex: 1 },
        '& .MuiTypography-caption:last-child': { textAlign: 'right' },
      },
      logo: {
        flex: 1,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: { display: 'none' },
      },
    })
  )();

  return (
    <Paper className={classes.footer} square>
      <Typography variant="caption" color="textSecondary">
        © MCAST Foundation College {new Date().getFullYear()}
      </Typography>
      <a href="https://www.mcast.edu.mt/" target="_blank" rel="noopener noreferrer" className={classes.logo}>
        <img width="100px" height="auto" src={require('../../assets/MCAST.png').default} alt="MCAST logo" />
      </a>
      <Typography variant="caption" color="textSecondary">
        {t('Footer.terms')}
      </Typography>
    </Paper>
  );
};

export default Footer;
