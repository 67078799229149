import { getDataFromApi, postDataToApi } from '../../../../api/dataFromApi';
import {
  IMessage,
  IMedia,
  IUser,
  IFileData,
  IData,
  State,
  Key,
  ActionType,
  Action,
  IChat,
  GetDataPayload,
} from '../types';

const checkFileData = async (
  response: IData<IMessage>,
  fileData: { [key: number]: IFileData },
  dispatch: (action: Action) => void
) => {
  const resultsWithoutData = response.results.filter((r: IMessage) => !fileData[r.message_id]);
  if (resultsWithoutData.length) {
    const data = await Promise.all(
      resultsWithoutData.map(async (r: IMessage) => await getDataFromApi(`files/media-file/${r.body}/`, 'v1'))
    );
    const payload = data.map((e, i) => ({ [resultsWithoutData[i].message_id]: e }));
    dispatch({
      type: ActionType.SET_FILE_DATA,
      payload,
    });
    return Object.assign({}, fileData, ...payload);
  }
  return fileData;
};

const checkUsers = async (
  response: IData<IMessage>,
  users: { [key: string]: IUser },
  dispatch: (action: Action) => void
) => {
  const resultsWithoutUsers = response.results.filter((r: IMessage) => !users[r.user_id]);
  if (resultsWithoutUsers.length) {
    const usersData = await Promise.all(
      Array.from(new Set(resultsWithoutUsers.map((r: IMessage) => r.user_id))).map(
        async (id) => await getDataFromApi(`auth/user/${id}/`)
      )
    );
    const payload = usersData.map((u) => ({ [u.user_id]: u }));
    dispatch({
      type: ActionType.SET_USERS,
      payload,
    });
    return Object.assign({}, users, ...payload);
  }
  return users;
};

const checkChats = async (
  response: IData<IMessage>,
  chats: { [key: string]: IChat },
  dispatch: (action: Action) => void
) => {
  const resultsWithoutChats = response.results.filter((r: IMessage) => !chats[r.chat_id]);
  if (resultsWithoutChats.length) {
    const chatsData = await Promise.all(
      Array.from(new Set(resultsWithoutChats.map((r: IMessage) => r.chat_id))).map(
        async (id) => await getDataFromApi(`chat/${id}/`)
      )
    );
    const chatsWithoutImages = chatsData.filter((c) => !c.data.private).map((c) => c.data.image);
    const imagesData = await postDataToApi('files/group-image/', { uuid_list: chatsWithoutImages }, 'v1');
    const imagesObject = Object.assign(
      {},
      ...imagesData.url_list.map((c: { chat_uuid: string; signed_url: string | null }) => ({
        [c.chat_uuid]: c.signed_url,
      }))
    );
    const payload = chatsData.map((c) => ({ [c.data.chat_id]: { ...c.data, signed_url: imagesObject[c.data.image] } }));
    dispatch({
      type: ActionType.SET_CHATS,
      payload,
    });
    return Object.assign({}, chats, ...payload);
  }
  return chats;
};

export const getData = (key: Key, payload: GetDataPayload) => {
  return async (dispatch: (action: Action) => void, getState: () => State) => {
    const response = await postDataToApi(`chat/ccp/${key}/`, payload, 'v1');
    if ((key === 'media' || key === 'files' || key === 'links') && response.count) {
      let { fileData, users, chats } = getState();

      if (key !== 'links') {
        fileData = await checkFileData(response, fileData, dispatch);
        response.results = response.results.map((r: IMessage) => ({
          ...r,
          file_data: fileData[r.message_id],
        }));
      }
      users = await checkUsers(response, users, dispatch);
      chats = await checkChats(response, chats, dispatch);
      response.results = response.results.map((r: IMedia) => ({
        ...r,
        user: users[r.user_id],
        chat: chats[r.chat_id],
      }));
    }
    dispatch({
      type: ActionType.SET_DATA,
      payload: {
        key,
        response,
      },
    });
  };
};

export const loadMore = (key: Key, url: string) => {
  return async (dispatch: (action: Action) => void, getState: () => State) => {
    const response = await getDataFromApi(url, 'v1');
    if (key === 'media' || key === 'files' || key === 'links') {
      let { fileData, users, chats } = getState();

      if (key !== 'links') {
        fileData = await checkFileData(response, fileData, dispatch);
        response.results = response.results.map((r: IMedia) => ({
          ...r,
          file_data: fileData[r.message_id],
        }));
      }
      users = await checkUsers(response, users, dispatch);
      chats = await checkChats(response, chats, dispatch);
      response.results = response.results.map((r: IMedia) => ({
        ...r,
        user: users[r.user_id],
        chat: chats[r.chat_id],
      }));
    }
    dispatch({
      type: ActionType.APPEND_DATA,
      payload: {
        key,
        response,
      },
    });
  };
};
