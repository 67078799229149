import { Selector } from 'react-redux';
import { ISettings } from './types';
import { IStore } from '../store';

export const getSettings: Selector<IStore, ISettings> = (state) => state.App.settings;
export const getSidebarOpen: Selector<IStore, boolean> = (state) => state.App.sidebarOpen;
export const getSearchOpen: Selector<IStore, boolean> = (state) => state.App.searchOpen;
export const getActiveTenant: Selector<IStore, string> = (state) => state.App.active_tenant;
export const getChangingTenant: Selector<IStore, boolean> = (state) => state.App.changing_tenant;
export const getIsTenantHeaderSet: Selector<IStore, boolean> = (state) => state.App.tenantHeaderSet;
