import { Theme } from '@material-ui/core/styles';
import { UsageCardData } from './types';
import CommunicatorIcon from '../../../assets/CommunicatorIcon'
import IconLA from '../../../assets/IconLearningApp'
import IconSmartboard from '../../../assets/IconSmartboad'

export const getColors = (theme: Theme) => ({
  light: theme.palette.primary.light,
  main: theme.palette.primary.main,
  dark: theme.palette.primary.dark,
  text: theme.palette.text.primary,
});

export const getChartUsageData = (
  usageData: UsageCardData,
  colors: { [key: string]: string },
  t: (key: string) => string
) => {
  const { title, data } = usageData;
  const chartType = title === 'Communicator' ? 'doughnut' : 'pie';
  const labels = Object.keys(data?.usage || {}).filter((key) => !key.includes('percent') && key !== 'total_users');
  const percents = labels.map(
    (label) => (data?.usage as { [key: string]: number })[`${label.toLocaleLowerCase()}_percent`]
  );
  percents.push(percents.reduce((acc, cur) => acc - cur, 100));
  labels.push('not_used');
  const backgroundColor = labels.length === 3 ? [colors.dark, colors.main, colors.light] : [colors.dark, colors.light];
  return {
    type: chartType,
    labels: labels.map((label, i) => `${t(`Dashboard.${label}`)} - ${percents[i].toFixed(0)}%`),
    percents,
    backgroundColor,
  };
};

export const getChartAnalyticsData = (
  usageData: UsageCardData,
  colors: { [key: string]: string },
  t: (key: string) => string
) => {
  const { title, data } = usageData;
  const chartType = title === 'Communicator' ? 'doughnut' : 'pie';
  const labels = Object.keys(data?.engagement || {}).filter((key) => !key.includes('percent') && key !== 'total_users');
  const percents = labels.map(
    (label) => (data?.engagement as { [key: string]: number })[`${label.toLocaleLowerCase()}_percent`]
  );
  percents.push(percents.reduce((acc, cur) => acc - cur, 100));
  labels.push('not_engaged');
  const backgroundColor = labels.length === 3 ? [colors.dark, colors.main, colors.light] : [colors.dark, colors.light];
  return {
    type: chartType,
    labels: labels.map((label, i) => `${t(`Dashboard.${label}`)} - ${percents[i].toFixed(0)}%`),
    percents,
    backgroundColor,
  };
};

export const getAppsChartData = (
  data: any,
  colors: { [key: string]: string },
  t: (key: string) => string
) => {
  const appsData: { [key: string]: any } = {}
  for (let app of data.usage) {
    const labels = Object.keys(app).filter(key => !key.includes('percent') && !['total_users', 'name'].includes(key))
    const percents = labels.map(label => app[`${label.toLocaleLowerCase()}_percent`])
    percents.push(percents.reduce((acc, cur) => acc - cur, 100));
    labels.push('not_engaged');
    const backgroundColor = labels.length === 3 ? [colors.dark, colors.main, colors.light] : [colors.dark, colors.light];
    appsData[app.name] = {
      labels: labels.map((label, i) => `${t(`Dashboard.${label}`)} - ${percents[i].toFixed(0)}%`),
      percents,
      backgroundColor
    }
  }
  return appsData;
};


export const getKey = (param: string) => {
  if (param === 'communicator' || param === 'smartboard') return param;
  else return 'learningApp';
};

export const getTitle = (param: string) => {
  return param
    .split('-')
    .map((s) => (s.length ? s.charAt(0).toUpperCase() + s.slice(1) : s))
    .join(' ');
};

export const getIcon = (title: string) => {
  if (title === 'Communicator') return CommunicatorIcon;
  else if (title === 'Learning App') return IconLA;
  return IconSmartboard;
}
