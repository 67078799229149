export const fullName = (user: any) => user.fullName || `${user.first_name} ${user.last_name}`;
export const getName = (user: any) => {
  if (user.role === 'Teacher') return `Lect. ${fullName(user)}`;
  return fullName(user);
};

export const employeeRole = ['Administrator', 'Counsellor', 'Student mentor', 'Institute Director / Deputy Director', 'Teacher'];
export const recordEmployeeRole = ['Administrator', 'Counsellor', 'Student mentor', 'Institute Director / Deputy Director'];

export const isEmployeeRole = (role: string) => employeeRole.includes(role)
export const isRecordEmployeeRole = (role: string) => recordEmployeeRole.includes(role)