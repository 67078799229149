import React, { ChangeEvent, useState, useRef, useEffect, useCallback, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setSearchOpen } from '../../../redux/App/actions';
import { getSearchOpen } from '../../../redux/App/selectors';
import {
  setInitSearch,
  setInitValueApplied,
  startSearch,
  incrementIndex,
  decrementIndex,
  resetSearch,
} from '../../../redux/Search/actions';
import {
  getFocusedResultIndex,
  getInitSearch,
  getInitValueApplied,
  searchResults,
} from '../../../redux/Search/selectors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const SearchBar: React.FC<{ keepMounted?: boolean }> = ({ keepMounted }) => {
  const initValue = useSelector(getInitSearch);
  const initValueApplied = useSelector(getInitValueApplied);
  const results = useSelector(searchResults);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const searchOpen = useSelector(getSearchOpen);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceTimeout = useRef<any>();

  const initSearch = useCallback(() => {
    const shouldStartSearch = value.length >= 3;
    if (!shouldStartSearch) {
      toggleSearchResults(shouldStartSearch);
      dispatch(resetSearch());
      clearTimeout(debounceTimeout.current);
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        toggleSearchResults(shouldStartSearch);
        dispatch(startSearch(value));
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value === '' && !initValueApplied && initValue) {
      setValue(initValue || '');
      setTimeout(() => {
        dispatch(setInitSearch(''));
        dispatch(setInitValueApplied(true));
      }, 1000);
    }
    initSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initValue]);

  useEffect(() => {
    if (debounceTimeout.current) {
      return () => clearTimeout(debounceTimeout.current);
    }
  }, []);

  const handleClear = () => {
    setValue('');
    dispatch(resetSearch());
    if (inputRef.current !== null) inputRef.current.focus();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleFocus = () => {
    setActive(true);
    if (value) initSearch();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (searchOpen) dispatch(decrementIndex());
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (searchOpen) dispatch(incrementIndex());
      else if (value.length >= 3) initSearch();
    } else if (e.key === 'Escape') {
      dispatch(setSearchOpen(false));
    } else if (e.key === 'Enter' && focusedResultIndex >= 0) {
      dispatch(resetSearch());
      dispatch(setSearchOpen(false));
      if (inputRef.current !== null) inputRef.current.blur();
      history.push(`/profile/${results[focusedResultIndex].user_id}`);
    }
  };

  const toggleSearchResults = (open: boolean) => {
    if (!keepMounted) dispatch(setSearchOpen(open));
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'grid',
        placeItems: 'center',
        border: '1px solid #818181',
        borderRadius: 18,
        padding: `0 ${theme.spacing(1)}px`,
        marginRight: theme.spacing(1),
        maxHeight: 36,
        '& .MuiSvgIcon-root': {
          color: '#757575',
          fontSize: '1.2em',
        },
        [theme.breakpoints.down('md')]: { display: keepMounted ? 'grid' : 'none' },
      },
    })
  )();

  return (
    <TextField
      placeholder={!active ? t('Header.search') : ''}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="large" />
          </InputAdornment>
        ),
        endAdornment: active ? (
          <InputAdornment position="end">
            <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClear} />
          </InputAdornment>
        ) : null,
        disableUnderline: true,
      }}
      inputProps={{ 'data-info': 'search-bar' }}
      inputRef={inputRef}
      onFocus={handleFocus}
      onBlur={() => !value.length && setActive(false)}
      value={value}
      onChange={handleChange}
      className={classes.root}
      onKeyDown={keepMounted ? undefined : handleKeyDown}
    />
  );
};

export default SearchBar;
