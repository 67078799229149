import * as React from 'react';

function Svg20Px(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 50 50" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M25 1a8.403 8.403 0 017.778 5.222 8.402 8.402 0 0111 11.001 8.403 8.403 0 010 15.555 8.402 8.402 0 01-11.001 11 8.403 8.403 0 01-15.555 0 8.402 8.402 0 01-11-11.001 8.403 8.403 0 010-15.555 8.402 8.402 0 0111.001-11A8.4 8.4 0 0125 1z"
          stroke="#FFF"
          strokeWidth={2}
          fill="#445D96"
        />
        <path fill="#FFF" fillRule="nonzero" d="M30.032 16L34 18.654 23.138 35 14 28.888l2.646-3.982 5.168 3.455z" />
      </g>
    </svg>
  );
}

export default Svg20Px;

