import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import StartScreen from './StartScreen/StartScreen';
import Unit from './Unit/Unit';
import Timetable from '../Sidebar/Screens/Timetable';
import LearningMaterials from './LearningMaterials/LearningMaterials';
import Dashboard from './Dashboard/Dashboard';
import Badges from '../Sidebar/Screens/Badges';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const Home: React.FC = () => {
  useEffect(() => {
    document.title = `Learning App`;
  }, []);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        gap: `${theme.spacing(3.5)}px`,
        padding: theme.spacing(4),
        paddingBottom: 0,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        height: '100%',
        minHeight: 'calc(100vh - 64px - 48px)',
        [theme.breakpoints.down('md')]: { flexDirection: 'column' },
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(0) },
        [theme.breakpoints.down('xs')]: { minHeight: 'calc(100vh - 56px - 48px)' },
        '& main': {
          flex: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '12px 12px 0 0',
        },
        '& aside': {
          flex: 1,
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          borderRadius: '12px 12px 0 0',
          [theme.breakpoints.down('xs')]: { display: 'none' },
        },
      },
      nav: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      },
      seeAll: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        cursor: 'not-allowed',
        '& > svg': {
          fontSize: '.8em',
          marginLeft: theme.spacing(1),
        },
      },
      badges: {
        padding: `${theme.spacing(2)}px 0`,
        '& .MuiTypography-h6': {
          fontWeight: 'bold',
          marginBottom: theme.spacing(2),
        },
        '& > a': {
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: theme.palette.text.primary,
          margin: `${theme.spacing(2)}px 0`,
          '& > svg': {
            fontSize: '.8em',
            marginLeft: theme.spacing(1),
          },
        },
      },
      badgesGrid: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(12.5)}px, 1fr))`,
        gap: `${theme.spacing(1)}px`,
      },
    })
  )();

  return (
    <div className={classes.root}>
      <main>
        <Switch>
          <Route path="/" exact component={StartScreen} />
          <Route path="/unit/:name" exact component={Unit} />
          <Route path="/materials" component={LearningMaterials} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </main>
      <aside>
        <Timetable />
        <Divider />
        <Badges />
      </aside>
    </div>
  );
};

export default Home;
