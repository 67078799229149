import React from 'react';
import { IOpenBadge } from '../../redux/CurrentUser/types';
import Verified from '../../assets/badges/verified';
import Dialog from '../shared/Dialog/Dialog';
import BadgeContent from './BadgeContent/BadgeContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  badge: IOpenBadge;
}

const OpenBadge: React.FC<IProps> = ({ badge }) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      trigger: {
        width: theme.spacing(12.5),
        height: theme.spacing(12.5),
        justifySelf: 'center',
        cursor: 'pointer',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
          width: theme.spacing(10),
          height: theme.spacing(10),
        },
        '& > svg': {
          position: 'absolute',
          bottom: 0,
          right: 0,
          fontSize: '1.3em',
        },
      },
    })
  )();

  return (
    <Dialog
      trigger={
        <div className={classes.trigger}>
          <img width="100%" height="100%" src={badge.image_url} alt="Open Badge" />
          {badge.is_valid && <Verified />}
        </div>
      }
    >
      {(close: () => void) => <BadgeContent badge={badge} handleClose={close} />}
    </Dialog>
  );
};

export default OpenBadge;
