import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie, Doughnut } from 'react-chartjs-2';
import { getColors, getIcon, getChartUsageData } from '../utils';
import { UsageCardData } from '../types';
import generateLegendPlugin from '../legendPlugin';
import NoData from '../shared/NoData';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import CardContainer from './CardContainer';

const AppUsageCard: React.FC<UsageCardData> = ({ title, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = getColors(theme);
  const legendPlugin = useMemo(
    () =>
      generateLegendPlugin({
        ulStyles: { marginTop: '8px' },
        textStyles: { color: theme.palette.text.secondary },
      }),
    [theme.palette.text.secondary]
  );

  const Chart = title === 'Communicator' ? Doughnut : Pie;
  const Icon = getIcon(title);
  const { type, labels, percents, backgroundColor } = getChartUsageData({ title, data }, colors, t);
  const handle = title.toLowerCase().split(' ').join('-');

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      appInfo: {
        padding: theme.spacing(3),
        '& .MuiTypography-subtitle2': {
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
      },
      chartContainer: {
        position: 'relative',
        height: 225,
        width: 225,
        margin: '0 auto',
        marginTop: theme.spacing(5),
      },
      link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'uppercase',
        letterSpacing: '0.1px',
        fontSize: '.9em',
        fontWeight: 'bold',
      },
      noData: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 200,
      },
    })
  )();

  if (!data) return null;

  const containerTitle = `${title} usage`.toUpperCase();
  const legendId = `${title}-usage-legend`;
  const detailsUrl = `/dashboard/${handle}`;

  return (
    <CardContainer title={containerTitle} Icon={Icon} legendId={legendId} detailsUrl={detailsUrl}>
      {data.users_data.length ? (
        <>
          <div className={classes.chartContainer}>
            <Chart
              type={type}
              data={{
                labels,
                datasets: [
                  {
                    data: percents,
                    backgroundColor,
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  htmlLegend: {
                    containerID: legendId,
                  },
                },
              }}
              plugins={[legendPlugin]}
            />
          </div>
        </>
      ) : (
        <NoData className={classes.noData} />
      )}
    </CardContainer>
  );
};

export default AppUsageCard;
