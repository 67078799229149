import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import 'moment/locale/mt';
import { updateSettings } from '../../../redux/App/actions';
import { getSettings } from '../../../redux/App/selectors';
import { ISettings } from '../../../redux/App/types';
import AccentColors from './AccentColors/AccentColors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';

const Appearance: React.FC = () => {
  const settings: ISettings = useSelector(getSettings);
  const [sliderValue, setSliderValue] = useState<number>(settings.learning_app.font_size);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSliderChange = (e: React.ChangeEvent<{}>, v: number | number[]) => setSliderValue(v as number);

  const getSliderValues = (e: React.ChangeEvent<{}>, v: number | number[]) => {
    dispatch(
      updateSettings('learning_app', {
        font_size: v as number,
      })
    );
  };

  const getInputValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateSettings('learning_app', {
        font_family: e.target.value,
      })
    );
  };

  const toggleDarkMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color_theme = settings.common.color_theme === 'Classic' ? 'Dark' : 'Classic';
    dispatch(updateSettings('common', { color_theme }));
  };

  const toggleAccentColor = (e: any) => {
    const color_accent = e.target.value;
    dispatch(updateSettings('common', { color_accent }));
  };

  const toggleLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    moment.locale(e.target.value);
    console.log(moment.locale());
    dispatch(
      updateSettings('common', {
        language: e.target.value,
      })
    );
  };

  const changeBackground = (color: string) => {
    const payload = {
      background_color: color,
    } as any;
    if (color !== 'default') {
      payload.color_theme = 'Classic';
    }
    dispatch(updateSettings('common', payload));
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        gap: `${theme.spacing(3.5)}px`,
        color: theme.palette.text.primary,
        '& main': { flex: 2.25 },
        '& aside': {
          flex: 1,
          [theme.breakpoints.down('md')]: { display: 'none' },
        },
      },
      section: { padding: theme.spacing(1) },
      fontSize: {
        maxWidth: theme.spacing(62.5),
        padding: theme.spacing(1),
      },
      slider: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& span:first-child': { fontSize: 14 * 0.75 },
        '& span:last-child': { fontSize: 14 * 1.25 },
        '& .MuiSlider-root': { margin: `0 ${theme.spacing(1.5)}px` },
      },
      fontFamily: {
        padding: theme.spacing(1),
        paddingBottom: 0,
      },
      radioGroup: {
        padding: theme.spacing(1),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
      },
      darkMode: {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6': { marginLeft: theme.spacing(1) },
        '&:hover': { backgroundColor: theme.palette.action.hover },
      },
      accentColor: { padding: theme.spacing(1) },
      backgroundColor: { padding: theme.spacing(1) },
      colors: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& div': {
          width: '1.5em',
          height: '1.5em',
          borderRadius: '50%',
          margin: `0 ${theme.spacing(1.5)}px`,
          cursor: 'pointer',
        },
      },
      backgroundColors: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '1rem',
      },
      colorButton: {
        marginTop: '1rem',
        border: '1px solid #E5E5E5',
        borderRadius: 12,
        outline: 'none',
        backgroundColor: 'none',
        aspectRatio: '1/1',
      },
      language: { padding: theme.spacing(1) },
      calendarPrefs: {
        padding: theme.spacing(1),
        '& .MuiTypography-subtitle2': { marginTop: theme.spacing(2) },
        '& > fieldset': {
          marginTop: theme.spacing(1),
          color: theme.palette.text.secondary,
        },
      },
      roboto: {
        '& .MuiFormControlLabel-label': { fontFamily: 'Roboto' },
      },
      openSans: {
        '& .MuiFormControlLabel-label': { fontFamily: 'Open Sans' },
      },
      nunito: {
        '& .MuiFormControlLabel-label': { fontFamily: 'Nunito' },
      },
      merriweather: {
        '& .MuiFormControlLabel-label': { fontFamily: 'Merriweather' },
      },
    })
  )();

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.section}>
          <div className={classes.fontSize}>
            <Typography variant="subtitle1">{t('Settings.Appearance.font_size_label')}</Typography>
            <div className={classes.slider}>
              <span>A</span>
              <Slider
                min={0.75}
                max={1.25}
                step={0.25}
                value={sliderValue}
                onChange={handleSliderChange}
                onChangeCommitted={getSliderValues}
              />
              <span>A</span>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.section}>
          <div className={classes.fontFamily}>
            <Typography variant="subtitle1">{t('Settings.Appearance.font_family_label')}</Typography>
            <div className={classes.radioGroup}>
              <FormControl component="fieldset">
                <RadioGroup value={settings.learning_app.font_family} onChange={getInputValues} name="font-family">
                  <FormControlLabel
                    value="Roboto"
                    control={<Radio color="primary" />}
                    label="Roboto (default)"
                    className={classes.roboto}
                  />
                  <FormControlLabel
                    value="Open Sans"
                    control={<Radio color="primary" />}
                    label="Open Sans"
                    className={classes.openSans}
                  />
                  <FormControlLabel
                    value="Nunito"
                    control={<Radio color="primary" />}
                    label="Nunito"
                    className={classes.nunito}
                  />
                  <FormControlLabel
                    value="Merriweather"
                    control={<Radio color="primary" />}
                    label="Merriweather"
                    className={classes.merriweather}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.section}>
          <div className={classes.darkMode}>
            <Switch
              color="primary"
              disabled={settings.common.background_color !== 'default'}
              checked={settings.common.color_theme === 'Dark'}
              onChange={toggleDarkMode}
            />
            <Typography variant="subtitle1">{t('Settings.Appearance.DarkMode')}</Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.section}>
          <div className={classes.accentColor}>
            <Typography variant="subtitle1">{t('Settings.Appearance.accent_color')}</Typography>
            <AccentColors color={settings.common.color_accent} toggleAccentColor={toggleAccentColor} />
          </div>
        </div>
        <Divider />
        <div className={classes.section}>
          <div className={classes.backgroundColor}>
            <Typography variant="subtitle1">{t('Settings.Appearance.background_color')}</Typography>

            <div className={classes.backgroundColors}>
              <button
                className={classes.colorButton}
                style={{
                  backgroundColor: '#FFF',
                  outline: settings.common.background_color === 'default' ? '4px solid #2294CB' : 'none',
                }}
                onClick={() => changeBackground('default')}
              />
              <button
                className={classes.colorButton}
                style={{
                  backgroundColor: '#CEDEA2',
                  outline: settings.common.background_color === 'green' ? '4px solid #2294CB' : 'none',
                }}
                onClick={() => changeBackground('green')}
              />
              <button
                className={classes.colorButton}
                style={{
                  backgroundColor: '#EEDBCE',
                  outline: settings.common.background_color === 'pink' ? '4px solid #2294CB' : 'none',
                }}
                onClick={() => changeBackground('pink')}
              />
              <button
                className={classes.colorButton}
                style={{
                  backgroundColor: '#DBD7E4',
                  outline: settings.common.background_color === 'blue' ? '4px solid #2294CB' : 'none',
                }}
                onClick={() => changeBackground('blue')}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.section}>
          <div className={classes.language}>
            <Typography variant="subtitle1">{t('Settings.Appearance.preferred_language_label')}</Typography>
            <div className={classes.radioGroup}>
              <FormControl component="fieldset">
                <RadioGroup value={settings.common.language} onChange={toggleLanguage} name="language">
                  <FormControlLabel
                    value="en"
                    label={t('Settings.Appearance.Languages.english')}
                    control={<Radio color="primary" />}
                    checked={settings.common.language === 'en'}
                  />
                  <FormControlLabel
                    value="mt"
                    label={t('Settings.Appearance.Languages.maltese')}
                    control={<Radio color="primary" />}
                    checked={settings.common.language === 'mt'}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </main>
      <aside />
    </div>
  );
};

export default Appearance;
