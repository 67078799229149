import React from 'react';
import { useTranslation } from 'react-i18next';
import Settings from './Settings';
import Badges from './Badges';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Timetable from './Timetable';

interface IProps {
  showScreen: null | string;
  setShowScreen: (screen: null | string) => void;
}

const ScreenContainer: React.FC<IProps> = ({ showScreen, setShowScreen }) => {
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        transform: showScreen ? 'translateX(0)' : 'translateX(100%)',
        transition: '.2s ease',
        '& .MuiAppBar-root': { color: '#FFF' },
      },
      title: { fontWeight: 'bold' },
    })
  )();

  return (
    <div className={classes.container}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => setShowScreen(null)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.title}>
            {t(`Menus.${showScreen}`)}
          </Typography>
        </Toolbar>
      </AppBar>
      {showScreen === 'settings' && <Settings />}
      {showScreen === 'badges' && <Badges />}
      {showScreen === 'timetable' && <Timetable />}
    </div>
  );
};

export default ScreenContainer;
