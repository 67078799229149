import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../redux/CurrentUser/actions';
import { getCurrentUser } from '../../redux/CurrentUser/selectors';
import { setSidebarOpen } from '../../redux/App/actions';
import { getSidebarOpen } from '../../redux/App/selectors';
import { getName } from '../../utils/userUtils';
import ProfileImage from '../ProfileImage/ProfileImage';
import ScreenContainer from './Screens/ScreenContainer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import SchoolIcon from '@material-ui/icons/School';
import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import InsertEmoticonTwoToneIcon from '@material-ui/icons/InsertEmoticonTwoTone';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';

const Sidebar: React.FC = () => {
  const [showScreen, setShowScreen] = useState<null | string>(null);
  const user = useSelector(getCurrentUser);
  const open = useSelector(getSidebarOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) setShowScreen(null);
  }, [open]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        width: '100%',
        height: open ? 'calc(100vh - 64px)' : '0px',
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(3)}px`,
        transform: open ? 'translateY(0)' : 'translateY(-100%)',
        transition: '.2s ease',
        [theme.breakpoints.down('xs')]: {
          height: open ? 'calc(100vh - 56px)' : '0px',
        },
        '& > div': { backgroundColor: theme.palette.background.paper },
      },
      profile: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        '& .MuiTypography-subtitle1': { marginLeft: theme.spacing(2) },
      },
      settingsIcon: { marginLeft: 'auto' },
      link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    })
  )();

  return (
    <div className={classes.root}>
      <div className={classes.profile}>
        <ProfileImage />
        <Typography variant="subtitle1">{getName(user)}</Typography>
        <IconButton className={classes.settingsIcon} onClick={() => setShowScreen('settings')}>
          <SettingsTwoToneIcon />
        </IconButton>
      </div>
      <div>
        <List disablePadding>
          <Link to="/calendar" className={classes.link}>
            <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
              <ListItemIcon>
                <CalendarTodayTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={t('Menus.calendar')} />
            </ListItem>
          </Link>
          <Link to="/materials" className={classes.link}>
            <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary={t('Menus.learn')} />
            </ListItem>
          </Link>
          <Link to="/dashboard" className={classes.link}>
            <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
              <ListItemIcon>
                <DashboardTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={t('Menus.dashboard')} />
            </ListItem>
          </Link>
          <ListItem button onClick={() => setShowScreen('timetable')}>
            <ListItemIcon>
              <ScheduleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('Menus.timetable')} />
          </ListItem>
          <ListItem button onClick={() => setShowScreen('badges')}>
            <ListItemIcon>
              <InsertEmoticonTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t('Menus.badges')} />
          </ListItem>
        </List>
      </div>
      <div>
        <List disablePadding>
          <ListItem button onClick={() => dispatch(logoutUser())}>
            <ListItemIcon>
              <ExitToAppTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t('Menus.logout')} />
          </ListItem>
        </List>
      </div>

      <ScreenContainer showScreen={showScreen} setShowScreen={setShowScreen} />
    </div>
  );
};

export default Sidebar;
