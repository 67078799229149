import React, { useState, useRef, ReactElement, useEffect } from 'react';
import styles from './Dropdown.module.css';

const Dropdown = ({
  notForNav,
  opener,
  className,
  children,
}: {
  notForNav?: boolean;
  opener: ReactElement;
  className?: string;
  children: ReactElement;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenu = useRef<HTMLElement | null>(null);
  const isMounted = useRef(false);

  const toggleMenu = (e: MouseEvent) => {
    isOpen ? closeMenu(e) : openMenu(e);
  };

  const openMenu = (e: MouseEvent) => {
    e.preventDefault();

    document.addEventListener('click', closeMenu);
    isMounted.current && setIsOpen(true);
  };

  const closeMenu = (e: MouseEvent) => {
    if (notForNav && dropdownMenu.current?.contains(e.target as Node)) return;

    document.removeEventListener('click', closeMenu);
    isMounted.current && setIsOpen(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {React.cloneElement(opener, {
        onClick: toggleMenu,
        id: 'Opener',
      })}
      <span ref={el => (dropdownMenu.current = el)} className={styles.DropdownHolder}>
        {isOpen ? (
          <div className={`${styles.Dropdown} ${notForNav ? '' : styles.forNav} ${className || ''}`}>{children}</div>
        ) : null}
      </span>
    </>
  );
};

export default Dropdown;
