import { getDataFromApi } from '../../api/dataFromApi';
import {
  SET_EVENTS,
  IEvent,
  REMOVE_EVENT,
  UPDATE_EVENT,
  CREATE_EVENT,
  UPDATE_LAST_CHECKED,
  ICalendarLastChecked,
  ICalendar,
  SET_CALENDARS,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { IStore } from '../store';
import { Action } from 'redux';

export const setCalendars = (calendars: ICalendar[]) => ({
  type: SET_CALENDARS,
  payload: calendars,
});

export const setEvents = (events: IEvent[], from: string, to: string) => ({
  type: SET_EVENTS,
  payload: {
    events,
    from,
    to,
  },
});

export const createEvent = (event: IEvent) => ({
  type: CREATE_EVENT,
  payload: event,
});

export const updateEvent = (event: IEvent) => ({
  type: UPDATE_EVENT,
  payload: event,
});

export const removeEvent = (event: IEvent) => ({
  type: REMOVE_EVENT,
  payload: event.id,
});

export const updateLastChecked = ({
  from,
  to,
}: {
  from: ICalendarLastChecked['from'];
  to: ICalendarLastChecked['to'];
}) => ({
  type: UPDATE_LAST_CHECKED,
  payload: {
    from,
    to,
    timestamp: new Date().toISOString().slice(0, -5),
  },
});

export const getAllCalendars: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      const calendars: ICalendar[] = await getDataFromApi('learning/calendars/', 'v1', true);
      if (!calendars) return;
      dispatch(setCalendars(calendars.sort((a, b) => Number(a.id) - Number(b.id))));
    } catch {}
  };
};

export const getAllEvents: (from: string, to: string) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (
  from,
  to
) => {
  return async (dispatch) => {
    try {
      const events: IEvent[] = await getDataFromApi(
        `learning/calendars/events/?start_time=${from}&end_time=${to}`,
        'v1',
        true
      );
      dispatch(updateLastChecked({ from, to }));
      if (!events) return;
      dispatch(setEvents(events, from, to));
    } catch {}
  };
};

export const refreshEvents: (from: string, to: string) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (
  from,
  to
) => {
  return async (dispatch, getState) => {
    const lastChecked = getState().Calendar.last_checked;
    if (from === lastChecked.from && to === lastChecked.to) {
      try {
        const events: IEvent[] = await getDataFromApi(
          `learning/calendars/events/refresh/?start_time=${from}&end_time=${to}&last_checked_time=${lastChecked.timestamp}`,
          'v1',
          true
        );
        if (!events) return;
        events.forEach((event) => {
          switch (event.state?.toUpperCase()) {
            case 'UPDATED':
              dispatch(updateEvent(event));
              break;
            case 'REMOVED':
              dispatch(removeEvent(event));
              break;
            case 'CREATED':
            default:
              dispatch(createEvent(event));
              break;
          }
        });
        dispatch(updateLastChecked({ from, to }));
      } catch {}
    }
  };
};
