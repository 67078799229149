import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../redux/CurrentUser/selectors';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { User } from '../../redux/Search/types';

interface IProps {
  small?: boolean;
  large?: boolean;
  user?: User;
}

const ProfileImage: React.FC<IProps> = ({ large, user, small }) => {
  const currentUser = useSelector(getCurrentUser);
  const useUser = user || currentUser;
  const userNameLetters = `${useUser.first_name[0]} ${useUser.last_name[0]}`;

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      large: {
        width: '5em',
        height: '5em',
      },
      small: {
        width: '1em',
        height: '1em',
      },
    })
  )();

  const className = large ? classes.large : small ? classes.small : '';

  return useUser.image_url ? (
    <Avatar className={className} src={useUser.image_url} alt="Profile picture" />
  ) : (
    <Avatar className={className}>{userNameLetters}</Avatar>
  );
};

export default ProfileImage;
