import * as React from 'react';

function SvgPpt(props) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 100 120" {...props}>
      <defs>
        <filter id="ppt_svg__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 0.596078 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g filter="url(#ppt_svg__a)">
          <path
            d="M59.098 0L100 40.115V110c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0h49.098z"
            fill="#2196F3"
          />
        </g>
        <path
          d="M37.554 57.901c-10.243 0-18.54 8.303-18.54 18.54 0 10.239 8.302 18.541 18.54 18.541s18.545-8.302 18.545-18.545H37.554V57.901z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.4}
        />
        <path d="M40.442 55.009v18.545h18.54c.005-10.243-8.298-18.545-18.54-18.545z" fill="#FFF" fillRule="nonzero" />
        <path d="M100 40l-35.314-.86A10 10 0 0159 30.114V0h1l40 39v1z" fill="#FFE0B2" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default SvgPpt;
