import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setSidebarOpen } from '../../../redux/App/actions';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import PaletteTwoToneIcon from '@material-ui/icons/PaletteTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import FingerprintTwoToneIcon from '@material-ui/icons/FingerprintTwoTone';

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '& .MuiListItemIcon-root': { minWidth: theme.spacing(5) },
      },
    })
  )();

  return (
    <List disablePadding>
      <Link to="/account-settings" className={classes.link}>
        <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
          <ListItemIcon>
            <PersonOutlineTwoToneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.Titles.view_public_profile')} />
        </ListItem>
      </Link>
      <Link to="/account-settings/appearance" className={classes.link}>
        <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
          <ListItemIcon>
            <PaletteTwoToneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.Titles.appearance')} />
        </ListItem>
      </Link>
      <Link to="/account-settings/privacy" className={classes.link}>
        <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
          <ListItemIcon>
            <VisibilityTwoToneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.Titles.privacy')} />
        </ListItem>
      </Link>
      <Link to="/account-settings/code-generator" className={classes.link}>
        <ListItem button onClick={() => dispatch(setSidebarOpen(false))}>
          <ListItemIcon>
            <FingerprintTwoToneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.Titles.code_generator')} />
        </ListItem>
      </Link>
    </List>
  );
};

export default Settings;
