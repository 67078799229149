import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabContent from './TabContent/TabContent';
import AppUsageDetails from './AppDetails/AppUsageDetails';
import AppAnalyticsDetails from './AppDetails/AppAnalyticsDetails';
import AppDetailsContainer from './AppDetails/AppDetailsContainer';
import AcademicPerformanceDetails from './AppDetails/AcademicPerformanceDetails';
import SingleUserData from './Student/SingleUserData';
import StudentDashboard from './Student/StudentDashboard';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';

interface Props {
  tab: number;
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const DashboardContent: React.FC<Props> = ({ tab, onTabChange }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      tabs: {
        padding: '0 10%',
        [theme.breakpoints.down('sm')]: { padding: '0 5%' },
        [theme.breakpoints.down('xs')]: { padding: `0 ${theme.spacing(2)}px` },
      },
      tab: { fontWeight: 'bold' },
    })
  )();

  return (
    <>
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onTabChange} className={classes.tabs}>
        <Tab label={t('Dashboard.usage')} className={classes.tab} />
        <Tab label={t('Dashboard.academic_performance')} className={classes.tab} />
      </Tabs>
      <Divider />

      <Switch>
        <Route path={match.url} exact>
          <TabContent tab={tab} />
        </Route>
        <Route path={`${match.url}/:app`} exact component={AppUsageDetails} />
        <Route path={`${match.url}/analytics/:app`} exact component={AppAnalyticsDetails} />
        <Route path={`${match.url}/academic-performance/:name/:id`} component={AcademicPerformanceDetails} />
        <Route path={`${match.url}/student-profile/:id`} exact component={StudentDashboard} />
        <Route path={`${match.url}/students/:id/:mname/:mtype`} exact component={SingleUserData} />
        <Route path={`${match.url}/:type/:app`} exact component={AppDetailsContainer} />
        <Redirect to={match.url} />
      </Switch>
    </>
  );
};

export default DashboardContent;
