import * as React from 'react';

function SvgCalendarColor(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        data-name="Path 477"
        d="M12 3a9 9 0 000 18 1.5 1.5 0 001.11-2.51A1.494 1.494 0 0114.23 16H16a5 5 0 005-5c0-4.42-4.03-8-9-8zm-5.5 9A1.5 1.5 0 118 10.5 1.5 1.5 0 016.5 12zm3-4A1.5 1.5 0 1111 6.5 1.5 1.5 0 019.5 8zm5 0A1.5 1.5 0 1116 6.5 1.5 1.5 0 0114.5 8zm3 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z"
        fill="#6a6a6a"
      />
      <path data-name="Path 478" d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

export default SvgCalendarColor;
