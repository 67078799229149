import * as React from 'react';

function SvgOutlineArrowRight24Px(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 12" {...props}>
      <defs>
        <path id="outline-arrow-right-24px_svg__a" d="M1603 885.59l4.327-4.59-4.327-4.59 1.332-1.41 5.668 6-5.668 6z" />
      </defs>
      <use
        fill="#425B95"
        xlinkHref="#outline-arrow-right-24px_svg__a"
        transform="translate(-1603 -875)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgOutlineArrowRight24Px;
