import React, { useEffect, useState } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../api/dataFromApi';
import Files from './Files/Files';
import Media from './Media/Media';
import Links from './Links/Links';
import Smartboard from './Smartboard/Smartboard';
import Menu from '../../shared/Menu/Menu';
import { ActiveFiltersType, Filters, SortOptionsType } from './types';
import { CCPProvider } from './context/context';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';
import InsertDriveFileTwoToneIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import LinkTwoToneIcon from '@material-ui/icons/LinkTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';

const LearningMaterials: React.FC = () => {
  const [sortOptions, setSortOptions] = useState<SortOptionsType>({
    sort_order: 'DESC',
    sort_by: 'date',
  });
  const [filters, setFilters] = useState<Filters>({
    chats: [],
    lecturer_ids: [],
    lecturers: [],
  });
  const [activeFilters, setActiveFilters] = useState<ActiveFiltersType>({
    chat_ids: [],
    lecturer_ids: [],
  });
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await getDataFromApi(`chat/materials_filters/`, 'v0');
      setFilters(response);
    })();
  }, []);

  const handleSort = (key: SortOptionsType['sort_by'], closeMenu: () => void) => {
    const { sort_order, sort_by } = sortOptions;
    const updatedSortOptions = {
      sort_order: key === sort_by ? (sort_order === 'DESC' ? 'ASC' : 'DESC') : sort_order,
      sort_by: key,
    };
    setSortOptions(updatedSortOptions);
    closeMenu();
  };

  const handleActiveFiltersChange = (key: 'chat_ids' | 'lecturer_ids', filterId: number) => {
    if (activeFilters[key].includes(filterId)) {
      setActiveFilters({
        ...activeFilters,
        [key]: activeFilters[key].filter((id) => {
          return id !== filterId;
        }),
      });
    } else {
      setActiveFilters({
        ...activeFilters,
        [key]: [...activeFilters[key], filterId],
      });
    }
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      materials: {
        height: '100%',
        padding: theme.spacing(4),
        display: 'flex',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
      },
      nav: {
        height: '100%',
        minWidth: 240,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { maxWidth: 320 },
        '& .MuiTypography-h6': {
          marginLeft: theme.spacing(2),
          marginBottom: theme.spacing(0.5),
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
        '& .MuiList-root': {
          '& a': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
          },
          '& .MuiListItemIcon-root': { minWidth: 36 },
        },
      },
      content: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(2),
        flex: 3,
        [theme.breakpoints.down('sm')]: {
          padding: 0,
          marginTop: theme.spacing(5),
        },
      },
      actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5),
      },
      filters: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
      },
      menuItem: { padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px` },
      icon: { fontSize: '1.2rem' },
      info: {
        marginTop: 'auto',
        '& .MuiAlert-icon': {
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': { fill: theme.palette.warning.main },
        },
      },
      selected: {
        '& .MuiListItem-root': { backgroundColor: theme.palette.action.selected },
      },
    })
  )();

  return (
    <CCPProvider>
      <div className={classes.materials}>
        <div className={classes.nav}>
          <Typography variant="h6">{t('Home.LearningMaterials.title')}</Typography>
          <List component="nav">
            <NavLink to="/materials" exact activeClassName={classes.selected}>
              <ListItem button>
                <ListItemIcon>
                  <ImageTwoToneIcon color="primary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Home.LearningMaterials.files')} />
              </ListItem>
            </NavLink>
            <NavLink to="/materials/media" exact activeClassName={classes.selected}>
              <ListItem button>
                <ListItemIcon>
                  <InsertDriveFileTwoToneIcon color="primary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Home.LearningMaterials.media')} />
              </ListItem>
            </NavLink>
            <NavLink to="/materials/links" exact activeClassName={classes.selected}>
              <ListItem button>
                <ListItemIcon>
                  <LinkTwoToneIcon color="primary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Home.LearningMaterials.links')} />
              </ListItem>
            </NavLink>
            <NavLink to="/materials/smartboard" activeClassName={classes.selected}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardTwoToneIcon color="primary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Home.LearningMaterials.smartboard')} />
              </ListItem>
            </NavLink>
          </List>
          <Alert severity="info" className={classes.info} color="warning">
            {t('Home.LearningMaterials.alert')}
          </Alert>
        </div>
        <div className={classes.content}>
          <div className={classes.actions}>
            <Menu
              opener={
                <Button
                  size="small"
                  endIcon={sortOptions.sort_order === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                >
                  {t('Home.LearningMaterials.sortByDate')}
                </Button>
              }
            >
              {(close: () => void) => (
                <div>
                  <MenuItem onClick={() => handleSort('date', close)}>
                    {t('Home.LearningMaterials.sortByDate')}
                  </MenuItem>
                </div>
              )}
            </Menu>
            <Menu
              opener={
                <Button size="small" endIcon={<FilterListIcon />}>
                  {t('Home.LearningMaterials.filter')}
                </Button>
              }
            >
              {() => (
                <div className={classes.filters}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t('Home.LearningMaterials.chats')}</FormLabel>
                    <FormGroup>
                      {filters.chats.map(({ id, title }) => (
                        <MenuItem className={classes.menuItem} key={id}>
                          <FormControlLabel
                            control={<Checkbox onChange={() => handleActiveFiltersChange('chat_ids', id)} />}
                            label={title}
                          />
                        </MenuItem>
                      ))}
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t('Home.LearningMaterials.lecturers')}</FormLabel>
                    <FormGroup>
                      {filters.lecturers.map(({ user_id, first_name, last_name }) => (
                        <MenuItem className={classes.menuItem} key={user_id}>
                          <FormControlLabel
                            control={<Checkbox onChange={() => handleActiveFiltersChange('lecturer_ids', user_id)} />}
                            label={`${first_name} ${last_name}`}
                          />
                        </MenuItem>
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
              )}
            </Menu>
          </div>
          <Route path="/materials" exact>
            <Files sortOptions={sortOptions} activeFilters={activeFilters} />
          </Route>
          <Route path="/materials/media" exact>
            <Media sortOptions={sortOptions} activeFilters={activeFilters} />
          </Route>
          <Route path="/materials/links" exact>
            <Links sortOptions={sortOptions} activeFilters={activeFilters} />
          </Route>
          <Route path="/materials/smartboard" exact>
            <Smartboard sortOptions={sortOptions} activeFilters={activeFilters} />
          </Route>
        </div>
      </div>
    </CCPProvider>
  );
};

export default LearningMaterials;
