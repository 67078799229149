import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getDataFromApi, postDataToApi } from '../../api/dataFromApi';
import { IStore } from '../store';
import {
  User,
  SET_SEARCH_LOADING,
  SET_SEARCH_RESULTS,
  SET_SEARCH_TERM,
  SET_INIT_SEARCH,
  SET_INIT_VALUE_APPLIED,
  INCREMENT_INDEX,
  DECREMENT_INDEX,
  SET_INDEX,
  RESET_SEARCH,
  SET_NEXT_PAGE,
  APPEND_SEARCH_RESULTS,
  UPDATE_USER_IMAGES,
} from './types';

export const setLoading = (payload: boolean) => {
  return {
    type: SET_SEARCH_LOADING,
    payload,
  };
};

export const setSearchResults = (payload: User[]) => {
  return {
    type: SET_SEARCH_RESULTS,
    payload,
  };
};

export const setSearchTerm = (searchTerm: string) => {
  return {
    type: SET_SEARCH_TERM,
    payload: searchTerm,
  };
};

export const setInitSearch = (searchTerm: string) => {
  return {
    type: SET_INIT_SEARCH,
    payload: searchTerm,
  };
};

export const setInitValueApplied = (applied: boolean) => {
  return {
    type: SET_INIT_VALUE_APPLIED,
    payload: applied,
  };
};

export const incrementIndex = () => {
  return {
    type: INCREMENT_INDEX,
  };
};

export const decrementIndex = () => {
  return {
    type: DECREMENT_INDEX,
  };
};

export const setIndex = (index: number) => {
  return {
    type: SET_INDEX,
    payload: index,
  };
};

export const resetSearch = () => {
  return {
    type: RESET_SEARCH,
  };
};

export const setNextPage = (nextPage: string | null) => {
  return {
    type: SET_NEXT_PAGE,
    payload: nextPage,
  };
};

export const appendSearchResults = (results: User[]) => {
  return {
    type: APPEND_SEARCH_RESULTS,
    payload: results,
  };
};

export const updateUserImages = (userImages: { [id: string]: User }) => {
  return {
    type: UPDATE_USER_IMAGES,
    payload: userImages,
  };
};

const getCmisImages: (users: User[], update?: boolean) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (
  users,
  update
) => {
  return async (dispatch) => {
    try {
      const cmisImages = Object.fromEntries(
        await Promise.all(
          users
            .filter((r) => !r.image_url)
            .map(async (r) => {
              const cmisUser = await getDataFromApi(`users/${r.user_id}/`, 'classter');
              return [r.user_id, cmisUser.image];
            })
        )
      );
      if (update) {
        dispatch(updateUserImages(cmisImages));
      } else {
        dispatch(
          setSearchResults(
            users.map((r) => ({
              ...r,
              image_url: r.image_url || `data:image/png;base64,${cmisImages[r.user_id]}`,
            }))
          )
        );
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const startSearch: (query: string) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (query) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setSearchTerm(query));
    try {
      const data = await postDataToApi('auth/user/search/', { search_term: query });
      dispatch(getCmisImages(data.results));
      if (getState().Search.searchTerm === query) {
        dispatch(setIndex(-1));
        dispatch(setSearchResults(data.results));
      }
      if (data.links?.next) {
        dispatch(setNextPage(data.links.next));
      }
    } catch {
      console.error('Error occurred');
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const loadMore: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch, getState) => {
    const link = getState().Search.nextPage;
    const searchTerm = getState().Search.searchTerm;
    if (link) {
      try {
        const data = await postDataToApi(link.split('/v0/')[1], { search_term: searchTerm });
        dispatch(getCmisImages(data.results, true));
        dispatch(appendSearchResults(data.results));
        if (data.links.next) {
          dispatch(setNextPage(data.links.next));
        } else {
          dispatch(setNextPage(null));
        }
      } catch {
        console.error('Error occurred');
      }
    }
  };
};
