import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { ARVRUserData, ModuleUsageData } from '../types';
import DashboardContext from '../context';
import generateLegendPlugin from '../legendPlugin';
import StudentsTable from './StudentsTable/StudentsTable';
import GroupSelect from '../shared/GroupSelect';
import NoData from '../shared/NoData';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const AppDetailsContainer: React.FC = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { usageData, fetchUsageData } = useContext(DashboardContext);
  const { t } = useTranslation();
  const params = useParams();
  const theme = useTheme();
  const legendPlugin = useMemo(
    () =>
      generateLegendPlugin({
        ulStyles: {
          marginTop: '12px',
          justifyContent: 'flex-start',
        },
        textStyles: { color: theme.palette.text.secondary },
      }),
    [theme.palette.text.secondary]
  );

  useEffect(() => {
    (async () => {
      setIsFetchingData(true);
      await fetchUsageData();
      setIsFetchingData(false);
    })();
  }, [fetchUsageData]);

  const { app, type } = params as { [key: string]: string };
  const title = `${app} ${type} APP`;
  const appData = (usageData as { [key: string]: any })?.[type.toLocaleLowerCase()].usage.find(
    (m: ModuleUsageData) => m.module_name === app
  );
  const usersData = (usageData as { [key: string]: any })?.[type.toLocaleLowerCase()].users_data;

  const charts: string[] = [];
  appData?.knowledge_total && charts.push('Knowledge');
  appData?.assessments_total && charts.push('Assessments');
  const backgroundColor = {
    Knowledge: ['#1177D3', '#C8E1FD'],
    Assessments: ['#42C7DB', '#D0FAF7'],
  };
  const labels = [t('Dashboard.done'), t('Dashboard.remaining')];
  const percents = charts.map((c) => {
    const percent = appData[`${c.toLocaleLowerCase()}_percent`];
    return [percent, 100 - percent];
  });

  const tableColumns = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'knowledge', label: 'Knowledge' },
    { id: 'assessments', label: 'Assessments' },
  ];
  const tableRows = usersData?.map((u: ARVRUserData) => ({
    id: u.id,
    image: u.profile_image,
    name: `${u.first_name} ${u.last_name}`,
    knowledge: `${u.usage[app].knowledge_finished} of ${u.usage[app].knowledge_total}`,
    assessments: `${u.usage[app].assessments_finished} of ${u.usage[app].assessments_total}`,
  }));
  const tableTitle = `${app} list`.toUpperCase();

  const renderCharts = () =>
    charts.map((chart, i) => {
      const bgColor = (backgroundColor as { [key: string]: any })[chart];
      const data = {
        labels,
        datasets: [
          {
            data: percents[i],
            backgroundColor: bgColor,
            hoverOffset: 4,
          },
        ],
      };
      const options = {
        plugins: {
          legend: { display: false },
          htmlLegend: { containerID: `${chart}-legend` },
        },
        cutout: '90%',
      };
      return (
        <div key={chart}>
          <Typography variant="subtitle1" className={classes.chartTitle}>
            {t(`Dashboard.${chart.toLowerCase()}`)}
          </Typography>
          <div className={classes.chart}>
            <span className={classes.percentage} style={{ color: bgColor[0] }}>
              {percents[i][0]}%
            </span>
            <Doughnut type="doughnut" data={data} options={options} plugins={[legendPlugin]} />
          </div>
          <div id={`${chart}-legend`} />
        </div>
      );
    });

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: `${theme.spacing(2)}px 10%`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          color: theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          '& svg': { fontSize: '1.2em' },
        },
        '& .MuiTypography-subtitle1': {
          fontWeight: 'bold',
          marginLeft: theme.spacing(2),
        },
      },
      content: {
        padding: `${theme.spacing(2)}px 10%`,
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      charts: {
        padding: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5rem',
      },
      chart: {
        position: 'relative',
        height: 175,
        width: 175,
        marginTop: theme.spacing(2.5),
      },
      chartTitle: { fontWeight: 'bold' },
      percentage: {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        fontSize: '2rem',
      },
      studentsTotal: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        letterSpacing: '.1px',
        [theme.breakpoints.down('xs')]: { width: 'unset' },
      },
      studentsTable: {
        '& thead': { backgroundColor: '#D0E9F0' },
      },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    })
  )();

  return (
    <>
      <div className={classes.head}>
        <div className={classes.title}>
          <Link to="/dashboard">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography variant="subtitle1">{title}</Typography>
        </div>
        <GroupSelect />
      </div>
      {isFetchingData ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : charts.length ? (
        <>
          <div className={classes.content}>
            <div className={classes.charts}>{renderCharts()}</div>
            <Typography className={classes.studentsTotal} variant="subtitle2" color="textSecondary">
              {appData.total_users} {t('Dashboard.students_total')}
            </Typography>
          </div>
          <StudentsTable
            rows={tableRows}
            columns={tableColumns}
            title={tableTitle}
            tableContainerProps={{ className: classes.studentsTable }}
            urlGenerator={(id: string) => `/dashboard/students/${id}/${app}/${type}`}
          />
        </>
      ) : (
        <NoData className={classes.loading} />
      )}
    </>
  );
};

export default AppDetailsContainer;
