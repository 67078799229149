import React from 'react';
import { useReducerWithThunk, reducer, initialState } from './reducer';
import { State } from '../types';

const StateContext = React.createContext<State>(initialState);
const DispatchContext = React.createContext<React.Dispatch<any>>(() => {});

const CCPProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export { StateContext, DispatchContext, CCPProvider };
