import React from 'react';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ARVRData } from '../types';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';

const LinearProgressPrimary = withStyles(() =>
  createStyles({
    colorPrimary: { backgroundColor: '#C8E1FD' },
    bar: { backgroundColor: '#1177D3' },
  })
)(LinearProgress);

const LinearProgressSecondary = withStyles(() =>
  createStyles({
    colorPrimary: { backgroundColor: '#D0FAF7' },
    bar: { backgroundColor: '#42C7DB' },
  })
)(LinearProgress);

interface Props {
  data?: ARVRData;
  studentDash?: boolean;
}

const ARAppsData: React.FC<Props> = ({ data, studentDash }) => {
  const createUrl = (moduleType: string, moduleName: string) => {
    if (studentDash) return `/dashboard/knowledge-items/${moduleType}/${moduleName}`;
    return `/dashboard/${moduleType}/${moduleName}`;
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      cardBody: { marginTop: theme.spacing(4) },
      appProgressBar: {
        marginBottom: theme.spacing(5),
        '& .MuiLinearProgress-root': {
          marginTop: theme.spacing(0.25),
          height: theme.spacing(1),
          borderRadius: '4px',
        },
      },
      link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        fontSize: '.9em',
        fontWeight: 'bold',
      },
      progressBar: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(1),
        '& .MuiTypography-body2': { textAlign: 'right' },
      },
      colorPrimary: { color: '#1177D3' },
      colorSecondary: { color: '#42C7DB' },
    })
  )();

  if (!data) return null;

  return (
    <div className={classes.cardBody}>
      {data.usage.map(
        ({ module_name, module_type, knowledge_total, knowledge_percent, assessments_total, assessments_percent }) => (
          <div className={classes.appProgressBar} key={module_name}>
            <Link to={createUrl(module_type, module_name)} className={classes.link}>
              {module_name.toUpperCase()} {module_type} APP
            </Link>
            {knowledge_total ? (
              <div className={classes.progressBar}>
                <Typography variant="body2" color="primary" className={classes.colorPrimary}>
                  {knowledge_percent}%
                </Typography>
                <LinearProgressPrimary variant="determinate" value={knowledge_percent} />
              </div>
            ) : null}
            {assessments_total ? (
              <div className={classes.progressBar}>
                <Typography variant="body2" className={classes.colorSecondary}>
                  {assessments_percent}%
                </Typography>
                <LinearProgressSecondary variant="determinate" value={assessments_percent} />
              </div>
            ) : null}
          </div>
        )
      )}
    </div>
  );
};

export default ARAppsData;
