import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeUserImage } from '../../../../redux/CurrentUser/actions';
import { ICurrentUserState } from '../../../../redux/CurrentUser/types';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '../../../shared/Dialog/Dialog';
import DialogTitle from '../../../shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ProfilePictureRemove = ({ image }: { image: ICurrentUserState['image_url'] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removePhoto = async (closeModal: () => void) => {
    await dispatch(removeUserImage());
    closeModal();
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: { textAlign: 'center' },
    })
  )();

  return (
    <Dialog
      trigger={
        <Button data-testid="removePhoto" variant="outlined" color="primary" disabled={!image}>
          {t('Settings.Edit_profile.remove')}
        </Button>
      }
    >
      {(close: () => void) => (
        <div className={classes.root}>
          <DialogTitle>{t('Settings.Edit_profile.remove')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Settings.Edit_profile.are_you_sure_to_remove_photo')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary" variant="outlined">
              {t('Settings.Edit_profile.no')}
            </Button>
            <Button onClick={() => removePhoto(close)} color="primary" variant="contained" autoFocus>
              {t('Settings.Edit_profile.yes')}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default ProfilePictureRemove;
