export interface IUser {
  first_name: string;
  last_name: string;
  user_id: string;
  role: string;
  fullName: string;
}

export interface IBadge {
  achievement_id: string;
  is_achieved: boolean;
  awarded_at: string;
  user_id: string;
  category: string;
}

export interface IOpenBadge {
  assertion_evidence: string;
  assertion_id: string;
  assertion_issued_on: string;
  criteria: string;
  description: string;
  id: number;
  image_url: string;
  is_valid: boolean;
  issuer_name: string;
  name: string;
  uploaded_at: string;
  user_id: string;
}

export interface ICurrentUserState {
  loggedIn: boolean;
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  fullName: string;
  badges: Array<IBadge>;
  open_badges: IOpenBadge[];
  bio_info: string;
  image_url: string;
  tnc_accepted: boolean;
  tnc_accepted_at: null | string;
}

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_BADGES = 'SET_BADGES';
export const ADD_OPEN_BADGE = 'ADD_OPEN_BADGE';
export const REMOVE_OPEN_BADGE = 'REMOVE_OPEN_BADGE';
export const SET_OPEN_BADGES = 'SET_OPEN_BADGES';
export const SET_BIO_INFO = 'SET_BIO_INFO';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_IMAGE_URL = 'SET_IMAGE_URL';
export const SET_TOS_ACCEPTED = 'SET_TOS_ACCEPTED';

interface ISetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  payload: IUser;
}

interface ISetBadgesAction {
  type: typeof SET_BADGES;
  payload: IBadge;
}

interface ISetOpenBadgesAction {
  type: typeof SET_OPEN_BADGES;
  payload: IOpenBadge[];
}

interface IAddOpenBadgeAction {
  type: typeof ADD_OPEN_BADGE;
  payload: IOpenBadge;
}

interface IRemoveOpenBadgeAction {
  type: typeof REMOVE_OPEN_BADGE;
  payload: number;
}

interface ISetBioInfo {
  type: typeof SET_BIO_INFO;
  payload: string;
}

interface ISetImageUrl {
  type: typeof SET_IMAGE_URL;
  payload: string;
}

interface IacceptTOS {
  type: typeof SET_TOS_ACCEPTED;
}

export type SetCurrentUserTypes =
  | ISetCurrentUserAction
  | ISetBadgesAction
  | ISetOpenBadgesAction
  | IAddOpenBadgeAction
  | IRemoveOpenBadgeAction
  | ISetBioInfo
  | ISetImageUrl
  | IacceptTOS;
