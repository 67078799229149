import * as React from 'react';

function SvgWord(props) {
  return (
    <svg width="2em" height="2em" viewBox="0 0 100 120" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M59.098 0L100 40.115V110c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0h49.098z"
          fill="#2196F3"
        />
        <path d="M39 85v5H19v-5h20zm20-10v5H19v-5h40zm0-10v5H19v-5h40zm0-10v5H19v-5h40z" fill="#FFF" />
        <path d="M100 40l-35.314-.86A10 10 0 0159 30.114V0h1l40 39v1z" fill="#BBDEFB" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default SvgWord;
