import React, { useState }  from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IOpenBadge } from '../../../redux/CurrentUser/types';
import Verified from '../../../assets/badges/verified';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '../../shared/Dialog/DialogTitle/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch } from 'react-redux';
import { deleteBadge } from '../../../redux/CurrentUser/actions';
import { Button, DialogActions, DialogContentText } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

interface IProps {
  badge: IOpenBadge;
  handleClose: () => void;
}

const BadgeContent: React.FC<IProps> = ({ badge, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: theme.palette.text.primary,
        '& .MuiDialogContent-root': {
          padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0.25)}px`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      imageWrapper: {
        maxWidth: theme.spacing(25),
        maxHeight: theme.spacing(25),
        '& img': {
          width: '100%',
          height: '100%',
        },
      },
      title: {
        marginTop: theme.spacing(1.5),
        '& h6': {
          '& svg': { verticalAlign: 'middle' },
        },
      },
      desc: { marginTop: theme.spacing(1) },
      criteria: {
        marginTop: theme.spacing(2),
        maxWidth: '80%',
        color: theme.palette.text.secondary,
      },
      footer: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& .MuiTypography-body2': { color: theme.palette.text.secondary },
      },
      text: { textAlign: 'center' },
      actions: {
        justifyContent: 'flex-start',
        marginLeft: theme.spacing(1.5),
      },
      delete_popup_title : {
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center' 
      }
    })
  )();

  const removeBadge = () => {
    dispatch(deleteBadge(badge.id));
  }

  const handleCloseDeletePopup = () => {
    setOpen(false);
  }

  const handleOpenDeletePopup = () => {
    setOpen(true);
  }

  return (
    <>
      <div className={classes.root}>
        <DialogTitle onClose={handleClose} />
        <DialogContent>
          <div className={classes.imageWrapper}>
            <img src={badge.image_url} alt="badge" />
          </div>
          <div className={classes.title}>
            <Link href={badge.assertion_id} target="_blank">
              <Typography variant="h6">
                {badge.name} {badge.is_valid && <Verified />}
              </Typography>
            </Link>
          </div>
          <Typography variant="body1" className={classes.desc}>
            {badge.description}
          </Typography>
          <Typography variant="body1" className={classes.criteria}>
            {badge.criteria}
          </Typography>
          <div className={classes.footer}>
            <span>
              <Typography variant="body2">
                {t('Settings.Appearance.Badges.awarded', {
                  issuer: badge.issuer_name,
                  date: moment(badge.assertion_issued_on).format('MMMM Do YYYY'),
                })}
              </Typography>
              {!badge.is_valid && (
                <Tooltip title={`${t('Settings.Appearance.Badges.mismatch')}`}>
                  <Typography variant="body2">{t('Settings.Appearance.Badges.unverified')}</Typography>
                </Tooltip>
              )}
            </span>
            <IconButton onClick={handleOpenDeletePopup}>
              <DeleteIcon />
            </IconButton>
          </div>
        </DialogContent>
      </div>
      <MuiDialog
        open={open}
        onClose={handleCloseDeletePopup}
        >
        <MuiDialogTitle className={classes.delete_popup_title}>
          {t('Settings.Appearance.Badges.delete_popup_title')}
        </MuiDialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.text}>
              {t('Settings.Appearance.Badges.delete_popup_content_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleCloseDeletePopup}
          >
            {t('Settings.Appearance.Badges.btn_cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={removeBadge}>
              {t('Settings.Appearance.Badges.btn_delete')}
            </Button>  
        </DialogActions> 
      </MuiDialog>
    </>
  );
};

export default BadgeContent;
