import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Units from './Units/Units';
import { getDataFromApi } from '../../../api/dataFromApi';
import { Group, IData } from '../../../utils/types';
import { getDataFromClusterName } from '../../../utils/clusterUtils';
import { getHigherRole } from '../../../redux/CurrentUser/selectors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isEmployeeRole } from '../../../utils/userUtils';

const StartScreen = () => {
  const [courseName, setCourseName] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const higherRole = useSelector(getHigherRole);

  useEffect(() => {
    (async () => {
      if (isEmployeeRole(higherRole)) return;
      const response: IData<Group> = await getDataFromApi(`groups/`, 'classter');

      const [code, title] = getDataFromClusterName(response?.results?.[0]?.grade.description);
      setCourseName(title || '');
      setCourseCode(code || '');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(20)}px 0`,
        [theme.breakpoints.down(1366)]: { padding: `${theme.spacing(2)}px ${theme.spacing(10)}px 0` },
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px ${theme.spacing(5)}px 0` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(1) },
      },
      tabs: {
        [theme.breakpoints.down('xs')]: { maginTop: theme.spacing(2) },
      },
      title: {
        marginTop: theme.spacing(10),
        fontWeight: 'bold',
        [theme.breakpoints.down(460)]: { marginTop: theme.spacing(5) },
      },
      progress: {
        margin: `${theme.spacing(3.75)}px 0 ${theme.spacing(1)}px`,
        height: theme.spacing(1.25),
      },
      units: {
        margin: `${theme.spacing(7.5)}px 0 ${theme.spacing(2.5)}px`,
        [theme.breakpoints.down(460)]: {
          margin: `${theme.spacing(3.75)}px 0 ${theme.spacing(2.5)}px`,
        },
        '& .MuiAccordion-root': {
          margin: `${theme.spacing(1)}px 0`,
          boxShadow: 'none',
          '&:before': {
            content: '',
            display: 'none',
          },
          '& .MuiAccordionDetails-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    })
  )();

  return (
    <div className={classes.root}>
      {!isEmployeeRole(higherRole) ? (
        <>
          <Typography variant="h5" className={classes.title}>
            {courseName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {courseCode}
          </Typography>
        </>
      ) : null}
      <div className={classes.units}>
        <Units />
      </div>
    </div>
  );
};

export default StartScreen;
