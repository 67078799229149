import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MediaPreview from './MediaPreview/MediaPreview';
import MediaThumb from './MediaThumb/MediaThumb';
import { IMedia, MaterialsRouteProps } from '../types';
import { DispatchContext, StateContext } from '../context/context';
import { getData, loadMore } from '../context/actions';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const Media: React.FC<MaterialsRouteProps> = ({ sortOptions, activeFilters }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const { t } = useTranslation();

  const data = state.data.media;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getData('media', { ...sortOptions, ...activeFilters }));
      setLoading(false);
    })();
  }, [dispatch, sortOptions, activeFilters]);

  const handleLoadMore = async () => {
    if (!data?.links?.next) return;
    setLoadingMore(true);
    await dispatch(loadMore('media', data.links.next));
    setLoadingMore(false);
  };

  const renderSkeleton = () => (
    <div className={classes.list}>
      {[...new Array(12)].map((item, index) => (
        <Skeleton key={index} className={classes.item} component="div" />
      ))}
    </div>
  );

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(160px, 1fr))`,
        gap: '16px',
        [theme.breakpoints.down('xs')]: { gridTemplateColumns: `repeat(auto-fill, minmax(140px, 1fr))` },
      },
      item: {
        aspectRatio: '1',
        transform: 'unset',
      },
      loadMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
  )();

  return (
    <div>
      {loading ? (
        renderSkeleton()
      ) : data ? (
        data.results.length ? (
          <div className={classes.list}>
            {data.results.map((item: IMedia) => (
              <div key={item.message_id} className={classes.item}>
                <MediaPreview media={item} trigger={<MediaThumb file={item} />} />
              </div>
            ))}
            {data.count > data.results.length && (
              <div className={classes.loadMore}>
                <Button onClick={handleLoadMore} disabled={loadingMore}>
                  {t('Home.LearningMaterials.load_more')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Home.LearningMaterials.no_media')}
            </Typography>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Media;
