import { getDataFromApi, postDataToApi, patchDataToApi, putDataToApi, deleteDataFromApi } from '../../api/dataFromApi';
import { getInitSettings, getUserTenants } from '../App/actions';
import { persistor, IStore } from '../store';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  IUser,
  IBadge,
  IOpenBadge,
  SET_CURRENT_USER,
  SET_BADGES,
  ADD_OPEN_BADGE,
  REMOVE_OPEN_BADGE,
  SET_OPEN_BADGES,
  SET_BIO_INFO,
  SET_IMAGE_URL,
  SET_TOS_ACCEPTED,
} from './types';
import { AxiosError } from 'axios';

export const setCurrentUser = (user: IUser) => {
  return {
    type: SET_CURRENT_USER,
    payload: {
      ...user,
      loggedIn: true,
    },
  };
};

export const setBadges = (list: Array<IBadge>) => {
  return {
    type: SET_BADGES,
    payload: list,
  };
};

export const setOpenBadges = (list: IOpenBadge[]) => {
  return {
    type: SET_OPEN_BADGES,
    payload: list,
  };
};

export const addOpenBadge = (badge: IOpenBadge) => {
  return {
    type: ADD_OPEN_BADGE,
    payload: badge,
  };
};

export const removeOpenBadge = (badgeId: number) => {
  return {
    type: REMOVE_OPEN_BADGE,
    payload: badgeId,
  };
};

export const setBioInfo = (bio_info: string) => {
  return {
    type: SET_BIO_INFO,
    payload: bio_info,
  };
};

export const setImageUrl = (url: string) => {
  return {
    type: SET_IMAGE_URL,
    payload: url,
  };
};

export const acceptTOS = () => {
  return {
    type: SET_TOS_ACCEPTED,
  };
};

export const setTOSAccepted: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    await postDataToApi('auth/user/self/tnc_accept/', {});
    dispatch(acceptTOS());
  };
};

export const logoutUser: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    await persistor.flush();
    await persistor.purge();
    dispatch({
      type: 'LOGOUT_USER',
      payload: {
        loggedIn: false,
      },
    });
  };
};

export const getCurrentUser: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      const currentUser = await getDataFromApi('auth/user/self/');
      if (!currentUser.tnc_accepted) return;
      await dispatch(getInitSettings());
      dispatch(
        setCurrentUser({
          ...currentUser,
          fullName: `${currentUser.first_name} ${currentUser.last_name}`,
        })
      );
      await dispatch(getUserTenants());
      postDataToApi(
        'achievements/increment/',
        {
          user_id: currentUser.user_id,
          achievement_event_id: 'learning_app_logged_in',
          count: 1,
        },
        'v1'
      );
      dispatch(getUserImage());
      dispatch(getUserBadges());
      dispatch(getOpenBadges());
    } catch {}
  };
};

export const getUserBadges: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      const list = await getDataFromApi('achievements/list/', 'v1');
      dispatch(setBadges(list));
    } catch {}
  };
};

export const getUserImage: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      const { url } = await getDataFromApi('files/profile-image/', 'v1');
      const lastModified = new Date().getTime();
      dispatch(setImageUrl(`${url}?lastModified=${lastModified}`));
    } catch {}
  };
};

export const removeUserImage: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    await deleteDataFromApi('files/profile-image/', 'v1');
    dispatch(setImageUrl(''));
  };
};

export const updateBioInfo: (bioInfo: string) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (bioInfo) => {
  return async (dispatch) => {
    await patchDataToApi('auth/user/self/', { bio_info: bioInfo });
    dispatch(setBioInfo(bioInfo));
  };
};

export const uploadPhoto: (image: File) => ThunkAction<Promise<void>, IStore, null, Action<any>> = (image) => {
  return async (dispatch) => {
    const form = new FormData();
    form.append('image', image);
    try {
      const { url } = await putDataToApi('files/upload/profile-image/', form, 'v1');
      const lastModified = new Date().getTime();
      dispatch(setImageUrl(`${url}?lastModified=${lastModified}`));
    } catch {}
  };
};

export const getOpenBadges: () => ThunkAction<Promise<void>, IStore, null, Action<any>> = () => {
  return async (dispatch) => {
    try {
      const list = await getDataFromApi('badges/list/', 'v1');
      dispatch(setOpenBadges(list));
    } catch {}
  };
};

export const uploadBadge: (image: File) => ThunkAction<Promise<IOpenBadge>, IStore, null, Action<any>> = (image) => {
  return async (dispatch) => {
    const form = new FormData();
    form.append('image', image);
    try {
      const response = await putDataToApi('badges/import/', form, 'v1');
      response && dispatch(addOpenBadge(response));
      return response || {};
    } catch (e) {
      return (e as AxiosError)?.response || {};
    }
  };
};

export const deleteBadge: (badgeId: number) => ThunkAction<Promise<void | {}>, IStore, null, Action<any>> = (
  badgeId
) => {
  return async (dispatch) => {
    try {
      await deleteDataFromApi(`/badges/badge/${badgeId}/`, 'v1');
      dispatch(removeOpenBadge(badgeId));
    } catch (e) {
      return (e as AxiosError)?.response || {};
    }
  };
};
