import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardContext from '../../context';
import UnitCard from '../../../StartScreen/Units/UnitCard';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoData from '../../shared/NoData';

const AcademicPerformance: React.FC = () => {
  const { subjects, fetchSubjects, subjectsLoading } = useContext(DashboardContext);
  const { t } = useTranslation();

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: { height: '100%' },
      head: {
        padding: `${theme.spacing(2)}px 10%`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      title: { fontWeight: 'bold' },
      units: {
        padding: '0 10% 2rem',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(12.5rem, 1fr))`,
        gridAutoRows: '1fr',
        gap: '16px',
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: `repeat(auto-fill, minmax(10.7rem, 1fr))`,
          gap: '4px',
        },
      },
      loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
      },
    })
  )();

  return (
    <>
      <div className={classes.head}>
        <Typography variant="subtitle1" className={classes.title}>
          {t('Dashboard.choose_unit')}
        </Typography>
      </div>
      {!subjectsLoading ? (
        subjects ? (
          <div className={classes.units}>
            {subjects.map(({ title, id }, i) => (
              <UnitCard title={title} id={id} fromDashboard key={i} />
            ))}
          </div>
        ) : (
          <NoData className={classes.loading} />
        )
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default AcademicPerformance;
