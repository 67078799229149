import Axios from 'axios';
import {
  SET_SETTINGS,
  SettingsActionTypes,
  IAppState,
  SET_SIDEBAR_OPEN,
  SET_SEARCH_OPEN,
  SET_ACTIVE_TENANT,
  SET_CHANGING_TENANT,
  SET_TENANTS,
} from './types';

let axiosInterceptor: null | number = null;

export default function reducer(
  state: IAppState = {
    settings: {
      learning_app: {
        font_size: 1,
        font_family: 'Roboto',
      },
      common: {
        color_accent: 'WaikawaGray',
        color_theme: 'Classic',
        background_color: 'default',
        language: 'en',
        badges_visible: '',
      },
      calendar: {},
    },
    sidebarOpen: false,
    searchOpen: false,
    tenants: {},
    tenantsFetched: false,
    active_tenant: '',
    active_role: '',
    changing_tenant: false,
    tenantHeaderSet: false,
  },
  action: SettingsActionTypes
) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case SET_SEARCH_OPEN:
      return {
        ...state,
        searchOpen: action.payload,
      };
    case SET_ACTIVE_TENANT:
      let tenantHeaderSet = false;
      if (action.payload.tenant) {
        if (axiosInterceptor !== null) {
          Axios.interceptors.request.eject(axiosInterceptor);
        }
        axiosInterceptor = Axios.interceptors.request.use((config) => {
          config.headers['x-tenant-uuid'] = action.payload.tenant;
          return config;
        });
        tenantHeaderSet = true;
      }
      return {
        ...state,
        active_tenant: action.payload.tenant,
        active_role: action.payload.role,
        changing_tenant: false,
        tenantHeaderSet,
      };
    case SET_CHANGING_TENANT:
      return {
        ...state,
        changing_tenant: action.payload,
      };
    case SET_TENANTS:
      return {
        ...state,
        tenants: action.payload,
        tenantsFetched: true,
      };
    default:
      return state;
  }
}
