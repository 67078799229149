import React, { useEffect, useRef, useState } from 'react';
// import IconEdit from '../../../assets/Icons_events/00CalendarEventEdit';
// import IconDelete from '../../../assets/Icons_events/00CalendarEventDelete';
import IconClose from '../../../assets/Icons_events/00CalendarEventClose';
// import IconCategory from '../../../assets/Icons_events/CalendarEventCategoryGreen';
// import IconAttendee from '../../../assets/Icons_events/CalendarEventAttendeesGreen';
import IconLocation from '../../../assets/Icons_events/CalendarEventLocationGreen';
// import IconReminder from '../../../assets/Icons_events/CalendarEventReminderGreen';
import IconTime from '../../../assets/Icons_events/CalendarEventTimeGreen';
import IconDetails from '../../../assets/Icons_events/CalendarEventDetailsGreen';
import { IEvent } from '../../../redux/Calendar/types';
import { useSelector } from 'react-redux';
import { getEvent, getCalendarColor } from '../../../redux/Calendar/selectors';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const EventDetails = ({
  showPopup,
  el,
  setShowPopup,
  eventId,
}: {
  showPopup: boolean;
  el: DOMRect | null;
  setShowPopup: (x: boolean) => void;
  eventId: IEvent['azure_id'] | null;
}) => {
  const event = useSelector(getEvent(eventId));
  const popup = useRef<HTMLElement | null>(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const calendarSettings = useSelector(getCalendarColor(event?.calendar?.id));

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener('keydown', listener);
    } else {
      document.removeEventListener('keydown', listener);
    }
  }, [showPopup, setShowPopup]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener('keydown', listener);
    } else {
      document.removeEventListener('keydown', listener);
    }
  }, [showPopup, setShowPopup]);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!popup.current?.contains(e.target as Node)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);
    }
  }, [showPopup, setShowPopup]);

  useEffect(() => {
    if (!el) return;
    if (!popup.current || !popup.current.getBoundingClientRect().width) {
      setModalPosition({ x: 0, y: 0 });
      return;
    }
    const popupDimensions = popup.current
      ? popup.current.getBoundingClientRect()
      : {
          width: 300,
          height: 300,
        };
    const x =
      el.x > popupDimensions.width
        ? el.x - popupDimensions.width
        : window.outerWidth - el.x - el.width > popupDimensions.width
        ? el.x + el.width
        : window.outerWidth - popupDimensions.width;
    const windowHeight = window.innerHeight - el.y;
    const y =
      windowHeight > popupDimensions.height
        ? el.y + window.scrollY
        : window.scrollY + window.innerHeight - popupDimensions.height;
    setModalPosition({ x, y });
  }, [el, setModalPosition, showPopup]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      eventPopup: {
        display: 'none',
        position: 'absolute',
        width: 300,
        background: theme.palette.background.default,
        border: `2px solid ${theme.palette.primary.main} !important`,
        zIndex: 10,
        '& section': { padding: theme.spacing(2.5) },
        '& header': {
          display: 'flex',
          '& h3': {
            fontSize: '1em',
            padding: 0,
            margin: 0,
            flex: 1,
          },
          '& svg': {
            fontSize: '1.6em',
            marginLeft: theme.spacing(1),
            cursor: 'pointer',
          },
        },
        '& main': {
          '& ul': {
            fontSize: '1em',
            fontWeight: 300,
            padding: 0,
            listStyle: 'none',
          },
          '& svg': {
            flexShrink: 0,
            fontSize: '1.2em',
            marginRight: theme.spacing(1.5),
          },
          '& span': { wordBreak: 'break-word' },
          '& li': {
            marginTop: theme.spacing(3),
            display: 'flex',
          },
        },
        '&.active': { display: 'block' },
      },
      icons: { justifySelf: 'flex-end' },
      noIcon: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(3.5),
      },
    })
  )();

  if (!event) return <></>;
  const calendarColor = calendarSettings ? calendarSettings.background : 'var(--calendar-1)';
  const startDate = moment(event.start_time).format('DD MMM YYYY');
  const endDate = moment(event.end_time).format('DD MMM YYYY');

  return (
    <div
      ref={(el) => (popup.current = el)}
      className={`${classes.eventPopup} ${showPopup ? 'active' : ''}`}
      style={{
        top: modalPosition.y,
        left: modalPosition.x,
        borderColor: calendarColor,
      }}
    >
      <section>
        <header>
          <h3>{event.subject}</h3>
          <div className={classes.icons}>
            {/* <IconEdit />
            <IconDelete /> */}
            <IconClose stroke={calendarColor} onClick={() => setShowPopup(false)} />
          </div>
        </header>
        <main>
          <ul>
            {/* <li>
              <IconCategory />
              On site event
            </li> */}
            <li>
              <IconTime stroke={calendarColor} />
              {startDate} {startDate !== endDate && `- ${endDate}`}
            </li>
            <li className={classes.noIcon}>
              {moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}
            </li>
            {/* <li>
              <IconAttendee />4 attendees
            </li>
            <li className={styles.noIcon}>icons</li> */}
            {event.location && (
              <li>
                <IconLocation stroke={calendarColor} />
                <span>{event.location}</span>
              </li>
            )}
            {/* <li>
              <IconReminder />
              10 minutes before
            </li> */}
            {event.description && (
              <li>
                <IconDetails stroke={calendarColor} />
                <span>{event.description}</span>
              </li>
            )}
          </ul>
        </main>
      </section>
    </div>
  );
};

export default EventDetails;
