import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSidebarOpen, setSearchOpen } from '../../redux/App/actions';
import { getSidebarOpen, getSearchOpen } from '../../redux/App/selectors';
import Logo from '../../assets/Logo';
import SearchBar from './SearchBar/SearchBar';
import AppSwitcher from './AppSwitcher/AppSwitcher';
import ProfileActions from './ProfileActions/ProfileActions';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';

const Header: React.FC = () => {
  const sidebarOpen = useSelector(getSidebarOpen);
  const searchOpen = useSelector(getSearchOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSearchClick = () => {
    if (sidebarOpen) dispatch(setSidebarOpen(false));
    dispatch(setSearchOpen(!searchOpen));
  };

  const handleMenuClick = () => {
    if (searchOpen) dispatch(setSearchOpen(false));
    dispatch(setSidebarOpen(!sidebarOpen));
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: { backgroundColor: theme.palette.type === 'light' ? '#fff' : theme.palette.background.paper },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
      },
      logo: {
        flex: 3,
        [theme.breakpoints.down('sm')]: { display: 'none !important' },
      },
      menuButton: {
        display: 'none !important',
        [theme.breakpoints.down('xs')]: { display: 'block !important' },
      },
      menu: {
        flex: 2,
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'flex-start',
          gap: `${theme.spacing(2)}px`,
        },
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'space-between',
          gap: 0,
        },
      },
      actions: {
        flex: 3,
        justifyContent: 'flex-end',
        gap: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
          display: 'none !important',
        },
      },
      displayDownMd: {
        display: 'none',
        [theme.breakpoints.down('md')]: { display: 'block' },
      },
      displayUpXs: {
        display: 'block',
        [theme.breakpoints.down('xs')]: { display: 'none' },
      },
      displayDownXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: { display: 'block' },
      },
      active: { '& button': { color: theme.palette.primary.main } },
    })
  )();

  return (
    <AppBar position="static" className={classes.root} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className={classes.menu}>
          <div className={classes.menuButton}>
            <IconButton
              edge="start"
              color={sidebarOpen ? 'primary' : 'default'}
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <NavLink to="/" exact activeClassName={classes.active}>
            <Tooltip title={`${t('Menus.home')}`}>
              <IconButton onClick={() => dispatch(setSidebarOpen(false))}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
          <div className={classes.displayDownXs}>
            <AppSwitcher />
          </div>
          <IconButton edge="end" className={classes.displayDownXs} data-info="search-bar" onClick={handleSearchClick}>
            <SearchIcon color={searchOpen ? 'primary' : 'inherit'} />
          </IconButton>
          <NavLink to="/materials" exact className={classes.displayUpXs} activeClassName={classes.active}>
            <Tooltip title={`${t('Menus.learning_materials')}`}>
              <IconButton onClick={() => dispatch(setSidebarOpen(false))}>
                <SchoolIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
          <NavLink to="/calendar" exact className={classes.displayUpXs} activeClassName={classes.active}>
            <Tooltip title={`${t('Menus.calendar')}`}>
              <IconButton onClick={() => dispatch(setSidebarOpen(false))}>
                <CalendarIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
          <NavLink to="/dashboard" className={classes.displayUpXs} activeClassName={classes.active}>
            <Tooltip title={`${t('Menus.dashboard')}`}>
              <IconButton onClick={() => dispatch(setSidebarOpen(false))}>
                <DashboardIcon />
              </IconButton>
            </Tooltip>
          </NavLink>
        </div>

        <div className={classes.actions}>
          <SearchBar />
          <IconButton
            className={classes.displayDownMd}
            data-info="search-bar"
            onClick={() => dispatch(setSearchOpen(!searchOpen))}
          >
            <SearchIcon color="primary" />
          </IconButton>
          <AppSwitcher />
          <ProfileActions />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
