import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tenants } from '../redux/App/types';
import { setActiveTenant } from '../redux/App/actions';
import { getActiveTenant } from '../redux/App/selectors';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

interface TenantsModalProps {
  tenants: Tenants;
}

const TenantsModal: React.FC<TenantsModalProps> = ({ tenants }) => {
  const activeTenant = useSelector(getActiveTenant);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      tenantsModalRoot: {
        '& .MuiDialog-paper': {
          margin: 0,
          width: '100%',
          '& .MuiDialogContent-root': {
            padding: 0,
            '& .MuiList-padding': {
              paddingTop: 0,
            },
          },
        },
      },
      title: { textAlign: 'center' },
      subtitle: {
        display: 'inline-block',
        marginTop: '.5rem',
        padding: '.5rem 1rem',
      },
    })
  )();

  return (
    <MuiDialog open={true} className={classes.tenantsModalRoot} keepMounted maxWidth="xs">
      <DialogTitle disableTypography>
        <Typography variant="h6" className={classes.title}>
          {t('TenantsModal.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="caption" color="textSecondary" className={classes.subtitle}>
          {t('TenantsModal.subtitle')}
        </Typography>
        <List>
          {Object.values(tenants).map((tenant) => (
            <ListItem key={tenant.key} button onClick={() => dispatch(setActiveTenant(tenant.key, tenant.role))}>
              <ListItemText disableTypography>
                <Typography
                  variant="body2"
                  style={{
                    color: tenant.key === activeTenant ? theme.palette.primary.main : theme.palette.text.primary,
                  }}
                >
                  {tenant.name}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </MuiDialog>
  );
};

export default TenantsModal;
