import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../../../utils/fileUtils';
import Dialog from '../../../../shared/Dialog/Dialog';
import MessageDetails from '../../MessageDetails/MessageDetails';
import { IMedia } from '../../types';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTooltip = withStyles({
  tooltipPlacementTop: { margin: 0 },
})(Tooltip);

interface IProps {
  media: IMedia;
  trigger: JSX.Element;
}

const MediaPreview: React.FC<IProps> = ({ media, trigger }) => {
  const { message_type, file_data } = media;
  const { media_file_url, content_type } = file_data;

  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.0);
  const { t } = useTranslation();

  const SCALE_FACTOR = 0.5;
  const ROTATE_FACTOR = 90;
  const FULL_CIRCLE = 360;
  const MAX_SCALE = 3;
  const MIN_SCALE = 0.5;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRotate(0);
    setScale(1.0);
    setOpen(false);
  };

  const rotateLeft = () => setRotate((rotate) => (rotate - ROTATE_FACTOR) % FULL_CIRCLE);
  const rotateRight = () => setRotate((rotate) => (rotate + ROTATE_FACTOR) % FULL_CIRCLE);
  const zoomIn = () => setScale((scale) => scale + SCALE_FACTOR);
  const zoomOut = () => setScale((scale) => scale - SCALE_FACTOR);
  const download = () => downloadFile(file_data);

  const renderContent = () => {
    if (message_type === 'image')
      return (
        <div className={classes.wrapper}>
          <img className={classes.image} src={file_data.media_file_url} alt={file_data.name} />
        </div>
      );
    else if (message_type === 'audio')
      return (
        <div className={`${classes.wrapper} ${classes.media}`}>
          <audio controls src={media_file_url} style={{ maxWidth: '100%' }}>
            {t('Home.LearningMaterials.Media.no_audio_support')}
          </audio>
        </div>
      );
    else if (message_type === 'video')
      return (
        <div className={`${classes.wrapper} ${classes.media}`}>
          <video controls preload="metadata" width="100%" style={{ maxWidth: 600 }}>
            <source src={media_file_url} type={content_type}></source>
            {t('Home.LearningMaterials.Media.no_video_support')}
          </video>
        </div>
      );
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiDialog-paper': {
          backgroundColor: 'rgba(0, 0, 0, .87)',
          color: theme.palette.getContrastText('rgba(0, 0, 0, .87)'),
        },
        '& .MuiDialogTitle-root': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDialogContent-root': { userSelect: 'none' },
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      actions: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '5vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': {
          color: theme.palette.common.white,
        },
        '& button[disabled]': {
          '& .MuiSvgIcon-root': { color: theme.palette.grey[700] },
        },
        [theme.breakpoints.down('sm')]: {
          top: 'auto',
          bottom: 0,
          width: '100%',
          height: '10vh',
          flexDirection: 'row',
        },
      },
      wrapper: {
        width: '90%',
        height: '100%',
        margin: 'auto',
        overflow: 'hidden',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '90%',
        },
      },
      media: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-32px',
      },
      image: {
        objectFit: 'scale-down',
        width: '100%',
        height: '100%',
        transform: `rotate(${rotate}deg) scale(${scale})`,
      },
      spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1em 0',
      },
    })
  )();

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleOpen })}
      <MuiDialog
        className={classes.root}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
      >
        {open && (
          <>
            <DialogTitle disableTypography id="alert-dialog-slide-title">
              <Typography variant="h6">{file_data.original_name}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </DialogTitle>
            <DialogContent>{renderContent()}</DialogContent>
            <div className={classes.actions}>
              {message_type === 'image' && (
                <>
                  <StyledTooltip title={`${t('Image.rotate_left')}`} placement="top">
                    <IconButton onClick={rotateLeft}>
                      <RotateLeftIcon fontSize="large" />
                    </IconButton>
                  </StyledTooltip>
                  <StyledTooltip title={`${t('Image.rotate_right')}`} placement="top">
                    <IconButton onClick={rotateRight}>
                      <RotateRightIcon fontSize="large" />
                    </IconButton>
                  </StyledTooltip>
                  <StyledTooltip title={`${t('Image.zoom_in')}`} placement="top">
                    <IconButton onClick={zoomIn} disabled={scale === MAX_SCALE}>
                      <ZoomInIcon fontSize="large" />
                    </IconButton>
                  </StyledTooltip>
                  <StyledTooltip title={`${t('Image.zoom_out')}`} placement="top">
                    <IconButton onClick={zoomOut} disabled={scale === MIN_SCALE}>
                      <ZoomOutIcon fontSize="large" />
                    </IconButton>
                  </StyledTooltip>
                </>
              )}
              <Dialog
                trigger={
                  <StyledTooltip title={`${t('Image.info')}`} placement="top">
                    <IconButton>
                      <InfoOutlinedIcon fontSize="large" />
                    </IconButton>
                  </StyledTooltip>
                }
              >
                {(close: () => void) => <MessageDetails handleClose={close} message={media} />}
              </Dialog>

              <StyledTooltip title={`${t('Image.download')}`} placement="top">
                <IconButton onClick={download}>
                  <GetAppIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
            </div>
          </>
        )}
      </MuiDialog>
    </>
  );
};

export default MediaPreview;
