import React from 'react';
import { getFileInfo } from '../../../utils/fileUtils';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface IProps {
  message: any;
}

const InfoSubheader: React.FC<IProps> = ({ message }) => {
  const { file_data } = message;
  const { original_name } = file_data;
  const { sizeText } = getFileInfo(file_data);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      info: {
        fontSize: '.9em',
        color: theme.palette.text.secondary,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(0.5),
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 0,
        },
      },
      col: {
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
      },
      name: {
        fontWeight: 'bold',
        maxWidth: '20ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: { maxWidth: '20ch' },
      },
    })
  )();

  return (
    <div className={classes.info}>
      <span className={classes.col}>
        <span className={classes.name}>{original_name}</span>
        <span>{sizeText}</span>
      </span>
    </div>
  );
};

export default InfoSubheader;
