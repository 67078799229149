import React, { useState, FormEvent, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uploadPhoto } from '../../../../redux/CurrentUser/actions';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '../../../shared/Dialog/Dialog';
import DialogTitle from '../../../shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import imageCompression from 'browser-image-compression';

const ProfilePictureUploader: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [image, setImage] = useState<File | null>();
  const [imageUrl, setImageUrl] = useState('');
  const [alert, setAlert] = useState<{ type: 'error' | 'warning' | 'success'; message: string }>({
    type: 'error',
    message: t('Settings.Edit_profile.ProfileUpload.Errors.default'),
  });
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // crop img
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg: (imageSrc: string, pixelCrop: Area) => Promise<Blob> = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 320;
    canvas.height = 320;

    ctx?.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, 320, 320);

    return new Promise((resolve) => {
      return canvas.toBlob((file) => {
        resolve(file!);
      });
    });
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const url = e.target!.result as string;
        setImageUrl(url);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  const imageChange = (files: FileObject[]) => {
    if (files.length) {
      const { file } = files[0];
      setImage(file);
    } else {
      resetUploader();
    }
  };

  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    confirmUploadPictureAction(onClose);
  };

  const uploadPicture = async (close: () => void, img: any) => {
    setIsSubmitting(true);
    await dispatch(uploadPhoto(img!));
    setIsSubmitting(false);
    setOpen(true);
    setAlert({ type: 'success', message: 'Profile picture successfuly updated' });
    close();
  };

  const resetUploader = () => {
    setImage(null);
    setImageUrl('');
    setZoom(1);
    setCroppedAreaPixels(null);
    setCrop({ x: 0, y: 0 });
  };

  const onClose = () => {
    resetUploader();
    return true;
  };

  const confirmUploadPictureAction = async (close: () => void) => {
    try {
      if (croppedAreaPixels === null) return;
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
      const fileImg = new File([croppedImage], `${image?.name}`, {
        type: 'image/png',
        lastModified: new Date().getTime(),
      });

      const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 320,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(fileImg, options);
        uploadPicture(close, compressedFile);
      } catch (error) {
        console.log(error);
        uploadPicture(close, fileImg);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        display: 'none',
      },
      root: {
        marginTop: theme.spacing(2),
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(4)}px`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        outline: 'none',
      },
      textContainer: {
        minHeight: theme.spacing(31.25),
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: 3,
        display: 'grid',
        placeItems: 'center',
      },
      text: {
        whiteSpace: 'pre-line',
        fontSize: '1.2em !important',
        lineHeight: '1.8em !important',
        padding: `0 ${theme.spacing(6)}`,
        color: theme.palette.text.secondary,
      },
      form: {
        '& .MuiDialogTitle-root': {
          height: '20px',
        },
        '& .MuiDialogContent-root': {
          paddingTop: '20px',
        },
      },
      containerSlider: {
        padding: '22px 0px',
      },
      cropWrapper: {
        backgroundColor: 'darkgray',
        padding: '15px 40px',
        position: 'relative',
        height: '200px',
      },
    })
  )();

  return (
    <>
      <Dialog
        trigger={
          <Button variant="contained" color="primary" disableElevation>
            {t('Settings.Edit_profile.change_photo')}
          </Button>
        }
        onClose={onClose}
      >
        {(close: () => void) => (
          <form onSubmit={submitForm} className={classes.form}>
            <DialogTitle onClose={close}>{t('Home.OpenBadgeUploader.cropArea.title')}</DialogTitle>
            {!imageUrl ? (
              <DialogContent>
                <DropzoneAreaBase
                  acceptedFiles={['image/*']}
                  filesLimit={1}
                  fileObjects={[]}
                  dropzoneText={`${t('Home.OpenBadgeUploader.uploadArea.drag')}
                          ${t('Home.OpenBadgeUploader.uploadArea.or')}
                          ${t('Home.OpenBadgeUploader.uploadArea.browse')}`}
                  classes={{
                    icon: classes.icon,
                    root: classes.root,
                    textContainer: classes.textContainer,
                    text: classes.text,
                  }}
                  showAlerts={['error', 'info']}
                  onAdd={imageChange}
                  maxFileSize={50 * 1024 * 1024} // 50MB
                />
              </DialogContent>
            ) : (
              <DialogContent>
                <div className={classes.cropWrapper}>
                  <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(+zoom)}
                  className={classes.containerSlider}
                />
                <DialogActions>
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                    {t('Settings.Edit_profile.ProfileUpload.save')}
                  </Button>
                  <Button color="primary" onClick={close}>
                    {t('Settings.Edit_profile.ProfileUpload.cancel')}
                  </Button>
                  <Box flexGrow={1}></Box>
                  <Tooltip title={`${t('Settings.Edit_profile.ProfileUpload.remove')}`}>
                    <IconButton aria-label="close" onClick={() => imageChange([])}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </DialogActions>
              </DialogContent>
            )}
          </form>
        )}
      </Dialog>

      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
      >
        <Alert onClose={handleClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfilePictureUploader;
