import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { getChartAnalyticsData, getColors, getTitle } from '../utils';
import generateLegendPlugin from '../legendPlugin';
import DashboardContext from '../context';
import NoData from '../shared/NoData';
import GroupSelect from '../shared/GroupSelect';
import StudentsTable from './StudentsTable/StudentsTable';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const AppAnalyticsDetails: React.FC = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { usageData, fetchUsageData } = useContext(DashboardContext);
  const { t } = useTranslation();
  const params = useParams();
  const theme = useTheme();
  const colors = getColors(theme);
  const legendPlugin = useMemo(
    () =>
      generateLegendPlugin({
        ulStyles: {
          marginTop: '0px',
          height: '22.5%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        boxStyles: {
          width: '20px',
          height: '20px',
          marginRight: '16px',
        },
        textStyles: {
          letterSpacing: '0.5px',
          color: theme.palette.text.secondary,
          fontSize: '0.9rem',
        },
      }),
    [theme.palette.text.secondary]
  );

  useEffect(() => {
    (async () => {
      setIsFetchingData(true);
      await fetchUsageData();
      setIsFetchingData(false);
    })();
  }, [fetchUsageData]);

  const { app } = params as { [key: string]: string };
  const title = getTitle(app);
  const data = usageData?.communicator;
  const { labels, percents, backgroundColor } = getChartAnalyticsData({ title, data }, colors, t);

  const tableColumns = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'engagement', label: 'Engaged' },
  ];
  const tableRows =
    data?.users_data?.map(({ id, profile_image, first_name, last_name, engaged }) => ({
      id,
      image: profile_image || '',
      name: `${first_name} ${last_name}`,
      engagement: t(`Dashboard.${engaged.toLowerCase()}`),
    })) || [];

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: `${theme.spacing(2)}px 10%`,
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          color: theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          '& svg': { fontSize: '1.2em' },
        },
        '& .MuiTypography-subtitle1': {
          fontWeight: 'bold',
          marginLeft: theme.spacing(2),
        },
      },
      chart: {
        padding: `${theme.spacing(2)}px 10%`,
        [theme.breakpoints.down('sm')]: { padding: `${theme.spacing(2)}px 5%` },
        [theme.breakpoints.down('xs')]: { padding: theme.spacing(2) },
      },
      chartWrapper: {
        display: 'flex',
        '& #legend-container-app-details': {
          marginLeft: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: { marginLeft: theme.spacing(6) },
          [theme.breakpoints.down('xs')]: {
            margin: 0,
            marginTop: theme.spacing(2),
          },
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      chartContainer: {
        position: 'relative',
        height: 250,
        width: 250,
      },
      percentage: {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        fontSize: '2rem',
        color: theme.palette.primary.main,
      },
      studentsTotal: {
        width: 250,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        fontWeight: 'bold',
        letterSpacing: '.1px',
        [theme.breakpoints.down('xs')]: { width: 'unset' },
      },
      loading: {
        display: 'grid',
        placeItems: 'center',
        minHeight: 400,
      },
    })
  )();

  return (
    <>
      <div className={classes.head}>
        <div className={classes.title}>
          <Link to="/dashboard">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <Typography variant="subtitle1">
            {title} {t('Dashboard.user_engagement')}
          </Typography>
        </div>
        <GroupSelect />
      </div>

      {isFetchingData ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : data?.users_data.length ? (
        <>
          <div className={classes.chart}>
            <div className={classes.chartWrapper}>
              <div className={classes.chartContainer}>
                <span className={classes.percentage}>{data?.engagement?.engaged_percent.toFixed(0)}%</span>
                <Doughnut
                  type="doughnut"
                  data={{
                    labels,
                    datasets: [
                      {
                        data: percents,
                        backgroundColor: backgroundColor,
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      htmlLegend: {
                        containerID: 'legend-container-app-details',
                      },
                    },
                    cutout: '90%',
                  }}
                  plugins={[legendPlugin]}
                />
              </div>
              <div id="legend-container-app-details" />
            </div>
            <Typography className={classes.studentsTotal} variant="subtitle2" color="textSecondary">
              {data?.engagement?.total_users} {t('Dashboard.students_total')}
            </Typography>
          </div>
          <StudentsTable
            rows={tableRows}
            columns={tableColumns}
            urlGenerator={(id: string) => `/dashboard/student-profile/${id}`}
          />
        </>
      ) : (
        <NoData className={classes.loading} />
      )}
    </>
  );
};

export default AppAnalyticsDetails;
