import React from 'react';
import SvgCheckmark from '../../../assets/Checkmark';
import SvgVerticalMenu from '../../../assets/Icons_events/Calendar3Dots';
import Dropdown from '../../shared/Dropdown/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../../redux/App/actions';
import styles from './CalendarFilter.module.css';
import { getCalendarColor } from '../../../redux/Calendar/selectors';
import SvgCalendarColor from '../../../assets/CalendarColor';
import { useTranslation } from 'react-i18next';

const CalendarFilter = ({
  active,
  title,
  className,
  index,
  id,
}: {
  active?: boolean;
  title: string;
  className?: string;
  index: number;
  id: string;
}) => {
  const colors = [
    { backgroundColor: '#ba0300', foregroundColor: '#ffffff' },
    { backgroundColor: '#fbc02d', foregroundColor: '#121212' },
    { backgroundColor: '#aebc00', foregroundColor: '#121212' },
    { backgroundColor: '#015ab5', foregroundColor: '#ffffff' },
    { backgroundColor: '#ce5ef0', foregroundColor: '#ffffff' },
    { backgroundColor: '#Ce5a58', foregroundColor: '#ffffff' },
    { backgroundColor: '#fae100', foregroundColor: '#121212' },
    { backgroundColor: '#319b18', foregroundColor: '#ffffff' },
    { backgroundColor: '#56b4e9', foregroundColor: '#121212' },
    { backgroundColor: '#f595c9', foregroundColor: '#121212' },
    { backgroundColor: '#fe6100', foregroundColor: '#ffffff' },
    { backgroundColor: '#936c26', foregroundColor: '#ffffff' },
    { backgroundColor: '#006340', foregroundColor: '#ffffff' },
    { backgroundColor: '#785ef0', foregroundColor: '#ffffff' },
    { backgroundColor: '#c7c7c7', foregroundColor: '#121212' },
    { backgroundColor: '#f57c00', foregroundColor: '#121212' },
    { backgroundColor: '#6d4c41', foregroundColor: '#ffffff' },
    { backgroundColor: '#65a4ac', foregroundColor: '#ffffff' },
    { backgroundColor: '#9c5ef0', foregroundColor: '#ffffff' },
    { backgroundColor: '#808080', foregroundColor: '#ffffff' },
  ];

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const calendarSettings = useSelector(getCalendarColor(id));

  const setColor = (backgroundColor: string, foregroundColor: string) => {
    dispatch(
      updateSettings('calendar', {
        [id]: {
          background: backgroundColor,
          foreground: foregroundColor,
        },
      })
    );
  };

  const styleObj = {} as any;
  if (calendarSettings) {
    styleObj['--filter-color'] = calendarSettings.background;
    styleObj['--color'] = calendarSettings.foreground;
  }

  return (
    <li className={styles.CalendarFilter}>
      <button
        className={`${styles.filter} ${active ? styles.activeFilter : ''} ${
          !calendarSettings ? `Calendar-${index}` : ''
        }`}
        type="button"
        style={styleObj}
      >
        <SvgCheckmark />
      </button>
      <span className={styles.text}>{title}</span>
      <Dropdown
        notForNav
        className={`dropdownHolder ${styles.dropdown}`}
        opener={
          <button className="dropdown">
            <SvgVerticalMenu />
          </button>
        }
      >
        <>
          <span className={styles.DropdownTitle}>
            <SvgCalendarColor /> {t('Calendar.colors')}
          </span>
          <div className={styles.colors}>
            {colors.map(({ backgroundColor, foregroundColor: color }) => {
              const foreground = '--color' as any;
              const background = '--backgroundColor' as any;
              const activeColor = calendarSettings?.background === backgroundColor;
              return (
                <div
                  key={`${backgroundColor}_${color}`}
                  onClick={() => setColor(backgroundColor, color)}
                  className={activeColor ? styles.active : ''}
                  style={{
                    [foreground]: color,
                    [background]: backgroundColor,
                  }}
                >
                  {activeColor ? <SvgCheckmark /> : 'A'}
                </div>
              );
            })}
          </div>
        </>
      </Dropdown>
    </li>
  );
};

export default CalendarFilter;
